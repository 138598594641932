import React, { Component } from 'react';
import Header from '../directives/header'
import Footer from '../directives/footer'
import Leftsidebar from '../directives/leftsidebar'
// import Footer from '../directives/footer'
import axios from 'axios'
import config from '../config/config'
import toast, { Toaster } from 'react-hot-toast';
import Cookies from 'js-cookie';
import 'react-toastify/dist/ReactToastify.css';
// import UserBanks from './userBanks';
const headers = {
    'Content-Type': 'application/json'
};

export default class AddBanks extends Component {

    constructor(props) {
        super(props)
        this.state = {
            bank_name: '',
            account_number: '',
            account_holder_name: '',
            bankList : []
        }
        this.onChange = this.onChange.bind(this);
        this.submitForm = this.submitForm.bind(this);
        this.loginData = (!Cookies.get('loginSuccesGarryToken')) ? [] : JSON.parse(Cookies.get('loginSuccesGarryToken'));
    }

    componentDidMount() {
        document.getElementById("userBanks").classList.add("active");
        this.getBankList();
    }

    onChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    async getBankList() {
        axios.get(`${config.apiUrl}getBankList`, { headers })
          .then(response => {
            this.setState({
              bankList: response.data.response,
            })
          })
      }

    async submitForm(e) {
        e.preventDefault();

        let obj = {
            user_id: this.loginData.data.id,
            bank_name: this.state.bank_name,
            account_number: this.state.account_number,
            account_holder_name: this.state.account_holder_name,
        }
        //   console.log(obj)
        //   return;
        axios.post(`${config.apiUrl}insertBankDetails`, obj, { headers })
            .then(response => {
                if (response.data.success === true) {
                    toast.success('Bank detail added successfully.');
                    setTimeout(() => {
                        window.location.href = config.baseUrl + 'UserBanks'
                    }, 1000);
                }
                else if (response.data.success === false) {
                    toast.error(response.data.msg);
                }
            })
            .catch(err => {
                toast.error(err.response.data?.msg);
            })
    }

    render() {
        return (
            <div>

                <div className="">
                    <Leftsidebar />
                    {/* Content Start */}
                    <div className="content">
                        <Header />


                        <div className="row" id="johndashidone">
                            {/* <h5 class="johndash">John Doe  /  Add card</h5> */}
                            <form action="#" id="userbankaccount">
                                <div className="walletimgblock">
                                    <img src="icons/bank2 1.png" alt="bank2 1" className="walletinput" />
                                </div>
                                <div className="selectbox">
                                    <h3>Select  Bank Name</h3>
                                    <p>Select  the name of bank you want to add</p>
                                    <div className="input-group mb-3 selectrow" id="oldbankinput">

                                        <select className='form-control' name="bank_name" onChange={this.onChange} value={this.state.bank} style={{ textAlign: 'center', background: '#fff' }}>
                                            <option value=''>- Select Bank -</option>
                                            {this.state.bankList.length > 0 ?
                                            this.state.bankList.map((item, i) =>
                                                <option value={item.id}>{item.bank_name}</option>
                                            )
                                            : ''}
                                        </select>
                                        <div className="input-group-text bankdiv" style={{ padding: '0.375rem 1.75rem' }}>
                                            <img src="icons/bank.png" alt="bank" className="bankinput" />
                                        </div>
                                    </div>
                                    <div className="input-group mb-3 selectrow" id="newbankinput">
                                        <button className="btn btn-outline-secondary dropdown-toggle" id="selecttoggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" />
                                        <ul className="dropdown-menu" style={{}}>
                                        <li><a className="dropdown-item" href="#">Action</a></li>
                                        <li><a className="dropdown-item" href="#">Another action</a></li>
                                        <li><a className="dropdown-item" href="#">Something else here</a></li>
                                        <li><hr className="dropdown-divider" /></li>
                                        <li><a className="dropdown-item" href="#">Separated link</a></li>
                                        </ul>
                                        <input type="text" className="form-control text-center active" placeholder="Select Bank" />
                                        <div className="input-group-text bankdiv" id="bankdivid">
                                        {/* <img src="icons/bank.png" alt="bank" className="bankinput" /> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="selectbox">
                                    <h3>Account Number</h3>
                                    <p>Enter your account number</p>
                                    <div className="input-group mb-3 selectrow">
                                        <input type="text" className="form-control text-center" placeholder="Account Number" name="account_number" value={this.state.account_number} onChange={this.onChange} autoComplete="off" />
                                    </div>
                                </div>
                                <div className="selectbox">
                                    <h3>Account Name</h3>
                                    <p>Enter account name</p>
                                    <div className="input-group mb-3 selectrow">
                                        <input type="text" className="form-control text-center" placeholder="Account Name" name="account_holder_name" value={this.state.account_holder_name} onChange={this.onChange} autoComplete="off" />
                                    </div>
                                </div>
                                <div className="bankbtn" id="addbandbtnid">

                                    <div className='col-md-12'>
                                    <a href="javascript: history.go(-1)" className='btn btn-danger m-2'><i class="fa fa-arrow-left "></i> Back</a>
                                        <button type='submit' className="btn btn-success mb-0 expirydatesubmit" onClick={this.submitForm}>Submit</button>
                                        {/* &nbsp;<a href={`${config.baseUrl}userBanks`} className="btn btn-danger expirydatesubmit" >Back</a> */}
                                       
                                    </div>

                                    
                                </div>

                            </form>
                        </div>

                    </div>
                </div>
                <Footer />
            </div>
        );

    }
}
