import React, { Component } from 'react';
import Header from '../directives/header'
import Footer from '../directives/footer'
import Leftsidebar from '../directives/leftsidebar'
// import Footer from '../directives/footer'
import axios from 'axios'
import config from '../config/config'
import toast, { Toaster } from 'react-hot-toast';
import Cookies from 'js-cookie';
import 'react-toastify/dist/ReactToastify.css';
// import UserBanks from './userBanks';
const headers = {
  'Content-Type': 'application/json'
};

export default class Faqs extends Component {

  constructor(props) {
    super(props)
    this.state = {
      faq_list: []
    }
  }

  componentDidMount() {
    this.getfaqs_list();
    document.getElementById("faqs").classList.add("active");
  }

  async getfaqs_list() {
    axios.get(`${config.apiUrl}getfaqs_list`)
      .then(response => {
        if (response.data.success === true) {
          this.setState({
            faq_list: response.data.response
          })
        }
      })
  }

  render() {
    return (
      <div>

        <div className="">
          <Leftsidebar />
          {/* Content Start */}
          <div className="content">
            <Header />

            <div className="row" id="johndashidfaq">
              {/* <h5 className="blog" id='blogsid'>Articles</h5> */}
              <div className="row m-0 p-0">
                <div className="cardbox" id="cardboxdiv">
                  
                  <div className="col-lg-12 col-md-12 col-12">
                    <div id="frequintlyid">
                      <img src="img/header.png" className="card-img-top" alt="header" />
                    

                      <div className="accordion" id="accordionExample">
                        {this.state.faq_list.map((item, i) =>
                          <div className="accordion-item" style={{ marginBottom: '40px', border: '1px solid lightgray'}}>
                            <h2 className="accordion-header" id={`heading` + i} >
                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse` + i} aria-expanded="false" aria-controls={`collapse` + i}>
                                {item.questions}
                              </button>
                            </h2>
                            <div id={`collapse` + i} className="accordion-collapse collapse" aria-labelledby={`heading` + i} data-bs-parent="#accordionExample" style={{}}>
                              <div className="accordion-body">

                                <div
                                  dangerouslySetInnerHTML={{ __html: item.answers }}
                                />
                              </div>
                            </div>
                          </div>
                        )}
                        
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>


          </div>
        </div>
        <Footer />
      </div>
    );

  }
}
