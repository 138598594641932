import React, { Component } from 'react';
import axios from 'axios';
import config from '../config/config'
import toast, { Toaster } from 'react-hot-toast';
import Cookies from 'js-cookie';
import 'react-toastify/dist/ReactToastify.css';

// import PhoneInput from 'react-phone-number-input'
// import 'react-phone-number-input/style.css'

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

const headers = {
	'Content-Type': 'application/json'
};

const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
const mediumRegex = new RegExp("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})");


export default class Signup extends Component {
	constructor(props) {
		super(props)
		this.state = {
			username: '',
			phone_code:'',
			phone: '',
			email: '',
			password: '',
			otp:'',
			otp_screen:false,
			reg_screen:true,
			is_checked_terms: true
		}
		this.onChange = this.onChange.bind(this);
		this.submitSend_otpForm = this.submitSend_otpForm.bind(this);
		this.submitRegForm = this.submitRegForm.bind(this);

		if (Cookies.get('loginSuccesGarryToken')) {
			window.location = `${config.baseUrl}dashboard`;
		}

	}
	componentDidMount() {
		// if ("geolocation" in navigator) {
    //   navigator.geolocation.getCurrentPosition(function(position) {
		// 		console.log(position);
		// 	});
    // } else {
    //   console.log("Not Available");
    // }
	}

	onChange(e) {
		this.setState({
			[e.target.name]: e.target.value
		})
		

	}

	toggleCheckbox(e) {
		console.log(e.target.value);
		let newValue = (this.state.is_checked_terms === "on" || this.state.is_checked_terms === true) ? false : true;
    this.setState({
      is_checked_terms: newValue
    });
	}
	

	// onChange1(e) {
	// 	console.log(e)
	// 	localStorage.setItem('mobile_no', e ? e : '');
	// }


	async submitSend_otpForm(e) {
		e.preventDefault()
		if(!this.state.username)
		{
			await toast.error('Username required!');
			return;
		}
		else if(this.state.username.indexOf(' ') >= 0){
			await toast.error('Username should be no space!');
			return;
		}
		else if(!this.state.phone)
		{
			await toast.error('Mobile number required!');
			return;
		}
		else if(!this.state.email)
		{
			await toast.error('Email required!');
			return;
		}
		else if(!this.state.password)
		{
			await toast.error('Password required!');
			return;
		}
		if(!strongRegex.test(this.state.password)) {
				await toast.error('Password should have one digit and one spacial character!');	
				return;
		}

		const data = {
			username:this.state.username,
			mobile_no: this.state.phone,
			email:this.state.email,
			password:this.state.password,
			terms:this.state.is_checked_terms,
		}
		axios.post(`${config.apiUrl}send_otp`, data, { headers })
			.then(response => {
				if (response.data.success === true) {
					toast.success(response.data.msg);
					this.setState({
						otp_screen:true,
						reg_screen:false,
						enc_otp:response.data.data
					})
				}
				else if (response.data.success === false) {
					toast.error(response.data.msg);
				}
			})
			.catch(err => {
				toast.error(err.response.data?.msg);
			})
	}

	async submitRegForm(e) {
		e.preventDefault()
		const data = {
			enc_otp:this.state.enc_otp,
			entered_otp:this.state.otp,
			username:this.state.username,
			phone_number:this.state.phone,
			email:this.state.email,
			password:this.state.password,
		}
		axios.post(`${config.apiUrl}signup`, data, { headers })
			.then(response => {
				if (response.data.success === true) {
					toast.success(response.data.msg);
					Cookies.set('loginSuccessGerryToken', response.data);
					setTimeout(() => {
						window.location.href = `${config.baseUrl}login`
					}, 1500);
					// window.location.href = `${config.baseUrl}login`
				}
				else if (response.data.success === false) {
					toast.error(response.data.msg);
				}
			})
			.catch(err => {
				toast.error(err.response.data?.msg);
			})
	}

	async send_otp_on_call(e) {
		e.preventDefault()
		const data = {
			mobile_no: this.state.phone
		}
		axios.post(`${config.apiUrl}otp_on_call`, data, { headers })
			.then(response => {
				if (response.data.success === true) {
					toast.success(response.data.msg);
					this.setState({
						otp_screen:true,
						reg_screen:false
					})
				}
				else if (response.data.success === false) {
					toast.error(response.data.msg);
				}
			})
			.catch(err => {
				toast.error(err.response.data?.msg);
			})
	}

	async togglePassword(params) {
		const togglePassword = document.querySelector('#togglePassword');
		const password = document.querySelector('#id_password');

		const type = password.getAttribute('type') === 'password' ? 'text' : 'password';
		password.setAttribute('type', type);
		togglePassword.classList.toggle('fa-eye-slash');
	}


	render() {
		return (

			<>
				<div>

					<section className="login" id='signupid'>
					<img src="img/loginmobile.png" alt="loginmobile" id="signupmobile"></img>

						<div className="inner-img" id="innerimgid">
						
							<img src="img/backimg.png" alt="backimg" />
						</div>
						<Toaster
								position="top-right"
								reverseOrder={false}
							/>
						<div className="container">
							<div className="row login-content">
								<div className="col-lg-8 col-md-8">
								{this.state.reg_screen ? 
									<div className="inner-login" id="signupdid">
										<div className="bankimgblock">
											<img src="icons/gcoin.png" alt="gcoin" className id="gcoin" />
										</div>
										<h1>Garri Swap - Sign Up</h1>
										<form action="#">
											<div className="form-group">
												<label htmlFor>Username</label>
												<input type="text" className="form-control" required="" value={this.state.username} onChange={this.onChange} name="username" id="exampleInputEmail_2" placeholder="Username" autoComplete='off' />
											</div>

											<div className="form-group" id='mobilecount'>
												<label htmlFor>Mobile no.</label>

												<PhoneInput
												country={'ng'}
												value={this.state.phone}
												onChange={phone => this.setState({ phone })}
												/>

												{/* <PhoneInput
														placeholder="Enter phone number"
														className="form-control countryPhoneInput" defaultCountry="IN" onChange={this.onChange1} /> */}
												{/* <input type="number" className="form-control" required="" value={this.state.mobile_no} onChange={this.onChange} name="mobile_no" id="exampleInputEmail_2" placeholder="Mobile no." min={0} autoComplete='off' /> */}
											</div>

											<div className="form-group">
												<label htmlFor>Email</label>
												<input type="email" className="form-control" required="" value={this.state.email}
													onChange={this.onChange} name="email" id="exampleInputEmail_2" placeholder="Email" autoComplete='off' />
											</div>
											<div className="form-group">
												<label htmlFor>Password</label>
												<input type="password" className="form-control" required="" id="id_password" value={this.state.password}
													onChange={this.onChange} name="password" placeholder="Password" autoComplete='off' />
												<i class="far fa-eye" id="togglePassword" onClick={this.togglePassword.bind(this)}></i>
											</div>

											<div className="form-group form-control-inline">
												<a href={`${config.baseUrl}termsconditions`}>
												  <label className="form-check-label form-text" htmlFor="flexSwitchCheckChecked">Term &amp; Conditions</label>
												</a>
												{/* <div className="form-check form-switch">
													<input className="form-check-input" type="checkbox"   checked={this.state.is_checked_terms} id="flexSwitchCheckChecked" onChange={this.toggleCheckbox.bind(this)} />
												</div> */}
												<label className="switch">
                                                <input type="checkbox" defaultChecked="" />
                                                <span className="slider round" />
                                            	</label>
											</div>

											<br></br>
											<div className="loginbtm">
												<button className="btn btn-success signinbtn"
													onClick={this.submitSend_otpForm}>Sign Up
												</button>
												<p>Already have an account ?<a href={`${config.baseUrl}login`}> Sign In</a></p>
											</div>
										</form>
									</div>
									:
									''
								}

								{this.state.otp_screen ? 
									<div className="inner-login">
										<h1>Garri Swap - OTP Verification</h1>
										<p style={{color:'green'}}>OTP has been sent to your mobile number.</p>
										<form action="#">
											<div className="form-group">
												<label htmlFor>OTP</label>
												<input type="text" className="form-control" required="" value={this.state.otp} onChange={this.onChange} name="otp" id="exampleInputEmail_2" placeholder="Enter Otp" autoComplete='off' />
											</div>
											<br></br>
											<div className="loginbtm">
												<button className="btn btn-success signinbtn"
													onClick={this.submitRegForm}>Verify
												</button>
												<p><a href='javascrip:void(0)' onClick={this.submitSend_otpForm}> Resend OTP</a></p>
												<p><a onClick={this.send_otp_on_call.bind(this)} href="javascript:;"> OTP On Call?</a></p>

												<p>Already have an account ?<a href={`${config.baseUrl}login`}> Sign In</a></p>
											</div>
										</form>
									</div>
									:
									''
								}
								</div>
							</div>
						</div>
					</section>

				</div>
			</>

			//             <section className="login">
			//                 <div className="inner-img">
			//                 <Toaster
			//                     position="top-right"
			//                     reverseOrder={false}
			//                 />
			//                     <img src="img/backimg.png" alt="backimg" />
			//                 </div>
			//                 <div className="container">
			//                     <div className="row login-content">
			//                         <div className="col-lg-8 col-md-8">
			//                             <div className="inner-login">
			//                                 <h1>Gari Swap - Signup</h1>
			//                                 <form action="#">

			//                                     <div className="form-group">
			//                                         <label className="control-label mb-10" for="exampleInputEmail_2">Username</label>
			//                                         <input type="username" className="form-control" required="" value={this.state.username}
			//                                             onChange={this.onChange} name="username" id="exampleInputEmail_2" placeholder="username" />
			//                                     </div>

			//                                     <div className="form-group">
			//                                         <label className="control-label mb-10" for="exampleInputEmail_2">Email</label>
			//                                         <input type="email" className="form-control" required="" value={this.state.email}
			//                                             onChange={this.onChange} name="email" id="exampleInputEmail_2" placeholder="email" />
			//                                     </div>



			//                                     <div className="form-group">
			//                                         <label className="pull-left control-label mb-10" for="exampleInputpwd_2">Password</label>
			//                                         <div className="clearfix"></div>
			//                                         <input type="password" className="form-control" required="" id="exampleInputpwd_2" value={this.state.password}
			//                                             onChange={this.onChange} name="password" placeholder="Enter Password" />



			// {/* 
			//                                         <div className="form-group form-control-inline">
			//                                             <label className="form-check-label form-text" htmlFor="flexSwitchCheckChecked">Term &amp; Conditions</label>
			//                                             <div className="form-check form-switch">
			//                                                 <input className="form-check-input" type="checkbox" id="flexSwitchCheckChecked" defaultChecked />
			//                                             </div>
			//                                         </div> */}


			//                                         <div className="loginbtm">
			//                                             <button className="btn btn-success signinbtn"
			//                                             onClick={this.submitForm}>sign in
			//                                             </button>
			//                                             <p>Already have an account ?<a href={`${config.baseUrl}login`}> Sign Up</a></p>
			//                                         </div>
			//                                     </div>
			//                                 </form>
			//                             </div>
			//                         </div>
			//                     </div>
			//                 </div>
			//             </section>

		)
	}
}