import React, { Component } from 'react';
import Header from '../directives/header'
import Footer from '../directives/footer'
import Leftsidebar from '../directives/leftsidebar'
// import Footer from '../directives/footer'
import axios from 'axios'
import config from '../config/config'
import toast, { Toaster } from 'react-hot-toast';
import 'react-toastify/dist/ReactToastify.css';
import Cookies from 'js-cookie';
import moment from "moment";

const headers = {
  'Content-Type': 'application/json'
};

export default class Transactions extends Component {

  constructor(props) {
    super(props)
    this.state = {
      transactions: [],
      pending_trx_count:0,
      success_trx_count:0,
      failed_trx_count:0,
    }

    this.loginData = (!Cookies.get('loginSuccesGarryToken')) ? [] : JSON.parse(Cookies.get('loginSuccesGarryToken'));
  }

  componentDidMount() {
    this.getMyRecentTransactions();
    document.getElementById("transactions").classList.add("active");
  }

  async getMyRecentTransactions() {
    let obj = {
      user_id: this.loginData.data.id
    }
    axios.post(`${config.apiUrl}getMyAllTransactions`, obj, { headers })
      .then(response => {
        if (response.data.success === true) {
          this.setState({
            transactions: response.data.response,
            allTransactions: response.data.response
          })

          let allData = response.data.response;
          var pending_transactions = allData.filter(function (el) {
            return el.status ==  0
          });
          var success_transactions = allData.filter(function (el) {
            return el.status ==  1
          });
          var failed_transactions = allData.filter(function (el) {
            return el.status ==  2
          });
          // console.log(selected_card)
          this.setState({
            pending_trx_count: pending_transactions.length,
            success_trx_count: success_transactions.length,
            failed_trx_count: failed_transactions.length
          })

        }
      })
      .catch(err => {
        // toast.error(err.response.data?.msg);
      })
  }

  async fiterTransactions(trx_status)
  {
    let allData = this.state.allTransactions;
    var transactions = allData.filter(function (el) {
      return el.status ==  trx_status
    });
    // console.log(selected_card)
    await this.setState({
      transactions: transactions
    })
  }

  render() {
    return (
      <div>

        <div className="">
          <Leftsidebar />
          {/* Content Start */}
          <div className="content">
            <Header />


            <div className="row" id='oldtransid'>
              <div className="quikdiv failed one">
                <span><img src="icons/tools.png" alt="tools" className="toolsimg" /></span>
                <h6 className="mb-0 clalehaed">Quick Tools</h6>
              </div>
              <div className="quiktools one" id='quikoldid'>
                <div className="col-lg-4 col-md-4 col-sm-12 col-12 trx_statics" onClick={this.fiterTransactions.bind(this, 0)}>
                  <div className="managebox2 failed" id='recnetdiv'>
                    <div className="quikicons">
                      <img src="icons/yelloplus.png" alt="yelloplus" />
                    </div>
                    <div className="quiktext">
                      <h5 className="review1">Click to review</h5>
                      <h3 className="failed">{this.state.pending_trx_count}</h3>
                      <p>List of pending transctions</p>
                    </div>
                  </div>
                  
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 col-12 trx_statics" onClick={this.fiterTransactions.bind(this, 2)}>
                  <div className="managebox2 failed">
                    <div className="quikicons">
                      <img src="icons/corss.png" alt="corss" />
                    </div>
                    <div className="quiktext">
                      <h5 className="review1">Click to review</h5>
                      <h3 className="failed">{this.state.failed_trx_count}</h3>
                      <p>List of cancelled transactions</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 col-12 trx_statics" onClick={this.fiterTransactions.bind(this, 1)}>
                  <div className="managebox2 failed">
                    <div className="quikicons">
                      <img src="icons/success-svgrepo-com 1.png" alt="success-svgrepo-com 1" />
                    </div>
                    <div className="quiktext">
                      <h5 className="review1">Click to review</h5>
                      <h3 className="failed">{this.state.success_trx_count}</h3>
                      <p>List of succesful transactions</p>
                    </div>
                  </div>
                </div>
              </div>
              
              <div className="quiktools one" id='quiknewid'>
                <div className="col-lg-4 col-md-4 col-sm-12 col-12" >
                  <div className="managebox2 failed">
                  <div className='filterinner'>
                        <span><img src="icons/blockfilter.png" alt="blockfilter" className='blockfilter'/></span>
                        <h5 className="review1">Filter by</h5>
                      </div>
                    <div className='quikinner'>
                    <div className="quikicons">
                      <img src="icons/recnetico.png" alt="recnetico" />
                    </div>
                    <div className="quiktext">
                      <h3 className="failed">Recent</h3>
                      <p>Recent transctions</p>
                    </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 col-12 trx_statics" onClick={this.fiterTransactions.bind(this, 0)}>
                  <div className="managebox2 failed">
                  <div className='filterinner'>
                        <span><img src="icons/blockfilter.png" alt="blockfilter" className='blockfilter'/></span>
                        <h5 className="review1">Filter by</h5>
                      </div>
                    <div className='quikinner'>
                    <div className="quikicons">
                      <img src="icons/yelloplus.png" alt="yelloplus" />
                    </div>
                    <div className="quiktext">
                      <h3 className="failed">Pending</h3>
                      <p>Pending transctions</p>
                    </div>
                    </div>
                  </div>
                </div>  
                <div className="col-lg-4 col-md-4 col-sm-12 col-12" onClick={this.fiterTransactions.bind(this, 2)}>
                  <div className="managebox2 failed">
                  <div className='filterinner'>
                        <span><img src="icons/blockfilter.png" alt="blockfilter" className='blockfilter'/></span>
                        <h5 className="review1">Filter by</h5>
                    </div>
                    <div className='quikinner'>
                    <div className="quikicons">
                      <img src="icons/corss.png" alt="corss" />
                    </div>
                    <div className="quiktext">
                      <h3 className="failed">Failed</h3>
                      <p>Failed transactions</p>
                    </div>
                    </div>
                  </div>
                </div> 
                <div className="col-lg-4 col-md-4 col-sm-12 col-12" onClick={this.fiterTransactions.bind(this, 1)}>
                  <div className="managebox2 failed">
                  <div className='filterinner'>
                        <span><img src="icons/blockfilter.png" alt="blockfilter" className='blockfilter'/></span>
                        <h5 className="review1">Filter by</h5>
                    </div>
                    <div className='quikinner'>
                    <div className="quikicons">
                      <img src="icons/success-svgrepo-com 1.png" alt="success-svgrepo-com 1" />
                    </div>
                    <div className="quiktext">
                      <h3 className="failed">Success</h3>
                      <p>Succesful transactions</p>
                    </div>
                    </div>
                  </div>
                </div> 
              </div>

            </div>
            

            <div className="row" id='newtransid'>
              <div className="quikdiv failed one" id='recentfilter'>
                <span><img src="icons/tools.png" alt="tools" className="toolsimg" /></span>
                <h6 className="mb-0 clalehaed">Quick Tools</h6>
              </div>
              <div className="quiktools one" id='quikoldid'>
                {/* <div className="col-lg-4 col-md-4 col-sm-12 col-12 trx_statics" onClick={this.fiterTransactions.bind(this, 0)}>
                  <div className="managebox2 failed">
                    <div className="quikicons">
                      <img src="icons/recnetico.png" alt="recnetico" />
                    </div>
                    <div className="quiktext">
                      <div className='filterdiv'>
                      <span><img src="icons/blockfilter.png" alt="blockfilter" className="filterimg" /></span>
                      <h5 className="review1">Filter by</h5>
                      </div>
                      <h3 className="failed">Recent</h3>
                      <p>Recent transctions</p>
                    </div>
                  </div>
                  
                </div> */}
                <div className="col-lg-4 col-md-4 col-sm-12 col-12 trx_statics" onClick={this.fiterTransactions.bind(this, 0)}>
                <a href={`${config.baseUrl}transactionsList/pending`}>
                  <div className="managebox2 failed">
                    <div className="quikicons">
                      <img src="icons/yelloplus.png" alt="yelloplus" />
                    </div>
                    <div className="quiktext">
                    <div className='filterdiv'>
                      <span><img src="icons/blockfilter.png" alt="blockfilter" className="filterimg" /></span>
                      <h5 className="review1">Filter by</h5>
                      </div>
                      <h3 className="failed">Pending</h3>
                      <p>Pending transctions</p>
                    </div>
                  </div>
                  </a>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 col-12 trx_statics" onClick={this.fiterTransactions.bind(this, 2)}>
                <a href={`${config.baseUrl}transactionsList/failed`}>
                  <div className="managebox2 failed">
                    <div className="quikicons">
                      <img src="icons/corss.png" alt="corss" />
                    </div>
                    <div className="quiktext">
                    <div className='filterdiv'>
                      <span><img src="icons/blockfilter.png" alt="blockfilter" className="filterimg" /></span>
                      <h5 className="review1">Filter by</h5>
                      </div>
                      <h3 className="failed">Failed</h3>
                      <p>Canclled transactions</p>
                    </div>
                  </div>
                  </a>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 col-12 trx_statics" onClick={this.fiterTransactions.bind(this, 1)}>
                <a href={`${config.baseUrl}transactionsList/success`}>
                  <div className="managebox2 failed">
                    <div className="quikicons">
                      <img src="icons/success-svgrepo-com 1.png" alt="success-svgrepo-com 1" />
                    </div>
                    <div className="quiktext">
                    <div className='filterdiv'>
                      <span><img src="icons/blockfilter.png" alt="blockfilter" className="filterimg" /></span>
                      <h5 className="review1">Filter by</h5>
                      </div>
                      <h3 className="failed">Success</h3>
                      <p>Succesful transactions</p>
                    </div>
                  </div>
                  </a>
                </div>
              </div>
              
              <div className="quiktools one" id='quiknewid'>
                <div className="col-lg-4 col-md-4 col-sm-12 col-12" >
                  
                  <div className="managebox2 failed">
                  <div className='filterinner'>
                        <span><img src="icons/blockfilter.png" alt="blockfilter" className='blockfilter'/></span>
                        <h5 className="review1">Filter by</h5>
                      </div>
                    <div className='quikinner'>
                    <div className="quikicons">
                      <img src="icons/recnetico.png" alt="recnetico" />
                    </div>
                    <div className="quiktext">
                      <h3 className="failed">Recent</h3>
                      <p>Recent transctions</p>
                    </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 col-12 trx_statics" onClick={this.fiterTransactions.bind(this, 0)}>
                  <div className="managebox2 failed">
                  <div className='filterinner'>
                        <span><img src="icons/blockfilter.png" alt="blockfilter" className='blockfilter'/></span>
                        <h5 className="review1">Filter by</h5>
                      </div>
                    <div className='quikinner'>
                    <div className="quikicons">
                      <img src="icons/yelloplus.png" alt="yelloplus" />
                    </div>
                    <div className="quiktext">
                      <h3 className="failed">Pending</h3>
                      <p>Pending transctions</p>
                    </div>
                    </div>
                  </div>
                </div>  
                <div className="col-lg-4 col-md-4 col-sm-12 col-12" onClick={this.fiterTransactions.bind(this, 2)}>
                  <div className="managebox2 failed">
                  <div className='filterinner'>
                        <span><img src="icons/blockfilter.png" alt="blockfilter" className='blockfilter'/></span>
                        <h5 className="review1">Filter by</h5>
                    </div>
                    <div className='quikinner'>
                    <div className="quikicons">
                      <img src="icons/corss.png" alt="corss" />
                    </div>
                    <div className="quiktext">
                      <h3 className="failed">Failed</h3>
                      <p>Failed transactions</p>
                    </div>
                    </div>
                  </div>
                </div> 
                <div className="col-lg-4 col-md-4 col-sm-12 col-12" onClick={this.fiterTransactions.bind(this, 1)}>
                  <div className="managebox2 failed">
                  <div className='filterinner'>
                        <span><img src="icons/blockfilter.png" alt="blockfilter" className='blockfilter'/></span>
                        <h5 className="review1">Filter by</h5>
                    </div>
                    <div className='quikinner'>
                    <div className="quikicons">
                      <img src="icons/success-svgrepo-com 1.png" alt="success-svgrepo-com 1" />
                    </div>
                    <div className="quiktext">
                      <h3 className="failed">Success</h3>
                      <p>Succesful transactions</p>
                    </div>
                    </div>
                  </div>
                </div> 
              </div>

            </div>

            
            <div className="row" id="johndashidtwo">
              {/* <h5 class="johndash">John Doe  /  Add card</h5> */}
              <div className="usertransaction" id="usertransid">
                <div className="col-lg-12 col-md-12 col-12">
                  <div className="bg-light">
                    <div className="d-flex align-items-center justify-content-between mb-3">
                      <h6 className="mb-0 clalehaed">Transaction</h6>
                    </div>
                    <table className="tablecolor" id='tratableid'>
                      <tbody>
                        {this.state.transactions.length ?
                          this.state.transactions.map((item, i) =>
                            <tr>
                              <td className="imgborder1">
                                <button type="button" className="btn btn-dark m-2 green">{moment(item.datetime).format("MMM").toUpperCase()}</button>
                              </td>
                              <td className="textcolor">
                                GARRI {item.garri_token_amount}
                                <p className="date">{moment(item.datetime).format("DD/MM/YYYY")}</p>
                              </td>
                              <td>
                                {item.currency_name} {item.currency_amount}
                                {/* <p class="ngnbtn">Success</p> */}
                              </td>
                              <td>
                                {item.status == 0 ? <p className="pendingbtn">Pending</p> : ''}
                                {item.status == 1 ? <p className="successbtn">Success</p> : ''}
                                {item.status == 2 ? <p className="failledbtn">Failed</p> : ''}
                              </td>
                              <td>
                              {item.payment_method == 'CARD' ? 'Card payment' : '' }
                              {item.payment_method == 'BANK' ? 'Direct bank deposit' : '' }
                              {item.payment_method == 'CRYPTO' ? 'Wallet payment'
                                // <a target="__blank" href={`https://testnet.bscscan.com/address/${item.from_address}`}>{item.from_address}</a>
                              : '' }
                                
                              </td>
                            </tr>
                          )
                          :
                          <tr>
                            <td colSpan={5}>
                              <center>No Transaction Found.</center>
                            </td>
                          </tr>
                        }
                        
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              {/* <div className="circlediv">
                <div className="smallcircle" />
                <div className="smallcircle" />
                <div className="smallcircle" />
                <div className="smallcircle active" />
              </div> */}
            </div>



          </div>
        </div>
        <Footer />
      </div>
    );

  }
}