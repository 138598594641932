
import React, { Component } from 'react';
import axios from 'axios';
import config from '../config/config'
import Header from '../directives/header'
import Footer from '../directives/footer'
import Leftsidebar from '../directives/leftsidebar'
import toast, { Toaster } from 'react-hot-toast';
import Cookies from 'js-cookie';
import 'react-toastify/dist/ReactToastify.css';
// import { toHaveDisplayValue } from '@testing-library/jest-dom/dist/matchers';


import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

const headers = {
    'Content-Type': 'application/json'
};

export default class UpdateProfile extends Component {
    constructor(props) {
        super(props)
        this.state = {
            id: '',
            item_id: '',
            email: '',
            first_name: '',
            last_name: '',
            phone_number: '',
            dob: '',
            gender: '',
            country_id: '',
            state_id: '',
            city: '',
            address: '',
            profile_pic: 'img/avatar.png',
            phonecode: [],
            name: [],
            item_list1: '',
            user: [],
            // phonecode1: 234,
            // country: '',
            // state: '',
            // city: '',
            stateList: [],
            cityList: [],
            // days1: '',
            // month1: '',
            phone_code: 234,
            gender1: '',
            otp_field: false,
            otp_verify_btn: false,
            update_btn_text: 'Update'
        }
        this.onChange = this.onChange.bind(this);
        // this.submitForm = this.submitForm.bind(this);
        this.handleChangeImage = this.handleChangeImage.bind(this);
        this.onHandleTelephoneChange = this.onHandleTelephoneChange.bind(this);
        this.loginData = (!Cookies.get('loginSuccesGarryToken')) ? [] : JSON.parse(Cookies.get('loginSuccesGarryToken'));

    }

    componentDidMount() {

        this.getUserProfileAPI();
        this.updateProfileAPI();
        this.getUserCountryAPI();
        // this.getUserStateAPI();
        // this.getUserCityAPI();
        document.getElementById("set_nav").classList.add("show");
        document.getElementById("set_nav").classList.add("active");
        document.getElementById("set_option_div").classList.add("show");
        document.getElementById("set_nav").setAttribute("aria-expanded", true);
    }
    onChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onHandleTelephoneChange(e) {
        let telephone = e.target.value;

        if (telephone == '') {

        }
        else {
            if ((!Number(telephone))) {
                return;
            }
        }
        if (telephone.length > 10) {
            return;
        }
        this.setState({
            [e.target.name]: telephone
        });
    };

    handleChangeImage = (e) => {
        let image_as_base64 = URL.createObjectURL(e.target.files[0])
        let image_as_files = e.target.files[0];

        if (!image_as_files) {
            this.setState({ invalidImage: 'Please select image.' });
            return false;
        }

        this.setState({
            profile_pic: image_as_base64,
            image_file: image_as_files,
        })
    }

    async getUserCountryAPI() {
        axios.get(`${config.apiUrl}getCountry`, {},)
            .then(response => {
                if (response.data.success === true) {
                    this.setState({
                        phonecode: response.data.response,
                    })
                }
                else if (response.data.success === false) {
                }
            })
            .catch(err => {
            })
    }

    async getUserStateAPI(id) {
        console.log('country', id)
        await axios.post(`${config.apiUrl}getState`, { 'country_id': id },)
            .then(response => {
                if (response.data.success === true) {
                    this.setState({
                        stateList: response.data.response
                    })
                    // if(this.state.state_id)
                }
                else if (response.data.success === false) {
                }
            })
            .catch(err => {
                this.setState({
                    name: []
                })
            })
    }

    async getUserCityAPI(id) {
        console.log("ID", id)
        axios.post(`${config.apiUrl}getCity`, { 'state_id': id },)
            .then(response => {
                if (response.data.success === true) {
                    this.setState({
                        cityList: response.data.response
                    })
                }
                else if (response.data.success === false) {
                }
            })
            .catch(err => {
            })
    }


    async getUserProfileAPI() {

        axios.post(`${config.apiUrl}getUserProfile`, { 'email': this.loginData?.data.email },)
            .then(response => {
                if (response.data.success === true) {
                    console.log('res', response.data)
                    this.setState({
                        id: response.data.response.id,
                        email: response.data.response.email,
                        first_name: response.data.response.first_name,
                        last_name: response.data.response.last_name,
                        old_phone_number: response.data.response.phone_number,
                        phone_number: response.data.response.phone_number,
                        // phone_code: response.data.response.phone_code,
                        dob: response.data.response.dob,
                        gender: response.data.response.gender,
                        country: response.data.response.country_id,
                        city: response.data.response.city,
                        address: response.data.response.address,
                        profile_pic: (response.data.response.profile_pic ? config.apiUrl + response.data.response.profile_pic : 'img/avatar.png')
                    })
                    if (response.data.response.country_id != '') {
                        this.getUserStateAPI(response.data.response.country_id);
                        this.setState({
                            state: response.data.response.state_id
                        })
                    }
                }
                else if (response.data.success === false) {
                }
            })
            .catch(err => {
            })
    }

    async updateProfileAPI(e) {
        e.preventDefault()
        // console.log('hello', this.state.email)
        this.setState({
            update_btn_text: 'Please wait'
        })
        if (this.state.old_phone_number == this.state.phone_number) {
            this.update_profile();
        }
        else {
            const data = {
                mobile_no: '+' + this.state.phone_number
            }
            await axios.post(`${config.apiUrl}send_otp_profile`, data, { headers })
                .then(response => {
                    if (response.data.success === true) {
                        toast.success(response.data.msg);
                        this.setState({
                            // enc_otp:response.data.data,
                            otp_field: true,
                            otp_verify_btn: true
                        })
                        // window.scrollTo({ top: 0, behavior: 'smooth' });
                        window.scrollTo(400, 400);
                    }
                    else if (response.data.success === false) {
                        toast.error(response.data.msg);
                    }
                })
                .catch(err => {
                    toast.error(err.response.data?.msg);
                })
        }
    }

    async verifyOtpAndupdateProfile() {
        if (this.state.otp) {
            await this.update_profile();
        }
        else {
            await toast.error('OTP required.');
            return;
        }
    }

    async update_profile() {

        const formData = new FormData();
        formData.append('id', this.state.id);
        formData.append('email', this.state.email);
        formData.append('first_name', this.state.first_name);
        formData.append('last_name', this.state.last_name);
        formData.append('phone_number', this.state.phone_number);
        formData.append('dob', this.state.dob);
        formData.append('gender', this.state.gender);
        formData.append('country', this.state.country);
        formData.append('state', this.state.state);
        formData.append('city', this.state.city);
        formData.append('address', this.state.address);
        formData.append('profile_pic', this.state.image_file);
        if (this.state.otp_field) {
            formData.append('entered_otp', this.state.otp);
        }
        await axios({
            method: 'post',
            url: `${config.apiUrl}updateProfile`,
            data: formData
        }).then(result => {
            if (result.data.success == true) {
                toast.success('Profile Updated Successfully');
                window.scrollTo(0, 0)
                setTimeout(() => {
                    window.location.reload()
                }, 1500);
            }
            else {
                toast.error(result.data.msg, {});
            }
        })
            .catch(err => {
                toast.error(err.response.data.msg, {});
            });
    }

    handleChange1 = e => {
        console.log('e', e.target.name)
        this.setState({
            [e.target.name]: e.target.value
        })
        if (e.target.name === 'country') {
            this.getUserStateAPI(e.target.value)
        }
        if (e.target.name === 'state') {
            this.getUserCityAPI(e.target.value)
        }
    }

    async send_otp_on_call(e) {
        e.preventDefault()
        const data = {
            mobile_no: this.state.phone_number
        }
        axios.post(`${config.apiUrl}otp_on_call`, data, { headers })
            .then(response => {
                if (response.data.success === true) {
                    toast.success(response.data.msg);
                    this.setState({
                        otp_screen: true,
                        reg_screen: false
                    })
                }
                else if (response.data.success === false) {
                    toast.error(response.data.msg);
                }
            })
            .catch(err => {
                toast.error(err.response.data?.msg);
            })
    }

    render() {
        return (

            <div>
                 <Toaster
                        position="top-right"
                        reverseOrder={false}
                    />
                <div className="">
                    <Leftsidebar />

                   

                    {/* Content Start */}
                    <div className="content" id='profilebg'>
                        <Header />

                        <div className="row" id="johndashidone">
                            <form action="#" id="user_profileid">

                                <div className="userprofileblock">

                                    <img className id="userpro" src={this.state.profile_pic} alt="" style={{ width: '40px', height: '40px' }} />
                                    <a href="#" className="">
                                        <img src={this.state.profile_pic} alt="client" id="clientid" /></a>
                                    <div className="input-file-container">
                                        <input className="input-file" id="my-file" type="file" onChange={this.handleChangeImage} />
                                        <label tabIndex={0} htmlFor="my-file" className="input-file-trigger">
                                            <img src="icons/photo-file-svgrepo-com 1.png" alt="photo-file-svgrepo-com 1" className="uploadinput" />Upload
                                        </label>
                                    </div>
                                </div>
                                <div className="userprobox">
                                    <div className="multinput1">
                                        <div className="selectext">
                                            <h3>First Name</h3>
                                            <p>Enter same name on your id card</p>
                                        </div>
                                        <div className="input-group mb-3 userpro1">
                                            <input type="text" onChange={this.handleChange1} name="first_name" className="form-control" placeholder="First Name" value={this.state.first_name} required />
                                        </div>
                                    </div>

                                    <div className="multinput1">
                                        <div className="selectext">
                                            <h3>Last Name</h3>
                                            <p>Enter same name on your id card</p>
                                        </div>
                                        <div className="input-group mb-3 userpro1">
                                            <input type="text" onChange={this.handleChange1} name="last_name" className="form-control" placeholder="Last Name" value={this.state.last_name} required />
                                        </div>
                                    </div>

                                </div>
                                <div className="userprobox">
                                    <div className="multinput1">
                                        <div className="selectext">
                                            <h3>Phone Number</h3>
                                            <p>Enter phone number on your BVN</p>
                                        </div>
                                        <div className="userphoneinput">

                                            <PhoneInput
                                                country={'in'}
                                                value={this.state.phone_number}
                                                onChange={phone_number => this.setState({ phone_number })}
                                            />
                                        </div>
                                    </div>

                                    {this.state.otp_field ?
                                        <>
                                                <div className="multinput1" style={{marginBottom: '45px'}}>
                                                    <div className="selectext">
                                                        <h3>Mobile no. OTP Authentication</h3>
                                                        <p style={{ color: 'green' }}>OTP has been sent to your mobile number.</p>
                                                    </div>
                                                    <div className="userphoneinput">
                                                        <input type="number" name="otp" onChange={this.onChange} value={this.state.otp} className="form-control" min={0} placeholder="Enter OTP" />

                                                    </div>
                                                    <b style={{ marginTop: '10px', position: 'absolute' }}><a onClick={this.send_otp_on_call.bind(this)} href="javascript:;" style={{color: '#009CFF'}} ><i className='fa fa-phone'></i> Request OTP On Call?</a></b>
                                                </div>
                                            
                                        </>
                                        :
                                        ''
                                    }



                                </div>
                                <div className="userprobox" style={{marginBottom: '30px'}}>
                                    <div className="multinput1">
                                        <div className="selectext">
                                            <h3>Date of Birth</h3>
                                            <p>Enter your date of birth same as on your id card </p>
                                        </div>
                                        <div className="userphoneinput">
                                            <input type="date" className="form-control active" name="dob" onChange={this.onChange} value={this.state.dob} required />
                                        </div>
                                    </div>
                                    <div className="multinput1" id="genderids">
                                        <div className="selectext">
                                            <h3>Gender</h3>
                                            <p>Indicate your sex </p>
                                        </div>
                                        <div className="userphoneinput1">
                                            <div className="form-check form-check-inline">
                                                <label className="form-check-label" htmlFor="inlineRadio1">
                                                    <input className="form-check-input" type="radio" name="gender" onChange={this.handleChange1} value="0" id="inlineRadio1" checked={this.state.gender == 0 ? true : false} defaultValue="option1" />
                                                    Male</label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <label className="form-check-label" htmlFor="inlineRadio2">
                                                    <input className="form-check-input" type="radio" name="gender" onChange={this.handleChange1} value="1" id="inlineRadio2" defaultValue="option2" checked={this.state.gender == 1 ? true : false} />
                                                    Female</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="selectbox">
                                    <h3>Country</h3>
                                    <p>Select country of residence</p>
                                    <div className="input-group selectrow">

                                        <div className="userphoneinput">

                                            <select name="country" onChange={this.handleChange1} value={this.state.country} class="form-control  basic">
                                                {/* <button className="btn btn-outline-secondary dropdown-toggle" id="selecttoggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" /> */}
                                                <option selected="selected" value="">Select Country</option>
                                                {this.state.phonecode.map(item => (
                                                    <option value={item.id}>{item.name}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div className="userprobox">
                                    <div className="multinput1">
                                        <div className="selectext">
                                            <h3>State</h3>
                                            <p>Select state of residence</p>
                                        </div>
                                        <div className="input-group mb-3 selectrow">

                                            <div className="userphoneinput" style={{ width: '100%' }}>

                                                <select name="state" onChange={this.handleChange1} value={this.state.state} class="form-control  basic">
                                                    <button className="btn btn-outline-secondary dropdown-toggle" id="selecttoggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" />
                                                    <option selected="selected" value="">Select State</option>
                                                    {this.state.stateList.map(item => (
                                                        <option value={item.id}>{item.name}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="multinput1">
                                        <div className="selectext">
                                            <h3>City</h3>
                                            <p>Select city of residence</p>
                                        </div>
                                        <div className="userphoneinput">

                                            <input type="text" name="city" onChange={this.onChange} value={this.state.city == 'null' ? '' : this.state.city} className="form-control" placeholder="City" />
                                            {/* <select name="city" onChange={this.handleChange1} value={this.state.city} class="form-control  basic">
                                                <button className="btn btn-outline-secondary dropdown-toggle" id="selecttoggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" />
                                                <option selected="selected" value="">Select City</option>
                                                {this.state.cityList.map(item => (
                                                    <option value={item.id}>{item.name}</option>
                                                ))}
                                            </select> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="selectbox" id="genderids">
                                    <h3>Address</h3>
                                    <p>Enter address</p>
                                    <div className="input-group mb-3 selectrow">
                                        <textarea name='address' className='form-control' placeholder='Address' onChange={this.onChange} value={this.state.address}>{this.state.address}</textarea>
                                        {/* <input type="text" name="address" onChange={this.handleChange1} value={this.state.address} className="form-control" placeholder /> */}
                                    </div>
                                </div>




                                <div className="bankbtn mb-lg-5 mb-sm-0" id="bankbtnid">
                                    <center>
                                        {this.state.otp_verify_btn ?
                                            <button type='button' className="btn btn-success expirydatesubmit" onClick={this.verifyOtpAndupdateProfile.bind(this)} >Verify & Update</button>
                                            :
                                            <button className="btn btn-success expirydatesubmit" onClick={this.updateProfileAPI.bind(this)} >{this.state.update_btn_text}</button>
                                        }
                                    </center>
                                </div>

                                {/* <div className="bankbtn" id="submitbtnid">
                                    <button className="btn btn-success expirydatesubmit">Submit</button>
                                </div> */}

                            </form>
                        </div>
                    </div>
                </div >
                <Footer />
            </div >

        );
    }
}