import React, { Component } from 'react';
import Header from '../directives/header'
import Footer from '../directives/footer'
import Leftsidebar from '../directives/leftsidebar'
// import Footer from '../directives/footer'
import axios from 'axios'
import config from '../config/config'
import Cookies from 'js-cookie';
import toast from 'react-hot-toast';
import Swal from 'sweetalert2';
import Web3 from 'web3';
import Onboard from 'bnc-onboard';
import { BrowserView, MobileView } from 'react-device-detect';
import { Modal, Button } from "react-bootstrap";

const headers = {
  'Content-Type': 'application/json'
};

export default class PayWithCrypto extends Component {

  constructor(props) {
    super(props)
    this.state = {
      wallet_address: '',
      adminWallet:'',
      // wallet_type:1,
      isOpen: false,
      connected_wallet:'',
      isMetamask:false,
    }
    this.onChange = this.onChange.bind(this);
    this.loginData = (!Cookies.get('loginSuccesGarryToken')) ? [] : JSON.parse(Cookies.get('loginSuccesGarryToken'));
    this.loginData_metamask = (!Cookies.get('loginMetamask_garry') ? null : Cookies.get('loginMetamask_garry'));
  }
  async componentDidMount() {
    this.get_current_transaction();
    this.get_adminWallet();

    // await this.checkIfWalletIsConnected();
    // await this.checkMetaMask();
    setTimeout(async() => {
      
    if(window.ethereum)
    {
      let web3 = new Web3(window.ethereum);
      let tr_address = await web3.eth.getAccounts();
      if(tr_address.length > 0)
      {
        
        await this.setState({
          connected_wallet:tr_address[0],
          wallet_address:tr_address[0],
          isMetamask:(web3.currentProvider.isMetaMask ? web3.currentProvider.isMetaMask : false),
        })
        await Cookies.set('loginMetamask_garry', tr_address[0]);
      }
    }
  }, 600);
  }

  async get_adminWallet()
  {
    axios.get(`${config.apiUrl}get_adminWallet`)
      .then(response => {
          if (response.data.success === true) {
            // console.log(response.data)
              if(this.state.currency == 1)
              {
                this.setState({
                  adminWallet:response.data.USDT_address
                })
              }
              else if(this.state.currency == 2)
              {
                this.setState({
                  adminWallet:response.data.BUSD_address
                })
              }
          }
      })
      .catch(err => {
      })
  }

  async get_current_transaction() {
    let obj = {
      user_id: this.loginData.data.id,
    }
    axios.post(`${config.apiUrl}getTemporaryData`, obj, { headers })
      .then(response => {
        if (response.data.success === true) {
          // console.log(response.data);
          this.setState({
            payble_amount: parseFloat(response.data.response.currency_amount) + parseFloat(response.data.response.fees),
            user_email: response.data.response.email,//response.data.user_name,
            currency_name: response.data.response.currency_name,
            currency: response.data.response.currency,
            currency_amount: response.data.response.currency_amount,
            garri_token_amount: response.data.response.garry_token_amount,
            fees: response.data.response.fees,
            usd_amount: response.data.response.usd_amount,
          })
        }
        else if (response.data.success === false) {
          // toast.error(response.data.msg);
          window.location.href = config.baseUrl;
        }
      })
      .catch(err => {
        // toast.error(err.response.data?.msg);
        window.location.href = config.baseUrl;
      })
  }

  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  copyToClipboard = async(e) => {
    if(this.state.wallet_address != '')
    {
      await navigator.clipboard.writeText(this.state.wallet_address);
      toast.success('Address copied!')
    }
  };

async processingAlert()
{
  let timerInterval
  Swal.fire({
    title: '<span style="color:red;">Please Wait</span>',
    html: '<b>Transaction is being process...</b></br><p>Please do not close the window</p>',
    timer: 1000000,
    timerProgressBar: true,
    allowOutsideClick: false,
    didOpen: () => {
      Swal.showLoading()
    },
    willClose: () => {
      clearInterval(timerInterval)
    }
  }).then((result) => {
    /* Read more about handling dismissals below */
    if (result.dismiss === Swal.DismissReason.timer) {
      console.log('Transaction done')
    }
  })
}

async closeProcessingAlert()
{
  Swal.close()
}

async purchaseItem() {
    let coin = this.state.currency_name;
    // console.log(this.state.currency_name);
    // return;
    if (this.state.wallet_address != '') {
       
       if (window.ethereum) {

          let web3 = new Web3(window.ethereum);
          let accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });

          let currentNetwork = await web3.currentProvider.chainId;
          // console.log(">>", currentNetwork)
          web3.eth.defaultAccount = accounts[0];
        
          // alert(accounts[0])
          // var ETH_mainnet = 0x1;
          // var ETH_testnet = 0x3;

          // var bnb_mainnet = 0x38;  // 56
          // var bnb_testnet = 0x61;  // 97
          
          if (currentNetwork != '0x38' && currentNetwork != '56') {
             toast.error('Please select BNB smartchain!! ');
             this.closeProcessingAlert()
             return false;
          }
          var chainId = currentNetwork;
         
          try {
             let to_address = this.state.adminWallet // adminWallet Get from database

             let token_transfer_address = this.state.wallet_address
             let amount = parseInt(parseFloat(this.state.payble_amount) * 10 ** 18) 
             amount = amount.toLocaleString('fullwide', {useGrouping:false}) 
             

             let from_address = accounts[0];
             let  contractAddress = config.USDT_TOKEN;
             if(coin == 'BUSD'){
               contractAddress = config.BUSD_TOKEN;
             }
             
             const contract = await new web3.eth.Contract(config.ERC20_ABI, contractAddress);
            
             let tokenBalance = await contract.methods.balanceOf(from_address).call();
             if(parseInt(amount) > parseInt(tokenBalance)){
              toast.error('Insufficient token balance!!');
              this.closeProcessingAlert()
              return false;
             }
             this.processingAlert()
             let tx_builder = await contract.methods.transfer(to_address,amount.toString());
             let encoded_tx = tx_builder.encodeABI();
             
             let gasPrice = await web3.eth.getGasPrice();
             gasPrice = parseInt(gasPrice) + parseInt(10000000000);

             let gasLimit = await web3.eth.estimateGas({
                gasPrice: web3.utils.toHex(gasPrice),
                to: contractAddress,
                from: from_address,
                chainId: chainId,
                data: encoded_tx
             });

             const txData = await web3.eth.sendTransaction({
                gasPrice: web3.utils.toHex(gasPrice),
                gas: web3.utils.toHex(gasLimit),
                to: contractAddress,
                from: from_address,
                chainId: chainId,
                data: encoded_tx
             });
            
             if (txData.transactionHash) {

                var transaction_arr = {
                  user_id: this.loginData.data.id,
                  from_address: from_address,
                  to_address: to_address,
                  token_transfer_address:token_transfer_address,
                  total_amount: amount,
                  hash: txData.transactionHash,
                  currency:this.state.currency,
                  currency_amount:this.state.currency_amount,
                  garri_token_amount:this.state.garri_token_amount,
                  fees:this.state.fees,
                  usd_amount: this.state.usd_amount,
                  payment_method:'CRYPTO'
                }
                // console.log(transaction_arr);
                this.save_transaction(transaction_arr);

             } else {
                this.closeProcessingAlert()
                toast.error('Something went wrong please try again.');
                return false;
             }

          } catch (err) {
             this.closeProcessingAlert()
             if (err.message.toString().split('insufficient funds')[1]) {
                toast.error('Transaction reverted : ' + err.message)
             } else {
                if (err.toString().split('execution reverted:')[1]) {
                   toast.error('Transaction reverted : ' + (err.toString().split('execution reverted:')[1]).toString().split('{')[0])

                } else {
                   toast.error(err.message);
                }
             }
             return false;
          }
       } else {
          toast.error('Please Connect to Wallet.');
          this.closeProcessingAlert()
          return false;
       }
    }
    else{
      toast.error('Please enter wallet address.');
    }
 }

 async save_transaction(transaction_arr) {

  console.log(transaction_arr)
  axios({
     method: 'post',
     url: `${config.apiUrl}submit_pay_with_crypto`,
     data: transaction_arr
  }).then(result => {
     console.log(result);

     this.closeProcessingAlert()

     if (result.data.success == true) {
        toast.success(result.data.msg, {});
        setTimeout(() => {
           window.location.href = config.baseUrl+'confirmation';
           // window.location.reload();
        }, 2000)
     }
     else {
        toast.error(result.data.msg, {});
     }
  })
     .catch(err => {
        this.closeProcessingAlert()
        this.openAlert('Ok', 'Somthing went wrong. Please try again.', 'warning', '');
        // toast.error(err.result.data.msg, {});
     });
}

async openAlert(btn_text, msg, icon, image)
{
 Swal.fire({
    // title: 'Are you sure?',
    text: msg,
    icon: icon,
    imageUrl: image,        
    imageHeight: 65, 
    imageWidth: 65,
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: btn_text,
    reverseButtons: true
  }).then((result) => {
    if (result.isConfirmed) {
      if(icon=='')
      {
        window.open('https://metamask.io/', '_blank');
      }
      else{
        window.location.reload();
      }
      // window.location.href = '';
      //   Swal.fire(
      //     'Deleted!',
      //     'Your file has been deleted.',
      //     'success'
      //   )
    }
  })
}

/// Wallet connectivity

async checkMetaMask() {
  const { ethereum } = window;
  if (ethereum) {
    ethereum.on("accountsChanged", accounts => {
      if (accounts.length > 0) {
        // this.logout(accounts)
        let web3 = new Web3(window.ethereum);
        let currentNetwork = web3.currentProvider.chainId;
        // var ETH_mainnet = 0x1;
        // var ETH_testnet = 0x3;

        // var bnb_mainnet = 0x38;
        // var bnb_testnet = 0x61;
        if (currentNetwork != '56' && currentNetwork != '0x61') {
          Cookies.set('loginMetamask_garry', "");
          return;
        }
        Cookies.set('loginMetamask_garry', ethereum.selectedAddress);
      }
      else
        Cookies.set('loginMetamask_garry', "");
    });

    ethereum.on('networkChanged', (chainId) =>
      this.networkChanged(chainId)
    );
  }
}

async connect_metamask(onConnected) {
  if (!window.ethereum) {
    let icon = '';
    Swal.fire({
      // title: 'Are you sure?',
      text: "You'll need to install MetaMask to continue. Once you have it installed, go ahead.",
      icon: icon,
      imageUrl: 'img/metamask.svg',
      imageHeight: 65,
      imageWidth: 65,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Install Now',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        if (icon == '') {
          window.open('https://metamask.io/', '_blank');
        }
        else {
          window.location.reload();
        }
      }
    })
    return;
  }
  const accounts = await window.ethereum.request({
    method: "eth_requestAccounts",
  });

  Cookies.set('loginMetamask_garry', accounts[0]);
  setTimeout(() => {
    window.location.reload()
  }, 100);
}


// async checkIfWalletIsConnected() {
//   console.clear()
//   if (window.ethereum) {
//     const accounts = await window.ethereum.request({
//       method: "eth_accounts",
//     });
//     if (accounts.length > 0) {
//       return;
//     }
//     else {
//       Cookies.set('loginMetamask_garry', '');
//     }
//   }
// }

async connectMetasmask_mobile() {
  let web3
  const onboard = Onboard({
    dappId: '0880efe2-6c9c-4c85-a416-d5aea1f62201', // [String] The API key created by step one above
    networkId: 56,  // [Integer] The Ethereum network ID your Dapp uses.
    walletSelect: {
      wallets: [
        { walletName: "metamask", preferred: true },
        { walletName: "trust", preferred: true },
      ]
    },
    subscriptions: {
      wallet: wallet => {
        web3 = new Web3(wallet.provider)
      }
    }
  })
  await onboard.walletSelect();
}

openModal = () => this.setState({ isOpen: true });
closeModal = () => this.setState({ isOpen: false });

async connect_trust_browser() {
  let icon = '';
    Swal.fire({
      // title: 'Are you sure?',
      text: "Please connect from dapp browser.",
      icon: icon,
      imageUrl: 'img/trustwallet.png',
      imageHeight: 65,
      imageWidth: 65,
      // showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'OK',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.close()
      }
    })
    return;
}

  render() {
    return (
      <div>
   
        <div className="container-xxl position-relative bg-white d-flex p-0">
          <Leftsidebar />
          {/* Content Start */}
          <div className="content">
            <Header />

            <section className="signup-step-container">
              <div className="container">
                <div className="row d-flex justify-content-center">
                  <div className="col-md-8 col-sm-12 col-xs-12 col-12">
                  <div className="wizard" id="wizardmain1">
                  <div className="wizard-inner">
                      <div className="connecting-line" />
                      <ul className="nav nav-tabs" role="tablist">
                        <li role="presentation" className="active">
                          <a href="" data-toggle="tab" aria-controls="step1" role="tab" aria-expanded="true"><span className="round-tab">1 </span> <i>Step 1</i></a>
                        </li>
                        <li role="presentation" className="active">
                          <a href="javascript:void(0)" data-toggle="tab" aria-controls="step2" role="tab" aria-expanded="false"><span className="round-tab">2</span> <i>Step 2</i></a>
                        </li>
                        <li role="presentation" className="disabled">
                          <a href="javascript:void(0)" data-toggle="tab" aria-controls="step3" role="tab"><span className="round-tab">3</span> <i>Step 3</i></a>
                        </li>
                        {/* <li role="presentation" class="disabled">
                                    <a href="#step4" data-toggle="tab" aria-controls="step4" role="tab"><span class="round-tab">4</span> <i>Step 4</i></a>
                                </li> */}
                      </ul>
                    </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <div className="row" id="johndashidone">
              <form action="#" id="userwalletid">
                <div className="walletimgblock">
                  <img src="icons/wallet-svgrepo-wall.png" alt="wallet-svgrepo-wall" className="walletinput" />
                </div>
                <div className="selectbox">
                  <h3>Wallet Address</h3>
                  <p>Add your wallet address to recive your Garri Token</p>
                  <div className="input-group mb-3 selectrow" id="walladdold">
                    <input type="text" className="form-control text-center active" name='wallet_address' placeholder="Wallet address" onChange={this.onChange} autoComplete="off" value={this.state.wallet_address} />
                    <div className="input-group-text bankdiv" onClick={this.copyToClipboard.bind(this)}>
                      <img src="icons/copy-svgrepo-com 1.png" alt="copy-svgrepo-com 1" className="bankinput" />
                    </div>
                  </div>
                  
                  <div className="input-group mb-3 selectrow" id="walladdnew">
                    <input type="text" className="form-control text-center active" name='wallet_address' placeholder="" onChange={this.onChange} autoComplete="off" value={this.state.wallet_address} />
                    {/* <div className="input-group-text bankdiv" onClick={this.copyToClipboard.bind(this)}>
                    </div> */}
                  </div>
                </div>
                <div className="bankbtn" id='cryptobtn'>

                  {/* <button type="button" onClick={this.purchaseItem.bind(this)} className="btn btn-success expirydatesubmit">Submit</button> */}

                  {this.loginData_metamask ?
              <button type="button" onClick={this.purchaseItem.bind(this)} className="btn btn-success expirydatesubmit" >Submit</button>  
              :
              <>
              <BrowserView>
              <button type="button" onClick={this.openModal} className="btn btn-success expirydatesubmit" >Connect</button>
              </BrowserView>
              <MobileView>
                {this.state.connected_wallet != '' ? 
                  <button type="button" onClick={this.purchaseItem.bind(this)} className="btn btn-success expirydatesubmit" >Submit</button>  
                :
                <button type="button" onClick={this.connectMetasmask_mobile} className="btn btn-success expirydatesubmit">Connect</button>
              }
              
              </MobileView>
              </>
            }

            <Modal show={this.state.isOpen} onHide={this.closeModal}>
              <Modal.Header closeButton>
                <Modal.Title>Choose Wallet</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row pt-4 pb-4">
                  <div className="col-md-6 text-center">
                    <BrowserView>
                     
                        <a className='d-flex align-items-center justify-content-center' href="javascript:;" onClick={this.connect_metamask}><img src="img/metamask.svg" className="" alt="Metamask wallet" width="50px" />&nbsp;&nbsp;
            
                        <h5>Metamask</h5></a>
                     
                    </BrowserView>
                  </div>
                  <div class="col-md-6">
                  <BrowserView className='text-center'>
                
                        <a className='d-flex align-items-center justify-content-center' href="javascript:;" onClick={this.connect_trust_browser}><img src="img/trustwallet.png" className='' alt="Trust wallet" style={{height:'50px'}} />&nbsp;&nbsp;
     
                        <h5>Trust Wallet</h5>
                        </a>
                        </BrowserView>
                    
                  </div>
                </div>
              </Modal.Body>
            </Modal>
                
                </div>
              </form>
            </div>

          </div>
        </div>
        <Footer />
      </div>
    );

  }
}