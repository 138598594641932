import React, { Component } from 'react';
// import { config } from 'yargs';
import Header from '../directives/header'
import Leftsidebar from '../directives/leftsidebar'
import Footer from '../directives/footer'
import axios from 'axios'
import config from '../config/config'
import toast, { Toaster } from 'react-hot-toast';
import 'react-toastify/dist/ReactToastify.css';
import Cookies from 'js-cookie';
import moment from "moment";

import Websocket from 'react-websocket';

import CanvasJSReact from '../canvasjs.react';
//var CanvasJSReact = require('./canvasjs.react');
var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

const headers = {
    'Content-Type': 'application/json'
};
export default class PublicDashboard extends Component {


    constructor(props) {
        super(props)
        this.state = {
            currency_list: [],
            currency: 3,
            currency_amount: '',
            garri_amount: '',
            transactions: [],
            ngnusdtPrice: 0,
            busdtousd: 0,
            my_pair_list: [
                { 'pair': 'BTCUSDT', 'image': 'icons/bitcoin1.png', 'name': 'BITCOIN', 'symbol': 'BTC' },
                { 'pair': 'ETHUSDT', 'image': 'icons/ethereum 1.png', 'name': 'ETHRIUM', 'symbol': 'ETH' },
                { 'pair': 'BNBUSDT', 'image': 'icons/bnb-svgrepo-com 1.png', 'name': 'BNB', 'symbol': 'BNB' },
                { 'pair': 'USDT', 'image': 'icons/usdt-svgrepo-com 1.png', 'name': 'USDT', 'symbol': 'USDT' },
                { 'pair': 'XRPUSDT', 'image': 'icons/xrpicons.png', 'name': 'XRP', 'symbol': 'XRP' },
                { 'pair': 'DASHUSDT', 'image': 'icons/dash-svgrepo-com 1.png', 'name': 'DASHCOIN', 'symbol': 'DASH' },
                { 'pair': 'DOGEUSDT', 'image': 'icons/dogecoin 1.png', 'name': 'DOGECOIN', 'symbol': 'DOGE' }
            ],
            graphDataPoints: []
        }
        this.onChange = this.onChange.bind(this);
        this.handleChangeNumber = this.handleChangeNumber.bind(this);
        this.submitForm = this.submitForm.bind(this);
        this.loginData = (!Cookies.get('loginSuccesGarryToken')) ? [] : JSON.parse(Cookies.get('loginSuccesGarryToken'));


    }

    componentDidMount() {
        // console.log(moment().format("DD-MM-YYYY"))
        // console.log(moment().format("hh:mm")+' GMT')
        this.getCurrency();
        this.getMyRecentTransactions();
        this.getBusdPriceAPI();
        this.getNgnUsdtPriceAPI();
        this.getGraphData();
        document.getElementById("dashboard").classList.add("active");
    }



    async onChange(e) {
        await this.setState({
            [e.target.name]: e.target.value
        })
        this.get_garri_amount();
    }

    async handleChangeNumber(event) {
        const re = /^[0-9/.]+$/;
        if (event.target.value === '' || re.test(event.target.value)) {
            await this.setState({
                [event.target.name]: event.target.value
            })
            this.get_garri_amount();
        }
    }
    async getCurrency() {
        axios.get(`${config.apiUrl}getCurrency`)
            .then(response => {
                if (response.data.success === true) {
                    this.setState({
                        currency_list: response.data.response
                    })
                }
            })
            .catch(err => {
                // toast.error(err.response.data?.msg);
            })
    }

    async get_garri_amount() {
        let obj = {
            currency: this.state.currency,
            currency_amount: (this.state.currency_amount ? this.state.currency_amount : 0)
        }
        axios.post(`${config.apiUrl}getCurrencyValue`, obj, { headers })
            .then(response => {
                if (response.data.success === true) {
                    this.setState({
                        garri_amount: response.data.garri_amount,
                        fees: response.data.fees
                    })
                }
                // else if (response.data.success === false) {
                //     toast.error(response.data.msg);
                // }
            })
            .catch(err => {
                // toast.error(err.response.data?.msg);
            })
    }

    async submitForm(e) {
        e.preventDefault();
        if (this.state.currency_amount == '') {
            toast.error('Currency amount required.');
            return;
        }
        else if (this.state.currency_amount == 0) {
            toast.error('Currency amount must be greater then 0');
            return;
        }

        let usd_amount = 0;
        if (this.state.currency == 1 || this.state.currency == 2) {
            usd_amount = this.state.currency_amount * this.state.busdtousd;
        }
        else if (this.state.currency == 3) {
            usd_amount = this.state.currency_amount * this.state.ngnusdtPrice;
        }
        let obj = {
            user_id: this.loginData.data.id,
            currency: this.state.currency,
            currency_amount: this.state.currency_amount,
            garry_token_amount: this.state.garri_amount,
            fees: this.state.fees,
            usd_amount: usd_amount,
        }
        axios.post(`${config.apiUrl}insertTemporeryData`, obj, { headers })
            .then(response => {
                if (response.data.success === true) {
                    setTimeout(() => {
                        window.location.href = config.baseUrl + 'paymentOptions'
                    }, 300);
                }
                else if (response.data.success === false) {
                    toast.error(response.data.msg);
                }
            })
            .catch(err => {
                toast.error(err.response.data?.msg);
            })
    }

    async getMyRecentTransactions() {
        let obj = {
            user_id: this.loginData.data.id
        }
        axios.post(`${config.apiUrl}getMyRecentTransactions`, obj, { headers })
            .then(response => {
                if (response.data.success === true) {
                    this.setState({
                        transactions: response.data.response
                    })
                }
            })
            .catch(err => {
                // toast.error(err.response.data?.msg);
            })
    }


    async livePairDataFromBinance(socketData) {

        socketData = JSON.parse(socketData);

        if (this.state.my_pair_list.length > 0) {
            let coinList1 = this.state.my_pair_list;
            // console.log(coinList1)
            var i = 0;
            for (i = 0; i < coinList1.length; i++) {
                var item = coinList1[i];

                var si = socketData.findIndex(el => el['s'] === item.pair);
                // console.log(si)
                if (si > -1) {
                    coinList1[i].livePrice = parseFloat(socketData[si].c).toFixed(4);
                    coinList1[i].changePercantage = parseFloat(socketData[si].P).toFixed(4);
                }
                if (item.pair == "USDT") {
                    coinList1[i].livePrice = 76.31;
                    coinList1[i].changePercantage = 0.00;
                }
            }


            this.setState({
                my_pair_list: coinList1
            })

        }
    }

    async getBusdPriceAPI() {
        await axios({
            method: 'get',
            url: `https://api.binance.com/api/v3/ticker/price?symbol=BUSDUSDT`,
        }).then(response => {
            this.setState({
                busdtousd: response.data.price,
                // usdtobnb: 1 / response.data.price
            })
            // console.log(response.data);

        })
    }

    async getNgnUsdtPriceAPI() {
        await axios({
            method: 'get',
            url: `https://api.coingecko.com/api/v3/simple/price?ids=tether&vs_currencies=ngn`,
        }).then(response => {
            this.setState({
                ngnusdtPrice: (1 / response.data.tether.ngn).toFixed(6),
            })

            console.log();
        })
    }

    async getGraphData() {
        let obj = {
            user_id: this.loginData.data.id
        }
        axios.post(`${config.apiUrl}getGraphData`, obj, { headers })
            .then(response => {
                if (response.data.success === true) {
                    let data = response.data.response;
                    let gdataPoints = [];
                    for (var i = 0; i < data.length; i++) {
                        gdataPoints.push({ x: new Date(data[i].month), y: data[i].usd_amount });
                    }
                    this.setState({
                        graphDataPoints: gdataPoints
                    })
                }
            })
            .catch(err => {
                // toast.error(err.response.data?.msg);
            })
    }


    render() {

        const dataPoints = this.state.graphDataPoints;
        console.log(dataPoints.length)
        // let a = [
        //     { x: new Date("2017-01"), y:1000 },
        //     { x: new Date("2017-02"), y:800 },
        //     { x: new Date("2017-03"), y:900 },
        //     { x: new Date("2017-04"), y:755 },
        //     { x: new Date("2017-05"), y:200 },
        //     { x: new Date("2017-06"), y:699 },
        //     { x: new Date("2017-07"), y:152 },
        //     { x: new Date("2017-08"), y:500 },
        //     { x: new Date("2017-09"), y:855 },
        //     { x: new Date("2017-10"), y:565 },
        //     { x: new Date("2017-11"), y:400 },
        //     { x: new Date("2017-12"), y:350 }
        // ]
        // console.log(a);

        const options = {
            animationEnabled: true,
            title: {
                // text: "Monthly history"
            },
            axisY: {
                title: "Amount (USD)",
                prefix: "$"
            },
            data: [{
                type: "splineArea",
                xValueFormatString: "MMMM-YYYY",
                yValueFormatString: "$#,##0.##",
                showInLegend: true,
                legendText: "Months",
                dataPoints: dataPoints
            }]
        }
        return (
            <div>

                <div className="container-xxl position-relative bg-white d-flex p-0">
                    <Leftsidebar />
                    {/* Content Start */}
                    <div className="content">
                        <Header />

                        <Websocket url='wss://stream.binance.com:9443/ws/!ticker@arr'
                            onMessage={this.livePairDataFromBinance.bind(this)} />

                        <div className="main-carousel">
                                <div className="container m-0 p-0">
                                <div className="row">
                                    <div className="col-12 m-0 p-0">
                                    <div className="inner-carousel m-0 p-0">
                                    {/* <h4>
                                        Crypto News
                                    </h4> */}
                                        <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel">
                                        <div className="carousel-indicators">
                                            <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to={0} className="maindost" aria-label="Slide 1" />
                                            <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to={1} className="maindost active" aria-label="Slide 2" aria-current="true" />
                                            <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to={2} className="maindost" aria-label="Slide 3" />
                                        </div>
                                        <div className="carousel-inner">
                                            <div className="carousel-item">
                                            <img src="img/mainbitcion.jpg" className="d-block w-100" alt="mainbitcion" />
                                            <div className="carousel-caption d-none d-md-block">
                                                <h5>First slide label</h5>
                                                <p>Some representative placeholder content for the first slide.</p>
                                            </div>
                                            </div>
                                            <div className="carousel-item active">
                                            <img src="img/mainbitcion.jpg" className="d-block w-100" alt="mainbitcion" />
                                            <div className="carousel-caption d-none d-md-block">
                                                <h5>Second slide label</h5>
                                                <p>Some representative placeholder content for the second slide.</p>
                                            </div>
                                            </div>
                                            <div className="carousel-item">
                                            <img src="img/mainbitcion.jpg" className="d-block w-100" alt="mainbitcion" />
                                            <div className="carousel-caption d-none d-md-block">
                                                <h5>Third slide label</h5>
                                                <p>Some representative placeholder content for the third slide.</p>
                                            </div>
                                            </div>
                                        </div>
                                        {/* <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                                                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                                            <span class="visually-hidden">Previous</span>
                                                            </button> */}
                                        {/* <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                                                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                                            <span class="visually-hidden">Next</span>
                                                            </button> */}
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                </div>
                        </div>

                        <div className="row" id="johndashid">
                            <h5 className="johndash">Dashboard</h5>
                            <div className="col-lg-4 col-md-4 col-12">
                                <div className="cardimgs1">
                                    <img src="img/cards.png" alt="cards" />
                                </div>
                            </div>
                            <div className="col-lg-8 col-md-8 col-12 m-0 p-0">
                                <div className="conversion">
                                    <div className="converdiv">
                                        <span><img src="icons/error.png" alt="error" className="error" /></span>
                                        <h4 className='converdexk'>Conversion Desk</h4>
                                        <h4 className='buytoken'>Buy Token</h4>
                                    </div>
                                    {/* <h5>Amount</h5> */}
                                    <div className="innerconver">
                                        <form action="#" className="mainform">
                                            <div className="innbox">
                                            </div>
                                            <div id='buytoknid'>
                                                <div className="form-group" id='buytokleft'>
                                                    {/* <label>Currency</label> */}
                                                    {/* <select className='form-control' id='garriselect' style={{ fontSize: '10px', borderRadius: '8px', padding: '5px 20px', margin: '0px auto 15px 0px', backgroundColor: '#fff', appearance: 'auto' }} name="currency" value={this.state.currency} onChange={this.onChange} >
                                                        {this.state.currency_list.map((item, i) =>
                                                            <option value={item.id}>{item.asset_id}</option>
                                                        )}
                                                    </select> */}
                                                    <i class="fa fa-angle-up" aria-hidden="true"></i>

                                                    <select class="custom-select" id='selectdrop' name="currency" value={this.state.currency} onChange={this.onChange}>
                                                        {this.state.currency_list.map((item, i) =>
                                                            <option value={item.id}>{item.asset_id}</option>
                                                        )}
                                                    </select>

                                                </div>
                                                <div className="form-group" id='buytokright'>
                                                    {/* <label>Amount</label> */}
                                                    <input type="text" className="form-control" id='currency_amount' name='currency_amount' value={this.state.currency_amount} onChange={this.handleChangeNumber} autoComplete='off' />
                                                    <p>Today Rate {moment().format("DD/MM/YYYY")}</p>
                                                    <p>{moment().format("hh:mm")} GMT</p>
                                                </div>
                                            </div>
                                            
                                            <div className="convertdiv">
                                                <img src="icons/convert_btn.png" alt="convert_btn" className="covertimg" id='convert_btn1'/>
                                                <img src="icons/convert_btn2.png" alt="convert_btn" className="covertimg" id='convert_btn2'/>
                                            </div>

                                            <div id='buytoknid'>
                                                <div className="form-group" id='buytokleft'>
                                                    {/* <label>Currency</label> */}
                                                    {/* <select className='form-control' id='garriselect' style={{ fontSize: '10px', borderRadius: '8px', padding: '5px 20px', margin: '0px auto 15px 0px', backgroundColor: '#fff', appearance: 'auto' }} name="currency" value={this.state.currency} onChange={this.onChange} >
                                                        {this.state.currency_list.map((item, i) =>
                                                            <option value={item.id}>{item.asset_id}</option>
                                                        )}
                                                    </select> */}
                                                    <i class="fa fa-angle-up" aria-hidden="true"></i>

                                                    <select class="custom-select" id='selectdrop'>
                                                    {/* <i class="fa-solid fa-angle-up"></i> */}
                                                        <option>GARRI</option>
                                                    </select>

                                                </div>
                                                <div className="form-group" id='buytokright'>
                                                    {/* <label>Amount</label> */}
                                                    <input type="text" className="form-control" id='currency_amount' name='garri_amount' value={this.state.garri_amount} onChange={this.handleChangeNumber} autoComplete='off' />
                                                    <p>Today Rate {moment().format("DD/MM/YYYY")}</p>
                                                    <p>{moment().format("hh:mm")} GMT</p>
                                                </div>
                                            </div>

                                        </form>
                                        <div className="buygaribtn">
                                            {/* <button class="btn btn-success buygaributton">Buy Garri</button> */}
                                            <button type='submit' onClick={this.submitForm} className="buygaributton" id='burgaribtn'>Buy Garri</button>
                                            <button type='submit' onClick={this.submitForm} className="buygaributton" id='Purchasebtn'>Purchase</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="col-lg-4 col-md-4 col-12">
                                <div className="howmuch">
                                    <a href="#" class="addcards2" data-bs-toggle="modal" data-bs-target="#myModal">Connet Wallet</a>
                                </div>
                            </div> */}
                        </div>
                        <div className="row" id="januaryid">
                            
                            <div className="col-lg-8 col-md-8 col-12 m-0 p-0">
                                


                            
                                    
                                        <div className="row m-0 p-0" id="bitconsid">
                                            <div className="col-lg-12 col-md-12 col-sm-12 col-12" id="coinboxid">
                                                <h4 className='marketpri'>
                                                    Market Price
                                                </h4>
                                                <h4 className='coinretet'>
                                                    Coins / Token rates
                                                </h4>
                                                <table className="bitcointableid">
                                                    <tbody>
                                                        {this.state.my_pair_list.map((coin_item, i) =>
                                                            <tr>
                                                                <td><img src={coin_item.image} alt="bitcoin1" className="bitcoinimg" /></td>
                                                                <td className="textcolor">{coin_item.name}</td>
                                                                <td>{coin_item.symbol}</td>
                                                                <td className="textcolor">${coin_item.livePrice}</td>
                                                                <td className="textcolor2">{coin_item.changePercantage}%</td>
                                                            </tr>
                                                        )}
                                                        {/* <tr>
                                                            <td><img src="icons/ethereum 1.png" alt="ethereum 1" className="bitcoinimg" /></td>
                                                            <td className="textcolor">ETHRIUM</td>
                                                            <td>ETH</td>
                                                            <td className="textcolor">$65,750</td>
                                                            <td className="textcolor2">+ 5.4%</td>
                                                        </tr>
                                                        <tr>
                                                            <td><img src="icons/bnb-svgrepo-com 1.png" alt="bnb-svgrepo-com 1" className="bitcoinimg" /></td>
                                                            <td className="textcolor">BNB</td>
                                                            <td>BNB</td>
                                                            <td className="textcolor">$65,750</td>
                                                            <td className="textcolor2">+ 11.1%</td>
                                                        </tr>
                                                        <tr className="usdtcolor">
                                                            <td><img src="icons/usdt-svgrepo-com 1.png" alt="usdt-svgrepo-com 1" className="bitcoinimg" /></td>
                                                            <td className="textcolor">USDT</td>
                                                            <td>USDT</td>
                                                            <td className="textcolor">$65,750</td>
                                                            <td className="textcolor2">+ 11.1%</td>
                                                        </tr>
                                                        <tr className="xrpcolor">
                                                            <td><img src="icons/xrpicons.png" alt="xrpicons" className="bitcoinimg" /></td>
                                                            <td className="textcolor">XRP</td>
                                                            <td>XRP</td>
                                                            <td className="textcolor">$65,750</td>
                                                            <td className="textcolor2">- 1.1%</td>
                                                        </tr>
                                                        <tr>
                                                            <td><img src="icons/dash-svgrepo-com 1.png" alt="dash-svgrepo-com 1" className="bitcoinimg" /></td>
                                                            <td className="textcolor">DASHCOIN</td>
                                                            <td>BNB</td>
                                                            <td className="textcolor">$65,750</td>
                                                            <td className="textcolor2">+ 11.1%</td>
                                                        </tr>
                                                        <tr className="dogecoin">
                                                            <td><img src="icons/dogecoin 1.png" alt="dogecoin 1" className="bitcoinimg" /></td>
                                                            <td className="textcolor">DOGECOIN</td>
                                                            <td>BNB</td>
                                                            <td className="textcolor">$65,750</td>
                                                            <td className="textcolor2">+11.1%</td>
                                                        </tr> */}
                                                    </tbody>
                                                </table>
                                            </div>
                                            {/* <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                <div className="d-flex align-items-center justify-content-between mb-4" id="transiid">
                                                    <h6 className="mb-0 clalehaed">Transaction</h6>
                                                    <a href={`${config.baseUrl}transactions`} className="showanker">See All</a>
                                                </div><table className="tablecolor" id="transid">
                                                    <tbody>
                                                        <tr>
                                                            <td className="imgborder1"><img src="icons/logo-web-white-lil 2.png" alt="logo-web-white-lil 2" className="bitcoinimg one" /></td>
                                                            <td className="textcolor">Grey level <p className="date">12/4/2022</p></td>
                                                            <td>+2.11 %</td>
                                                            <td className="textcolor"><img src="icons/graphicon1.png" alt="graphicon1" className="vectorimg" /></td>
                                                            <td>NGN 30,000</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="imgborder"><img src="icons/logo-web-white-lil 2.png" alt="logo-web-white-lil 2" className="bitcoinimg" /></td>
                                                            <td className="textcolor">Blu level <p className="date">12/4/2022</p></td>
                                                            <td>+2.11 %</td>
                                                            <td className="textcolor"><img src="icons/graphicon1.png" alt="graphicon1" className="vectorimg" /></td>
                                                            <td>NGN 30,000</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="imgborder"><img src="icons/logo-web-white-lil 2.png" alt="logo-web-white-lil 2" className="bitcoinimg" /></td>
                                                            <td className="textcolor">Brown Pro <p className="date">12/4/2022</p></td>
                                                            <td>+2.11 %</td>
                                                            <td className="textcolor"><img src="icons/graphicon2.png" alt="graphicon2" className="vectorimg" /></td>
                                                            <td>NGN 30,000</td>
                                                        </tr>
                                                        <tr className="usdtcolor">
                                                            <td className="imgborder"><img src="icons/logo-web-white-lil 2.png" alt="logo-web-white-lil 2" className="bitcoinimg" /></td>
                                                            <td className="textcolor">Blu level ++ <p className="date">12/4/2022</p></td>
                                                            <td>+2.11 %</td>
                                                            <td className="textcolor"><img src="icons/graphicon1.png" alt="graphicon1" className="vectorimg" /></td>
                                                            <td>NGN 30,000</td>
                                                        </tr>
                                                        <tr className="xrpcolor">
                                                            <td className="imgborder"><img src="icons/logo-web-white-lil 2.png" alt="logo-web-white-lil 2" className="bitcoinimg" /></td>
                                                            <td className="textcolor">Platnum level <p className="date">12/4/2022</p></td>
                                                            <td>+2.11 %</td>
                                                            <td className="textcolor"><img src="icons/graphicon3.png" alt="graphicon3" className="vectorimg" /></td>
                                                            <td>NGN 30,000</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div> */}
                                        </div>
                                   
                                
                            </div>
                        </div>
                    </div>
                    {/* Content End */}
                    {/* Connet Wallet Popup */}
                    {/* The Modal */}

                    

                    {/* JavaScript Libraries */}
                    {/* Template Javascript */}
                </div>
                <Footer />
            </div>
        );

    }
}
