import React, { Component } from 'react';
import Header from '../directives/header'
import Footer from '../directives/footer'
import Leftsidebar from '../directives/leftsidebar'
// import Footer from '../directives/footer'
import axios from 'axios'
import config from '../config/config'
import toast, { Toaster } from 'react-hot-toast';
import Cookies from 'js-cookie';
import 'react-toastify/dist/ReactToastify.css';
// import UserBanks from './userBanks';
const headers = {
    'Content-Type': 'application/json'
};

export default class UserGuide_3rd extends Component {

    constructor(props) {
        super(props)
        this.state = {
            heading:'',
            title:'',
            description:''
        }
    }

    componentDidMount() {
        this.getUserGuides();
        document.getElementById("userGuide").classList.add("active");
    }
    async getUserGuides() {
        axios.get(`${config.apiUrl}getUserGuides`)
            .then(async response => {
                if (response.data.success === true) {
                    let user_guide_list = response.data.response[2];
                    await this.setState({
                        heading:user_guide_list.heading,
                        title:user_guide_list.title,
                        description:user_guide_list.description,
                    })
                }
            })
    }

    render() {
        return (
            <div>

                <div className="">
                    <Leftsidebar />
                    {/* Content Start */}
                    <div className="content">
                        <Header />

                        <div className='row'>
                                <div className='payinner'>
                                    <div className='col-lg-12 col-md-12 col-sm-12'>
                                    <div className="cardssinner1" id='paycardsinnnerid'>
                                        <div className="card" id="payboxid3">
                                            <div className='guide-inner' id="managepay">
                                                <div className='guidtext'>
                                                    <h4>{this.state.heading}</h4>
                                                </div>
                                                <div className='guidicon'>
                                                <img src="icons/p_manage.png" alt="p_manage" className='guidicinimg'/>

                                                </div>
                                            </div>
                                        <div className="card-body" id='paybody'>
                                            <div className='wall_inner'>
                                                <h5 className="card-title">
                                                {this.state.title}
                                                </h5>
                                                {/* <a href="#" class="btn btn-primary" id="paymentbtnthree">Settings</a> */}
                                            </div>
                                            {/* <p className="carda-text">
                                            <span>
                                                <img src="icons/time.png" alt="time" className='timeico'/>
                                            </span>2 days ago</p> */}
                                            <div
                                                dangerouslySetInnerHTML={{ __html: this.state.description }}
                                                />
                                        </div>
                                        </div>
                                    </div>
                                        
                                    </div>

                                 
                                </div>
                            </div>

                        
                    </div>
                </div>
                <Footer />
            </div>
        );

    }
}
