import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import config from '../config/config'
import Cookies from 'js-cookie'
import axios from 'axios';
import { BrowserView, MobileView } from 'react-device-detect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro'


export default class Leftsidebar extends Component {

    constructor(props) {
        super(props)
        this.state = {
            profile_pic: 'img/avatar.png'
        }
        this.loginData = JSON.parse(!Cookies.get('loginSuccesGarryToken') ? null : Cookies.get('loginSuccesGarryToken'));
    }

    componentDidMount() {
        this.getUserProfileAPI();
    }

    logout() {
        Cookies.remove('loginSuccesGarryToken')
        Cookies.remove('loginMetamask_garry')
        window.location.href = config.baseUrl
    }

    async getUserProfileAPI() {

        axios.post(`${config.apiUrl}getUserProfile`, { 'email': this.loginData?.data.email },)
            .then(response => {
                if (response.data.success === true) {
                    this.setState({
                        profile_pic: (response.data.response.profile_pic ? config.apiUrl + response.data.response.profile_pic : 'img/avatar.png')
                    })
                }
                else if (response.data.success === false) {
                }
            })
            .catch(err => {
            })
    }

    render() {
        return (
            <>

                {/* <div id="spinner" className="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
                <div className="spinner-border text-primary" style={{ width: '3rem', height: '3rem' }} role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div> */}
                {/* Spinner End */}
                {/* Sidebar Start */}
                <div className="sidebar">
                    <nav className="navbar navbar-light">
                        <a href={`${config.baseUrl}dashboard`} className="navbar-brand mx-4 mb-3">
                            <img src="img/logo.png" alt="logo" id="sidelogo"/>
                        </a>
                        <div className="d-flex align-items-center ms-4 mb-4">
                            <div className="position-relative">
                                <img className="rounded-circle" src={this.state.profile_pic} alt="" style={{ width: '40px', height: '40px' }} />
                                <div className="bg-success rounded-circle border border-2 border-white position-absolute end-0 bottom-0 p-1" />
                            </div>
                            <div className="ms-3">
                                <h6 className="mb-0">{this.loginData.data.username}</h6>
                                {/* <span>Admin</span> */}
                            </div>
                        </div>
                        <div className="navbar-nav w-100">
                            <a href={`${config.baseUrl}dashboard`} className="nav-item nav-link" id="dashboard"><span><img src="icons/dashicon.png" alt="dashicon" className="naviconimg" /></span>Dashboard</a>
                            {/*  <div class="nav-item dropdown"> */}
                            {/* <a href={`${config.baseUrl}userGuide`} id="user_guide" className="nav-item nav-link" data-bs-toggle="dropdown"><span><img src="icons/navicon2.png" alt="navicon2" className="naviconimg" /></span>User guide</a> */}
                            <a href={`${config.baseUrl}userGuide`} className="nav-item nav-link" id='userGuide'><span><img src="icons/open-book2.png" alt="open-book2" className="naviconimg" /></span>User guide</a>
                            <a href={`${config.baseUrl}userBanks`} className="nav-item nav-link" id='userBanks'><span><img src="icons/navicon3.png" alt="navicon3" className="naviconimg" /></span>Account</a>

                            {/* <a href={`${config.baseUrl}phone`} className="nav-item nav-link" id='userBanks'><span><img src="icons/navicon5.png" alt="navicon5" className="naviconimg" /></span>Phone</a> */}


                            <a href={`${config.baseUrl}blogs`} className="nav-item nav-link" id='blogs'><span><img src="icons/navicon2.png" alt="navicon2" className="naviconimg" /></span>Blog</a>

                            <a href={`${config.baseUrl}faqs`} className="nav-item nav-link" id='faqs'><span><img src="icons/navicon2.png" alt="navicon2" className="naviconimg" /></span>FAQ's</a>

                            <a href={`${config.baseUrl}transactions`} id="transactions" className="nav-item nav-link"><span><img src="icons/navicon3.png" alt="navicon3" className="naviconimg" /></span>Transactions</a>

                            {/* <a href="user_profile.html" class="nav-item nav-link dropdown-toggle"><span><img src="icons/navicon4.png" alt="navicon4" class="naviconimg"></span>Profile Setting</a> */}
                            <div className="nav-item dropdown" id='setting_nav'>
                                <a href="#" id='set_nav' className="nav-item nav-link dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><span><img src="icons/settings2.png" alt="settings2" className="naviconimg" /></span>Setting</a>
                                <div id='set_option_div' className="dropdown-menu bg-transparent border-0">
                                <BrowserView>
                                <a href={`${config.baseUrl}updateProfile`} className="dropdown-item"><i className='fa fa-user'></i> Profile</a>
                                </BrowserView>
                                <MobileView>
                                    <a href={`${config.baseUrl}profile`} className="dropdown-item"><i className='fa fa-user'></i> Profile</a>
                                    </MobileView>
                                    {/* <a href={`${config.baseUrl}phone`} className="dropdown-item"><i className='fa fa-phone'></i> Phone</a> */}
                                    <a href={`${config.baseUrl}changePassword`} className="dropdown-item"> <i className='fa fa-key'></i>  Change Password</a>
                                </div>
                            </div>
                            <a href="javascript:;" onClick={this.logout.bind(this)} className="nav-item nav-link"><span><img src="icons/logoutico.png" alt="logoutico" className="naviconimg" /></span>Logout</a>
                            {/* <a href="user_phone.html" class="nav-item nav-link"><span><img src="icons/navicon5.png" alt="navicon5" class="naviconimg"></span>Phone</a> */}
                            {/* <a href="user_Password.html" class="nav-item nav-link"><span><img src="icons/keypaww.png" alt="keypaww" class="naviconimg"></span>Password</a> */}
                            {/* <a href="admin_managetransactions.html" class="nav-item nav-link"><span><img src="icons/ortherpaymenr.png" alt="ortherpaymenr" class="naviconimg"></span>Transaction</a> */}
                        </div>
                    </nav>
                </div>
                {/* Sidebar End */}
            </>



        );
    }
}