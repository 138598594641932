import React, { Component } from 'react';
import Header from '../directives/header'
import Footer from '../directives/footer'
import Leftsidebar from '../directives/leftsidebar'
// import Footer from '../directives/footer'
// import axios from 'axios'
// import config from '../config/config'
// import Cookies from 'js-cookie';

export default class Phone extends Component {


    componentDidMount() {
        document.getElementById("set_nav").classList.add("show");
        document.getElementById("set_option_div").classList.add("show");
        document.getElementById("set_nav").setAttribute("aria-expanded", true);
    }

    render() {
        return (
            <div>

                <div className="container-xxl position-relative bg-white d-flex p-0">
                    <Leftsidebar />
                    {/* Content Start */}
                    <div className="content">
                        <Header />


                        <div className="row" id="johndashidone">
                            {/* <h5 class="johndash">John Doe  /  Add card</h5> */}
                            <form action="#" id="userphoneid">
                                <div className="walletimgblock">
                                    <img src="icons/telephone-userphone.png" alt="telephone-userphone  " className="" />
                                </div>
                                <div className="userphonebox">
                                    <div className="selectext">
                                        <h3>Add Phone Number</h3>
                                        <p>Add your phone number for recovery and verifications</p>
                                    </div>
                                    <div className="userphoneinput">
                                        {/* <div class="input-group mb-3 selectphone1">
                                <input type="text" class="form-control" placeholder="">
                            </div> */}
                                        <div className="input-group mb-3 selectphone1">
                                            {/* <button className="btn btn-outline-secondary dropdown-toggle" id="selecttoggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" />
                                            <ul className="dropdown-menu">
                                                <li><a className="dropdown-item" href="#">Action</a></li>
                                                <li><a className="dropdown-item" href="#">Another action</a></li>
                                                <li><a className="dropdown-item" href="#">Something else here</a></li>
                                                <li><hr className="dropdown-divider" /></li>
                                                <li><a className="dropdown-item" href="#">Separated link</a></li>
                                            </ul> */}
                                            {/* <input type="text" className="form-control text-center active" placeholder="+234" /> */}
                                            <input type="text" className="form-control" placeholder="+234" />

                                            {/* <div class="input-group-text bankdiv">
                                    <img src="icons/bank.png" alt="bank" class="bankinput">
                                  </div> */}
                                        </div>
                                        <div className="input-group mb-3 selectphone2">
                                            <input type="text" className="form-control" placeholder="XX - XXX - XXX - XXX" />
                                        </div>
                                    </div>
                                </div>
                                <div className="bankbtn">
                                    <button className="btn btn-success expirydatesubmit">Submit</button>
                                </div>
                            </form>
                        </div>


                        <div class="modal fade" id="myModal">
                            <div class="modal-dialog">
                                <div class="modal-content">

                                    <div class="modal-header">
                                        <h4 class="modal-title text-center">OxEOF1...5154</h4>
                                        <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                                    </div>

                                    <div class="modal-body">
                                        <div class="row">
                                            <div class="col-sm-6 col-sm-12 col-xs-12 col-12">
                                                <div class="card" id="walletpopupid">
                                                    <div class="card-body">
                                                        <h5 class="card-title">$31.12 USD</h5>
                                                        <p class="card-text">Total Balance</p>
                                                        {/* <!-- <a href="#" class="btn btn-primary">Go somewhere</a> --> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="modal-footer">
                                            <button type="button" class="btn btn-danger addcards2" data-bs-dismiss="modal">Close</button>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            </div>

                        </div>
                    </div>
                    <Footer />

                </div>
                );

    }
}