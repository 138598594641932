import React, { Component } from 'react';
import Header from '../directives/header'
import Leftsidebar from '../directives/leftsidebar'
// import Footer from '../directives/footer'
import axios from 'axios'
import config from '../config/config'
import toast, { Toaster } from 'react-hot-toast';
import Cookies from 'js-cookie';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import moment from "moment";
const headers = {
  'Content-Type': 'application/json'
};
export default class PayWithBank extends Component {

  constructor(props) {
    super(props)
    this.state = {
      admin_bank_list: [],
      user_bank_list: [],
      add_bank_section: false,
      select_bank_section: true,
    }
    this.onChange = this.onChange.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.submitForm2 = this.submitForm2.bind(this);
    this.handleChangeImage = this.handleChangeImage.bind(this);
    this.loginData = (!Cookies.get('loginSuccesGarryToken')) ? [] : JSON.parse(Cookies.get('loginSuccesGarryToken'));
  }

  componentDidMount() {
    this.get_current_transaction();
    this.get_admin_banks();
    this.get_user_banks();
  }

  handleChangeImage = (e) => {
		let image_as_base64 = URL.createObjectURL(e.target.files[0])
		let image_as_files = e.target.files[0];

		if (!image_as_files) {
			this.setState({ invalidImage: 'Please select image.' });
			return false;
		}

		// if (!image_as_files.name.match(/\.(jpg|jpeg|png|gif)$/)) {
		// 	this.setState({ invalidImage: 'Please select valid image.' });
		// 	return false;
		// }


		this.setState({
			image_preview: image_as_base64,
			image_file: image_as_files,
		})
	}

  async get_current_transaction() {
    let obj = {
      user_id: this.loginData.data.id,
    }
    axios.post(`${config.apiUrl}getTemporaryData`, obj, { headers })
      .then(response => {
        if (response.data.success === true) {
          // console.log(response.data);
          this.setState({
            currency: response.data.response.currency,
            currency_amount: response.data.response.currency_amount,
            garri_token_amount: response.data.response.garry_token_amount,
            user_name: 'Test',//response.data.user_name,
            date: moment(response.data.response.date).format("DD/MM/YYYY"),
            fees: response.data.response.fees,
            payble_amount: parseFloat(response.data.response.currency_amount) + parseFloat(response.data.response.fees),
          })
        }
        else if (response.data.success === false) {
          // toast.error(response.data.msg);
          window.location.href = config.baseUrl;
        }
      })
      .catch(err => {
        // toast.error(err.response.data?.msg);
        window.location.href = config.baseUrl;
      })
  }

  async get_user_banks() {
    let obj = {
      user_id: this.loginData.data.id,
    }
    axios.post(`${config.apiUrl}getUserBankDetails`, obj, { headers })
      .then(response => {
        if (response.data.success === true) {
          this.setState({
            user_bank_list: response.data.response,
          })
        }
        else if (response.data.success === false) {
          this.setState({
            add_bank_section: true,
            select_bank_section: false,
            user_bank_list: [],
          })
        }
      })
      .catch(err => {
        this.setState({
          add_bank_section: true,
          select_bank_section: false,
        })
      })
  }

  async get_admin_banks() {
    let obj = {
      user_id: 1,
    }
    axios.post(`${config.apiUrl}getAdminBankDetails`, obj, { headers })
      .then(response => {
        if (response.data.success === true) {
          this.setState({
            admin_bank_list: response.data.response,
          })

          console.log(this.state.admin_bank_list)
        }
        else if (response.data.success === false) {
          this.setState({
            admin_bank_list: [],
          })
        }
      })
      .catch(err => {
      })
  }

  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  async submitForm(e) {
    e.preventDefault();

    let obj = {
      user_id: this.loginData.data.id,
      bank_name: this.state.bank_name,
      account_number: this.state.account_number,
      account_holder_name: this.state.account_holder_name,
    }
    axios.post(`${config.apiUrl}insertBankDetails`, obj, { headers })
      .then(response => {
        if (response.data.success === true) {
          toast.success('Bank detail added successfully.');
          this.setState({
            add_bank_section: false,
            select_bank_section: true,
          })
          this.get_user_banks();
        }
        else if (response.data.success === false) {
          toast.error(response.data.msg);
        }
      })
      .catch(err => {
        toast.error(err.response.data?.msg);
      })
  }


  async add_new_bank() {
    await this.setState({
      add_bank_section: true,
      select_bank_section: false,
    })

  }

  async cancel_add_bank() {
    await this.setState({
      add_bank_section: false,
      select_bank_section: true,
    })
  }

  async submitForm2(e) {
    e.preventDefault();

    const formData = new FormData();
		formData.append('user_id', this.loginData.data.id);
		formData.append('currency', this.state.currency);
		formData.append('currency_amount', this.state.currency_amount);
		formData.append('garri_token_amount', this.state.garri_token_amount);
		formData.append('fees', this.state.fees);
		formData.append('payment_method', 'BANK');
		formData.append('user_bank_id', this.state.user_bank_id);
		formData.append('admin_bank_id', this.state.admin_bank_id);
		formData.append('bank_receipt', this.state.image_file);

    await axios({
      method: 'post',
      url: `${config.apiUrl}submit_bank_transfer`,
      data: formData
    }).then(result => {
      // console.log(result);
      if (result.data.success == true) {
        toast.success(result.data.msg, {});
        // setTimeout(() => {
        //   window.location.href = url;
        // }, 1000)
      }
      else {
        toast.error(result.data.msg, {});
      }
    })
      .catch(err => {
        toast.error(err.response.data.msg, {});
      });
  
  }

  render() {
    return (
      <div>

        <div className="container-xxl position-relative bg-white d-flex p-0">
          <Leftsidebar />
          {/* Content Start */}
          <div className="content" id='confirmitionid'>
            <Header />

            <section className="signup-step-container mt-5">
              <div className="container">
                <div className="row d-flex justify-content-center">
                  <div className="col-md-8 col-sm-12 col-xs-12 col-12">
                    <div className="wizard">
                      <div className="wizard-inner">
                        <div className="connecting-line" />
                        <ul className="nav nav-tabs" role="tablist">
                          <li role="presentation" className="active">
                            <a href="javascript:void(0)" data-toggle="tab" aria-controls="step1" role="tab" aria-expanded="true"><span className="round-tab">1 </span> <i>Step 1</i></a>
                          </li>
                          <li role="presentation" className="active">
                            <a href="javascript:void(0)" data-toggle="tab" aria-controls="step2" role="tab" aria-expanded="true"><span className="round-tab">2</span> <i>Step 2</i></a>
                          </li>
                          <li role="presentation" className="active">
                            <a href="javascript:void(0)" data-toggle="tab" aria-controls="step3" role="tab"><span className="round-tab">3</span> <i>Step 3</i></a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

              <div className="row">
                {/* <h5 class="johndash">John Doe  /  Add card</h5> */}
                {/* <form action="#" id="userbankaccount" style={{paddingTop:'130px'}}> */}
                  {/* <div className="walletimgblock"> */}
                    
                  {/* </div> */}
                  <p style={{marginTop:'50px'}}>
                  <center>
                    <h3>
                    <img src="icons/success-svgrepo-com 2.png" alt="success-svgrepo-com 2" className="paysuccess" />
                    </h3>
                  <h4 className="paytexts">
                  
                    Payment Successfull

                    
                  </h4>
                  <a href={`${config.baseUrl}transactions`} className="paytexts"> <br/>View transactions</a>
                  </center>
                  </p>
                {/* </form> */}
              </div>
           
              <div className="payment_success">
                <div className="successbox">
                  <img src="icons/success-svgrepo-com 2.png" alt="success-svgrepo-com 2" />
                </div>
                <p>Payment Successful</p>
                <center>
                  <a href={`${config.baseUrl}transactions`} className="paytexts"> <br/>View transactions</a>
                </center>
              </div>

          </div>
        </div >
      </div >
    );

  }
}