import React, { Component } from 'react';
import Header from '../directives/header'
import Footer from '../directives/footer'
import Leftsidebar from '../directives/leftsidebar'
// import Footer from '../directives/footer'
import axios from 'axios'
import config from '../config/config'
import toast, { Toaster } from 'react-hot-toast';
import Cookies from 'js-cookie';
import 'react-toastify/dist/ReactToastify.css';
// import UserBanks from './userBanks';
const headers = {
    'Content-Type': 'application/json'
};

export default class ViewBank extends Component {

    constructor(props) {
        super(props)
        this.state = {
            bank_name: '',
            account_number: '',
            account_holder_name: '',
            bankList: [],
        }
        this.loginData = (!Cookies.get('loginSuccesGarryToken')) ? [] : JSON.parse(Cookies.get('loginSuccesGarryToken'));
    }

    async componentDidMount() {
        document.getElementById("userBanks").classList.add("active");
        this.bank_id = window.location.pathname.split("/").pop()
        await this.getBankDetail();
        // await this.getBankList();
    }

    // async getBankList() {
    //     axios.get(`${config.apiUrl}getBankList`, { headers })
    //       .then(response => {
    //         // this.setState({
    //           let bankList = response.data.response;
    //         // })
          
    //     })
    // }

    async getBankDetail()
    {
      let obj = { bank_id : this.bank_id };
      axios.post(`${config.apiUrl}getBankDetail`, obj, { headers })
        .then(response => {
          if (response.data.success === true) {
            this.setState({
              bank_name: response.data.response.bank_name,
              account_number: response.data.response.account_number,
              account_holder_name: response.data.response.account_holder_name,
            })
          }
          else if (response.data.success === false) {
          }
        })
        .catch(err => {
        })
    }

    
    render() {
        return (
            <div>

                <div className="container-xxl position-relative bg-white d-flex p-0 mb-5">
                    <Leftsidebar />
                    {/* Content Start */}
                    <div className="content">
                        <Header />


                        <div className="row mb-5" id="johndashidone">
                            {/* <h5 class="johndash">John Doe  /  Add card</h5> */}
                            <form action="#" id="userbankaccount">
                                <div className="walletimgblock">
                                    <img src="icons/bank2 1.png" alt="bank2 1" className="walletinput" />
                                </div>
                                <div className="selectbox">
                                    <h3>Bank Name</h3>
                                    <p>{this.state.bank_name}</p>
                                   
                                </div>
                                <div className="selectbox">
                                    <h3>Account Number</h3>
                                    <p>{this.state.account_number}</p>
                                    
                                </div>
                                <div className="selectbox">
                                    <h3>Account Holder Name</h3>
                                    <p>{this.state.account_holder_name}</p>
                                </div>
                                <hr></hr>
                              <center>
                                <a href="javascript: history.go(-1)" className='btn btn-danger'><i className='fa fa-arrow-left'></i> Back</a>
                              </center>
                            </form>
                        </div>

                    </div>
                </div>
                <Footer />
            </div>
        );

    }
}
