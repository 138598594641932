import React, { Component } from 'react';
import Header from '../directives/header'
import Footer from '../directives/footer'
import Leftsidebar from '../directives/leftsidebar'
// import Footer from '../directives/footer'
import axios from 'axios'
import config from '../config/config'
import toast, { Toaster } from 'react-hot-toast';
import Cookies from 'js-cookie';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import moment from "moment";
import Web3 from 'web3';
const headers = {
  'Content-Type': 'application/json'
};
export default class PayWithBank extends Component {

  constructor(props) {
    super(props)
    this.state = {
      admin_bank_list: [],
      user_bank_list: [],
      add_bank_section: false,
      select_bank_section: true,
      wallet_address: '',
      bankList: [],
      bank_name: '',
      account_no: '',
      account_holder_name: '',
      admin_bank: false
    }
    this.onChange = this.onChange.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.submitForm2 = this.submitForm2.bind(this);
    this.handleChangeImage = this.handleChangeImage.bind(this);
    this.loginData = (!Cookies.get('loginSuccesGarryToken')) ? [] : JSON.parse(Cookies.get('loginSuccesGarryToken'));
  }

  async componentDidMount() {
    this.get_current_transaction();
    this.getAdminActiveBank();
    this.get_user_banks();
    this.getBankList();
    if (window.ethereum) {
      let web3 = new Web3(window.ethereum);
      let tr_address = await web3.eth.getAccounts();
      if (tr_address.length > 0) {
        this.setState({
          wallet_address: tr_address[0]
        })
      }
    }
  }

  handleChangeImage = (e) => {
    let image_as_base64 = URL.createObjectURL(e.target.files[0])
    let image_as_files = e.target.files[0];

    if (!image_as_files) {
      this.setState({ invalidImage: 'Please select image.' });
      return false;
    }

    // if (!image_as_files.name.match(/\.(jpg|jpeg|png|gif)$/)) {
    // 	this.setState({ invalidImage: 'Please select valid image.' });
    // 	return false;
    // }


    this.setState({
      image_preview: image_as_base64,
      image_file: image_as_files,
    })
  }

  async get_current_transaction() {
    let obj = {
      user_id: this.loginData.data.id,
    }
    axios.post(`${config.apiUrl}getTemporaryData`, obj, { headers })
      .then(response => {
        if (response.data.success === true) {
          // console.log(response.data);
          this.setState({
            currency: response.data.response.currency,
            currency_amount: response.data.response.currency_amount,
            garri_token_amount: response.data.response.garry_token_amount,
            currency_name: response.data.response.currency_name,
            user_name: 'Test',//response.data.user_name,
            date: moment(response.data.response.date).format("DD/MM/YYYY"),
            fees: response.data.response.fees,
            usd_amount: response.data.response.usd_amount,
            payble_amount: parseFloat(response.data.response.currency_amount) + parseFloat(response.data.response.fees),
          })
        }
        else if (response.data.success === false) {
          // toast.error(response.data.msg);
          window.location.href = config.baseUrl;
        }
      })
      .catch(err => {
        // toast.error(err.response.data?.msg);
        window.location.href = config.baseUrl;
      })
  }

  async get_user_banks() {
    let obj = {
      user_id: this.loginData.data.id,
    }
    axios.post(`${config.apiUrl}getUserBankDetails`, obj, { headers })
      .then(response => {
        if (response.data.success === true) {
          this.setState({
            user_bank_list: response.data.response,
          })
        }
        else if (response.data.success === false) {
          this.setState({
            add_bank_section: true,
            select_bank_section: false,
            user_bank_list: [],
          })
        }
      })
      .catch(err => {
        this.setState({
          add_bank_section: true,
          select_bank_section: false,
        })
      })
  }

  // async get_admin_banks() {
  //   let obj = {
  //     user_id: 1,
  //   }
  //   axios.post(`${config.apiUrl}getAdminBankDetails`, obj, { headers })
  //     .then(response => {
  //       if (response.data.success === true) {
  //         this.setState({
  //           admin_bank_list: response.data.response,
  //         })

  //         console.log(this.state.admin_bank_list)
  //       }
  //       else if (response.data.success === false) {
  //         this.setState({
  //           admin_bank_list: [],
  //         })
  //       }
  //     })
  //     .catch(err => {
  //     })
  // }
  async getAdminActiveBank() {

    axios.post(`${config.apiUrl}getAdminActiveBank`, { headers })
      .then(response => {
        if (response.data.success === true) {
          this.setState({
            admin_bank_id : response.data.response.id,
            admin_bank_name: response.data.response.bank_name,
            admin_account_number: response.data.response.account_number,
            admin_account_holder_name: response.data.response.account_holder_name,
            admin_bank: true
          })
        }
        else if (response.data.success === false) {
          this.setState({
            bank_name: '',
            account_no: '',
            account_holder_name: '',
            admin_bank: false
          })
        }
      })
      .catch(err => {
      })
  }

  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  async submitForm(e) {
    e.preventDefault();

    let obj = {
      user_id: this.loginData.data.id,
      bank_name: this.state.bank_name,
      account_number: this.state.account_number,
      account_holder_name: this.state.account_holder_name,
    }
    axios.post(`${config.apiUrl}insertBankDetails`, obj, { headers })
      .then(response => {
        if (response.data.success === true) {
          toast.success('Bank detail added successfully.');
          this.setState({
            add_bank_section: false,
            select_bank_section: true,
            bank_name:'',
            account_number:'',
            account_holder_name:''
          })
          this.get_user_banks();
        }
        else if (response.data.success === false) {
          toast.error(response.data.msg);
        }
      })
      .catch(err => {
        toast.error(err.response.data?.msg);
      })
  }


  async add_new_bank() {
    await this.setState({
      add_bank_section: true,
      select_bank_section: false,
    })

  }

  async cancel_add_bank() {
    await this.setState({
      add_bank_section: false,
      select_bank_section: true,
    })
  }

  async submitForm2(e) {
    e.preventDefault();
    if (!this.state.wallet_address) {
      toast.error('Please enter wallet address.', {});
      return;
    }
    // else if (!this.state.admin_bank_id) {
    //   toast.error('Deposit bank account must be required.', {});
    //   return;
    // }
    else if (!this.state.user_bank_id) {
      toast.error('Your bank account must be required.', {});
      return;
    }
    else if (!this.state.image_file) {
      toast.error('Payment receipt must be required.', {});
      return;
    }

    try {
      const web3 = new Web3()
      let addr_chk = await web3.utils.toChecksumAddress(this.state.wallet_address)
      if (addr_chk == this.state.wallet_address) {
        const formData = new FormData();
        formData.append('user_id', this.loginData.data.id);
        formData.append('currency', this.state.currency);
        formData.append('currency_amount', this.state.currency_amount);
        formData.append('garri_token_amount', this.state.garri_token_amount);
        formData.append('fees', this.state.fees);
        formData.append('usd_amount', this.state.usd_amount);
        formData.append('payment_method', 'BANK');
        formData.append('user_bank_id', this.state.user_bank_id);
        formData.append('admin_bank_id', this.state.admin_bank_id);
        formData.append('bank_receipt', this.state.image_file);
        formData.append('token_transfer_address', this.state.wallet_address);
        await axios({
          method: 'post',
          url: `${config.apiUrl}submit_bank_transfer`,
          data: formData
        }).then(result => {
          // console.log(result);
          if (result.data.success == true) {
            toast.success(result.data.msg, {});
            setTimeout(() => {
              window.location.href = config.baseUrl + 'confirmation';
            }, 1200)
          }
          else {
            toast.error(result.data.msg, {});
          }
        })
          .catch(err => {
            toast.error(err.response.data.msg, {});
          });
      }
      else {
        toast.error('Please enter correct wallet address.', {});
        return;
      }
    }
    catch {
      toast.error('Please enter correct wallet address.', {});
      return;
    }


  }

  copyToClipboard = async (e) => {
    if (this.state.account_number != '') {
      await navigator.clipboard.writeText(this.state.account_number);
      toast.success('Account no. copied!')
    }
  };

  async getBankList() {
    axios.get(`${config.apiUrl}getBankList`, { headers })
      .then(response => {
        this.setState({
          bankList: response.data.response,
        })
      })
  }

  render() {
    return (
      <div style={{ overflowX: "hidden" }}>

        <div className="">
          <Leftsidebar />
          {/* Content Start */}
          <div className="content">
            <Header />

            {/* <section className="signup-step-container">
              <div className="container">
                <div className="row d-flex justify-content-center">
                  <div className="col-md-8 col-sm-12 col-xs-12 col-12">
                    <div className="wizard">
                      <div className="wizard-inner">
                        <div className="connecting-line" />
                        <ul className="nav nav-tabs" role="tablist">
                          <li role="presentation" className="active">
                            <a href="javascript:void(0)" data-toggle="tab" aria-controls="step1" role="tab" aria-expanded="true"><span className="round-tab">1 </span> <i>Step 1</i></a>
                          </li>
                          <li role="presentation" className="">
                            <a href="javascript:void(0)" data-toggle="tab" aria-controls="step2" role="tab" aria-expanded="true"><span className="round-tab">2</span> <i>Step 2</i></a>
                          </li>
                          <li role="presentation" className="disabled">
                            <a href="javascript:void(0)" data-toggle="tab" aria-controls="step3" role="tab"><span className="round-tab">3</span> <i>Step 3</i></a>
                          </li>
                        </ul>
                        <a className='btn btn-danger btn-sm' href='javascript:history.back()' style={{ float: 'right', marginLeft: '55px' }}> &nbsp; Back &nbsp;</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section> */}

            <section className="signup-step-container">
              <div className="container">
                <div className="row d-flex justify-content-center">
                  <div className="col-md-8">
                    <div className="wizard" id="wizardmain1">
                  <div className="wizard-inner">
                      <div className="connecting-line" />
                      <ul className="nav nav-tabs" role="tablist">
                        <li role="presentation" className="active">
                          <a href="" data-toggle="tab" aria-controls="step1" role="tab" aria-expanded="true"><span className="round-tab">1 </span> <i>Step 1</i></a>
                        </li>
                        <li role="presentation" className="active">
                          <a href="javascript:void(0)" data-toggle="tab" aria-controls="step2" role="tab" aria-expanded="false"><span className="round-tab">2</span> <i>Step 2</i></a>
                        </li>
                        <li role="presentation" className="disabled">
                          <a href="javascript:void(0)" data-toggle="tab" aria-controls="step3" role="tab"><span className="round-tab">3</span> <i>Step 3</i></a>
                        </li>
                        {/* <li role="presentation" class="disabled">
                                    <a href="#step4" data-toggle="tab" aria-controls="step4" role="tab"><span class="round-tab">4</span> <i>Step 4</i></a>
                                </li> */}
                      </ul>
                    </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            {this.state.add_bank_section ?
              <div className="row" id="johndashidone">
                {/* <h5 class="johndash">John Doe  /  Add card</h5> */}
                <form action="#" id="userbankaccount">
                  <div className="walletimgblock">
                    <img src="icons/bank2 1.png" alt="bank2 1" className="walletinput" />
                  </div>
                  <div className="selectbox">
                    <h3>Select  Bank Name</h3>
                    <p>Select  the name of bank you want to add</p>
                    <div className="input-group mb-3 selectrow">

                      <select className='form-control' name="bank_name" onChange={this.onChange} value={this.state.bank} style={{ textAlign: 'center', background: '#fff' }}>
                        <option value=''>- Select Bank -</option>
                        {this.state.bankList.length > 0 ?
                          this.state.bankList.map((item, i) =>
                            <option value={item.id}>{item.bank_name}</option>
                          )
                          : ''}
                      </select>
                      <div className="input-group-text bankdiv" style={{ padding: '0.375rem 1.75rem' }}>
                        <img src="icons/bank.png" alt="bank" className="bankinput" />
                      </div>
                    </div>
                  </div>
                  <div className="selectbox">
                    <h3>Account Number</h3>
                    <p>Enter your account number</p>
                    <div className="input-group mb-3 selectrow">
                      <input type="text" className="form-control text-center" placeholder="Account Number" name="account_number" value={this.state.account_number} onChange={this.onChange} autoComplete="off" />
                    </div>
                  </div>
                  <div className="selectbox">
                    <h3>Account Holder Name</h3>
                    <p>Enter account holder name</p>
                    <div className="input-group mb-3 selectrow">
                      <input type="text" className="form-control text-center" placeholder="Account Holder Name" name="account_holder_name" value={this.state.account_holder_name} onChange={this.onChange} autoComplete="off" />
                    </div>
                  </div>
                  <div className="canclesubmit" id="cancleids">
  <div className="col-md-6">
    <button type='button' onClick={this.cancel_add_bank.bind(this)} className="btn btn-danger expirydatesubmit">Cancel</button>
  </div>
  <div className="col-md-6">
    <button onClick={this.submitForm} className="btn btn-success expirydatesubmit">Submit</button>
  </div>
</div>
                </form>
              </div>
              :
              ''
            }

            {this.state.select_bank_section ?
              <div className="row" id="johndashid">
                <div className="main-content">
                  <div className="tophead" id="selecttop">
                    <h4>Select Bank</h4>
                  </div>
                  <form action="#" id="bankform2">

                    <div className="bankcontent">
                      <div className="bankimgblock borderimg">
                        <img src="icons/bank2 23.png" alt="bank2 23" className="bankinput" />
                      </div>
                      <h5>Bank payment</h5>
                      <p>Kindly select one from your saved account copy
                        our account provided below and make your cash
                        deposit via your selected account to enable fast
                        and easy confirmation.</p>
                    </div>

                    <div className='row confirm_order_section' style={{ background: '#fff', padding: '16px', marginBottom: '30px'}}>
                      <div className='col-md-12'>
                        <h5>Confirm order info :</h5>
                        <div className='confirm-inner'>
                        <div className='col-md-4 col-sm-6'>
                          <label>Amount</label>
                          <h5 className='text-green'>{this.state.currency_name} {this.state.currency_amount}</h5>
                        </div>
                        <div className='col-md-4 col-sm-6'>
                          <label>Quantity</label>
                          <h5>{this.state.garri_token_amount} GARRI  </h5>
                        </div>
                        </div>
                      </div>
                      <div className='col-md-12'>
                        {this.state.admin_bank ? 
                        <>
                        <p className='text-orange'>Transfer the fund to seller's account provided below.</p>
                        <div className='trnainner'>
                        <div className='col-md-4 col-sm-12 col-12'>
                          <label>Name</label>
                          <h5>{this.state.admin_account_holder_name.toUpperCase()} </h5>
                        </div>
                        <div className='col-md-4 col-sm-12 col-12'>
                          <label>Bank account number</label>
                          <h5 onClick={this.copyToClipboard.bind(this)} >{this.state.admin_account_number} <img src="icons/copy-svgrepo-com 1.png" alt="copy-svgrepo-com 1" class="bankinput" style={{height:'23px'}} /></h5>
                        </div>
                        <div className='col-md-4 col-sm-12 col-12'>
                          <label> Bank name</label>
                          <h5>{this.state.admin_bank_name} </h5>
                        </div>
                        </div>
                        </>
                        :
                        <p>
                          <h6>Seller bank account detail not available.</h6>
                        </p>
                      }
                      </div>
                    </div>

                    <div className="selectbox" id="walletadd">
                      <h3>Wallet Address</h3>
                      <p>Add your wallet address to recive your Garri Token</p>
                      <div className="input-group mb-3 selectrow">
                        <input type="text" className="form-control text-center active" name='wallet_address' placeholder="Wallet address" onChange={this.onChange} autoComplete="off" value={this.state.wallet_address} />
                        {/* <div className="input-group-text bankdiv" onClick={this.copyToClipboard.bind(this)}>
                          <img src="icons/copy-svgrepo-com 1.png" alt="copy-svgrepo-com 1" className="bankinput" />
                        </div> */}
                      </div>
                    </div>

                    {/* <div className="selectbox">
                      <h3>Account Number</h3>
                      <p>Deposit your payment for Garri token to this account</p>
                      <div className="input-group mb-3 selectrow" id="aanumid">
                        <select className='form-control' name="admin_bank_id" value={this.state.admin_bank_id} onChange={this.onChange} style={{ textAlign: 'center', background: '#fff' }}>
                          <option value=''>- Select Deposit Bank -</option>
                          {this.state.admin_bank_list.map((item, i) =>
                            <option value={item.id}>{item.account_number} ( {item.bank_name} )</option>
                          )}
                        </select>
                        <div className="input-group-text bankdiv" style={{ padding: '0.375rem 1.75rem' }}>
                          <img src="icons/bank.png" alt="copy-svgrepo-com 1" className="bankinput" />
                        </div>
                      </div>

                      <div className="input-group mb-3 selectrow" id="accnumberid">
                        <div className="input-group-text bankdiv">
                          <img src="icons/zenith 1.png" alt="zenith 1" className="bankinput" />
                        </div>
                        <input type="text" className="form-control text-center active" placeholder={23100672519} />
                        <div className="input-group-text bankdiv">
                          <img src="icons/copy-svgrepo-com 1.png" alt="copy-svgrepo-com 1" className="bankinput" />
                        </div>
                      </div>
                    </div> */}

                    <div className="selectbox">
                      <h3>Select Your Account</h3>
                      <p>Select the account from which you will  pay for Garri Token</p>
                      <div className="input-group mb-3 selectrow" id='seleaccold'>
                        <select className='form-control' name="user_bank_id" value={this.state.user_bank_id} onChange={this.onChange} style={{ textAlign: 'center', background: '#fff' }}>
                          <option value=''>- Select Your Account -</option>
                          {this.state.user_bank_list.map((item, i) =>
                            <option value={item.id}>{item.account_number} ( {item.bank_name} )</option>
                          )}
                        </select>
                        <div className="input-group-text bankdiv" style={{ padding: '0.375rem 1.75rem' }}>
                          <img src="icons/bank.png" alt="bank" className="bankinput" />
                        </div>
                        {/* <input type="text" className="form-control text-center active" placeholder="John Doe GTB Bank Account" />
                        <button className="btn btn-outline-secondary dropdown-toggle" id="selecttoggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" />
                        <ul className="dropdown-menu">
                          <li><a className="dropdown-item" href="#">Action</a></li>
                          <li><a className="dropdown-item" href="#">Another action</a></li>
                          <li><a className="dropdown-item" href="#">Something else here</a></li>
                          <li><hr className="dropdown-divider" /></li>
                          <li><a className="dropdown-item" href="#">Separated link</a></li>
                        </ul> */}
                      </div>
                      <div className="input-group mb-3 selectrow" id='seleaccnew'>
                        <input type="text" className="form-control text-center active" placeholder="John Doe GTB Bank Account" />
                        <button className="btn btn-outline-secondary dropdown-toggle" id="selecttoggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" />
                        <ul className="dropdown-menu" style={{}}>
                          <li><a className="dropdown-item" href="#">Action</a></li>
                          <li><a className="dropdown-item" href="#">Another action</a></li>
                          <li><a className="dropdown-item" href="#">Something else here</a></li>
                          <li><hr className="dropdown-divider" /></li>
                          <li><a className="dropdown-item" href="#">Separated link</a></li>
                        </ul>
                        {/* <div class="input-group-text bankdiv">
                                                    <img src="icons/bank.png" alt="bank" class="bankinput">
                                                  </div> */}
                      </div>
                    </div>
                    <div className="selectbox" id='uploadboxid'>
                      <h3>Upload Payment Receipt</h3>
                      <p>After payment complete please upload payment receipt.</p>
                      <div className="input-group mb-3 selectrow">
                        <input type="file" className="form-control text-center active" style={{ textAlign: 'center', background: '#fff' }} name="bank_receipt" onChange={this.handleChangeImage} />
                      </div>
                    </div>
                    <div className="bankbtn" id='paybanksubmit'>
                      <button type='submit' onClick={this.submitForm2} className="btn btn-success expirydatesubmit">Submit</button>
                    </div>
                  </form>
                  <div className="addaccount" id='addbtnid'>
                    <div class="tophead" id="addcardid">
                      <a href="javascript:;" onClick={this.add_new_bank.bind(this)} class="addcards"><span><img src="icons/add_btn.png" alt="add_btn" /></span>Add Account</a>
                    </div>
                    <h5><a href='javascript:;' onClick={this.add_new_bank.bind(this)} id="addaccid"> + Add Account </a></h5>
                    <p>Haven’t added an account yet? click this link to register one and complete
                      your purchase of Garri Token</p>
                    <p><img src="icons/addaccount.png" alt="addaccount" className="addaccountimg" id="addaccimg" /><span>Other payment options</span></p>
                  </div>
                </div>
              </div>


              :
              ''
            }

            {/* <div className="confirmbottom">
                <div className="mainconfirmbtn">
                  <a href="#" onClick={this.submitForm}>Confirm</a>
                </div> 
                <div className='confirmbottom'>
                <div className="mainconfirmbtn">
                <button className="btn btn-success" id='submitconf' onClick={this.submitForm}>Confirm</button>
                </div> 
                </div>               
              </div> */}


          </div>

        </div >
        <Footer />
      </div >
    );

  }
}


