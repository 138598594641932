import React, { Component } from 'react';
import Header from '../directives/header'
import Footer from '../directives/footer'
import Leftsidebar from '../directives/leftsidebar'
// import Footer from '../directives/footer'
import axios from 'axios'
import config from '../config/config'

const headers = {
    'Content-Type': 'application/json'
};

export default class UserGuide extends Component {

    constructor(props) {
        super(props)
        this.state = {
            user_guide_list : []
        }
    }

    componentDidMount() {
        this.getUserGuides();
        document.getElementById("userGuide").classList.add("active");
    }
    async getUserGuides() {
        axios.get(`${config.apiUrl}getUserGuides`)
            .then(async response => {
                if (response.data.success === true) {
                    let user_guide_list = response.data.response;
                    await this.setState({
                        heading0:user_guide_list[0].heading,
                        heading1:user_guide_list[1].heading,
                        heading2:user_guide_list[2].heading,
                        title0:user_guide_list[0].title,
                        title1:user_guide_list[1].title,
                        title2:user_guide_list[2].title,
                    })
                }
                // console.log(this.state.user_guide_list[0].heading)

            })
            .catch(err => {
                // toast.error(err.response.data?.msg);
            })
    }
    render() {
        return (
            <div>

                <div className="">
                    <Leftsidebar />
                    {/* Content Start */}
                    <div className="content">
                        <Header />

                        <div className='row'>
                        <h5 class="blog" id="blogsidone">User Guide</h5>

                        <div className="accordion" id="accordionExample">
                            <div className='row'>
                                <div className='payinner'>
                                    <div className='col-lg-4 col-md-4 col-sm-12'>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="headingOne">
                                            <button className="accordion-button collapsed" id="usercart" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                                <a href={`${config.baseUrl}userGuide_1st`} className="cardsmall">
                                                    <div className="cardssinner1" id='paycardsinnnerid'>
                                                        <div className="card" id="accardblock">
                                                            <div className='guide-inner' id="guide-innerone">
                                                                <div className='guidtext'>
                                                                    <h4>{this.state.heading0}</h4>
                                                                </div>
                                                                <div className='guidicon'>
                                                                    <img src="icons/p_wallet.png" alt="p_wallet" className='guidicinimg'/>
                                                                </div>
                                                            </div>
                                                        <div className="card-body">
                                                            <div className='wall_inner'>
                                                                <h5 className="card-title">
                                                                    {this.state.title0}
                                                                </h5>
                                                                <a href={`${config.baseUrl}userGuide_1st`} class="btn btn-primary" id="paymentbtnone">Read</a>
                                                            </div>
                                                            {/* <p className="carda-text">
                                                            <span>
                                                                <img src="icons/time.png" alt="time" className='timeico'/>
                                                            </span>2 days ago</p> */}
                                                        </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </button>
                                            </h2>
                                        </div>
                                    </div>

                                    <div className='col-lg-4 col-md-4 col-sm-12'>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="headingTwo">
                                            <button className="accordion-button collapsed" id="usercart" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                <a href={`${config.baseUrl}userGuide_2nd`} className="cardsmall">
                                                    <div className="cardssinner1" id='paycardsinnnerid'>
                                                        <div className="card" id="accardblock">
                                                            <div className='guide-inner' id="guide-innertwo">
                                                                <div className='guidtext'>
                                                                    <h4>{this.state.heading1}</h4>
                                                                </div>
                                                                <div className='guidicon'>
                                                                    <img src="icons/p_upload.png" alt="p_upload" className='guidicinimg'/>
                                                                </div>
                                                            </div>
                                                        <div className="card-body">
                                                            <div className='wall_inner'>
                                                                <h5 className="card-title">
                                                                
                                                                {this.state.title1}
                                                                    {/* How to upload payment proof for faster confirmation */}
                                                                    </h5>
                                                                <a href={`${config.baseUrl}userGuide_2nd`} class="btn btn-primary" id="paymentbtntwo">Read</a>
                                                            </div>
                                                            {/* <p className="carda-text">
                                                            <span>
                                                                <img src="icons/time.png" alt="time" className='timeico'/>
                                                            </span>2 days ago</p> */}
                                                        </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </button>
                                            </h2>
                                        </div>
                                    </div>
                                    
                                    <div className='col-lg-4 col-md-4 col-sm-12'>
                                        <div className="accordion-item" id="accordionbox">
                                            <h2 className="accordion-header" id="headingThree">
                                            <button className="accordion-button" type="button" id="usercart" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="true" aria-controls="collapseThree">
                                                <a href={`${config.baseUrl}userGuide_3rd`} className="cardsmall">
                                                    <div className="cardssinner1" id='paycardsinnnerid'>
                                                        <div className="card" id="accardblock">
                                                            <div className='guide-inner' id="guide-innerthree">
                                                                <div className='guidtext'>
                                                                    <h4>{this.state.heading2}</h4>
                                                                </div>
                                                                <div className='guidicon'>
                                                                    <img src="icons/p_manage.png" alt="p_manage" className='guidicinimg'/>
                                                                </div>
                                                            </div>
                                                        <div className="card-body">
                                                            <div className='wall_inner'>
                                                                <h5 className="card-title">
                                                                {this.state.title2}
                                                                    {/* How to modify your profile and get verified */}
                                                                    </h5>
                                                                <a href={`${config.baseUrl}userGuide_3rd`} class="btn btn-primary" id="paymentbtnthree">Read</a>
                                                            </div>
                                                            {/* <p className="carda-text">
                                                            <span>
                                                                <img src="icons/time.png" alt="time" className='timeico'/>
                                                            </span>2 days ago</p> */}
                                                        </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </button>
                                            </h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                            
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );

    }
}
