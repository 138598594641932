import React, { Component } from 'react';
import { BrowserView, MobileView } from 'react-device-detect';

import config from '../config/config'

export default class Footer extends Component {

 

    componentDidMount() {


    }


    render() {

        return (
            <>
            <BrowserView>
             <footer className="footer container-fluid pl-30 pr-30">
                 <div className="row">
                     <div className="col-sm-12">
                     </div>
                 </div>
             </footer>
             </BrowserView>
        <MobileView>
          {/* <h3>Hello</h3> */}
          {/* <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br> */}
          <footer class="mainfooter">
                <div class="container">
                    <div class="row">
                        <div class="col-12 sm-p">
                            <div class="ftn-inner" id="ftn-innerid">
                                <ul>
                                <li><a href={`${config.baseUrl}dashboard`}><span><img src="icons/dashicon.png" alt="user-profile-svgrepo-com 1" class="ftricon" /></span>Home</a></li>
                                    <li><a href={`${config.baseUrl}profile`}><span><img src="icons/user-profile-svgrepo-com 1.png" alt="user-profile-svgrepo-com 1" class="ftricon" /></span>Profile</a></li>
                                    {/* <li><a href={`${config.baseUrl}setting`}><span><img src="icons/settings.png" alt="settings" class="ftricon" /></span>Settings</a></li> */}
                                    <li><a href={`${config.baseUrl}faqs`}><span><img src="icons/about-faq.png" alt="about-faq" class="ftricon" /></span>F.A.Q</a></li>
                                    <li><a href={`${config.baseUrl}userGuide`}><span><img src="icons/open-book.png" alt="open-book" class="ftricon" /></span>User guide</a></li>
                                    <li><a href="#" class="sidebar-toggler flex-shrink-0 activered"><span><img src="icons/more-circle-svgrepo-com 1.png" alt="more-circle-svgrepo-com 1" class="ftricon" /></span>More</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </MobileView>
            </>
        )
    }
}