import React, { Component } from 'react';
import Header from '../directives/header'
import Footer from '../directives/footer'
import Leftsidebar from '../directives/leftsidebar'
// import Footer from '../directives/footer'
// import axios from 'axios'
// import config from '../config/config'
// import Cookies from 'js-cookie';

export default class Profpage extends Component {


    componentDidMount() {
        document.getElementById("set_nav").classList.add("show");
        document.getElementById("set_option_div").classList.add("show");
        document.getElementById("set_nav").setAttribute("aria-expanded", true);
    }

    render() {
        return (
            <div>

                <div className="container-xxl position-relative bg-white d-flex p-0">
                    <Leftsidebar />
                    {/* Content Start */}
                    <div className="content">
                        <Header />


                        <div className="row" id="johndashidone">
                            <div className='upload_inner mt-5'>
                                <div className='uploadimgbox'>
                                    <img src="icons/profupload.png" alt="profupload  " className="" />
                                </div>
                                <h4>Upload Proof of payment</h4>
                                <p>Upload a screen shoot or pdf document of your  your cash
                                    deposit via your selected account for fast
                                    and easy payment confirmation.
                                </p>
                                <div className='uploadbtnbox' id="uploadbtnid">
                                {/* <input id="upload" type="file" accept="image/*" /> */}
                                <div class="variants">
                                    <div class='file'>
                                    <label for='input-file' id='uploadlabel'>
                                        Upload File
                                    </label>
                                    <input id='input-file' type='file' />
                                    </div>
                                </div>
                                </div>
                            </div>
                            <a href='#' className='uplaodlatter'>
                                <span><img src="icons/uploadic.png" alt="uploadic" id="" /></span>
                                Upload later
                            </a>
                        </div>
                        </div>
                    </div>
                    <Footer />

                </div>
                );

    }
}