import React, { Component } from 'react';
import Header from '../directives/header'
import Footer from '../directives/footer'
import Leftsidebar from '../directives/leftsidebar'

import axios from 'axios'
import config from '../config/config'
import toast, { Toaster } from 'react-hot-toast';
import Cookies from 'js-cookie';
import 'react-toastify/dist/ReactToastify.css';
// import UserBanks from './userBanks';
const headers = {
    'Content-Type': 'application/json'
};

export default class AddCards extends Component {

    constructor(props) {
        super(props)
        this.state = {
            card_number: '',
            card_name: '',
            expiry_month: '',
            expiry_year: '',
        }
        this.onChange = this.onChange.bind(this);
        this.handleChangeNumber = this.handleChangeNumber.bind(this);
        this.submitForm = this.submitForm.bind(this);
        this.loginData = (!Cookies.get('loginSuccesGarryToken')) ? [] : JSON.parse(Cookies.get('loginSuccesGarryToken'));
    }

    componentDidMount() {
        document.getElementById("dashboard").classList.add("active");
    }

    onChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    handleChangeNumber(event) {
        const re = /^[0-9\b]+$/;
          if (event.target.value === '' || re.test(event.target.value)) {
            this.setState({
                [event.target.name]: event.target.value
            })
          }
      }

    async submitForm(e) {
        e.preventDefault();

        let obj = {
            user_id: this.loginData.data.id,
            card_number: this.state.card_number,
            card_name: this.state.card_name,
            name_on_card: this.state.name_on_card,
            expiry_month: this.state.expiry_month,
            expiry_year: this.state.expiry_year,
        }
        //   console.log(obj)
        //   return;
        axios.post(`${config.apiUrl}insertDebitCard`, obj, { headers })
            .then(response => {
                if (response.data.success === true) {
                    toast.success('Card detail added successfully.');
                    setTimeout(() => {
                        window.location.href = config.baseUrl + 'payWithCards'
                    }, 1000);
                }
                else if (response.data.success === false) {
                    toast.error(response.data.msg);
                }
            })
            .catch(err => {
                toast.error(err.response.data?.msg);
            })
    }


    render() {
        return (
            <div>

                <div className="container-xxl position-relative bg-white d-flex p-0">
                    <Leftsidebar />
                    {/* Content Start */}
                    <div className="content">
                        <Header />


                        <div className="row" id="johndashidone">
                            {/* <h5 className="johndash">John Doe  /  Add card</h5> */}
                            <div className="row">
                                <form action="#" id="addcardform">
                                    <h4>Add Card</h4>
                                    <div className="selectbox">
                                        <h3>Card Number</h3>
                                        <p>Enter the 16 - digit number on your card</p>
                                        <div className="input-group mb-3 selectrow">
                                            <input type="text" className="form-control" name="card_number" pattern="[0-9]*" value={this.state.card_number} onChange={this.handleChangeNumber} autoComplete="off" maxLength={16} />
                                        </div>
                                    </div>
                                    <div className="selectbox">
                                        <h3>Card Name</h3>
                                        <p>Enter the name written  on your card</p>
                                        <div className="input-group mb-3 selectrow">
                                            <input type="text" className="form-control" name="card_name" value={this.state.card_name} onChange={this.onChange} autoComplete="off" />
                                        </div>
                                    </div>
                                    <div className="mainselectbox" id="cvvinner">
                                        <div className="selectext">
                                            <h3 className='nameoncard'>Name On Card</h3>
                                            <h3 className='cvvnumber'>CVV Number</h3>
                                            <p>Enter the 3-digit numer card
                                                behind the card</p>
                                        </div>
                                        <div className="selectinput">
                                            <div className="input-group mb-3 selectrow">
                                                <input type="text" className="form-control" name="name_on_card" value={this.state.name_on_card} onChange={this.onChange} autoComplete="off" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mainselectbox" id='mianselectold'>
                                        <div className="selectext">
                                            <h3>Expiry Date</h3>
                                            <p>Enter expiry date of the card</p>
                                        </div>
                                        <div className="selectinput expirydate">
                                            <div className="input-group mb-3 selectrow">
                                                <input type="text" className="form-control" name="expiry_month" value={this.state.expiry_month} onChange={this.handleChangeNumber} maxLength={2} autoComplete="off" />
                                            </div>
                                            <div className="input-group mb-3 selectrow">
                                                <input type="text" className="form-control" name="expiry_year" value={this.state.expiry_year} onChange={this.handleChangeNumber} maxLength={4} autoComplete="off" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="userphonebox" id="userphonebox">
                                        <div className="selectext">
                                        <h3>Expiry Date</h3>
                                        <p>Enter expiry date of the card </p>
                                        </div>
                                        <div className="userphoneinput">
                                        <div className="input-group mb-3 proinput1">
                                            <button className="btn btn-outline-secondary dropdown-toggle" id="selecttoggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" />
                                            <ul className="dropdown-menu">
                                            <li><a className="dropdown-item" href="#">Action</a></li>
                                            <li><a className="dropdown-item" href="#">Another action</a></li>
                                            <li><a className="dropdown-item" href="#">Something else here</a></li>
                                            <li><hr className="dropdown-divider" /></li>
                                            <li><a className="dropdown-item" href="#">Separated link</a></li>
                                            </ul>
                                            <input type="text" className="form-control active" placeholder={26} />
                                        </div>
                                        <div className="input-group mb-3 proinput2">
                                            <button className="btn btn-outline-secondary dropdown-toggle" id="selecttoggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" />
                                            <ul className="dropdown-menu">
                                            <li><a className="dropdown-item" href="#">Action</a></li>
                                            <li><a className="dropdown-item" href="#">Another action</a></li>
                                            <li><a className="dropdown-item" href="#">Something else here</a></li>
                                            <li><hr className="dropdown-divider" /></li>
                                            <li><a className="dropdown-item" href="#">Separated link</a></li>
                                            </ul>
                                            <input type="text" className="form-control active" placeholder="january" />
                                        </div>
                                        <div className="input-group mb-3 proinput3">
                                            <button className="btn btn-outline-secondary dropdown-toggle" id="selecttoggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" />
                                            <ul className="dropdown-menu">
                                            <li><a className="dropdown-item" href="#">Action</a></li>
                                            <li><a className="dropdown-item" href="#">Another action</a></li>
                                            <li><a className="dropdown-item" href="#">Something else here</a></li>
                                            <li><hr className="dropdown-divider" /></li>
                                            <li><a className="dropdown-item" href="#">Separated link</a></li>
                                            </ul>
                                            <input type="text" className="form-control active" placeholder={2022} />
                                        </div>
                                        </div>
                                    </div>

                                    <div className="bankbtn" id="expirebtnid">
                                        <button className="btn btn-success expirydatesubmit" onClick={this.submitForm}>Submit</button>
                                    </div>
                                </form>
                            </div>
                        </div>


                    </div>
                </div>
        <Footer />
            </div>
        );

    }
}