import React, { Component } from 'react';
import Header from '../directives/header'
import Footer from '../directives/footer'
import Leftsidebar from '../directives/leftsidebar'
// import Footer from '../directives/footer'
// import axios from 'axios'
// import config from '../config/config'
// import Cookies from 'js-cookie';

export default class Setting extends Component {


    componentDidMount() {
        document.getElementById("set_nav").classList.add("show");
        document.getElementById("set_option_div").classList.add("show");
        document.getElementById("set_nav").setAttribute("aria-expanded", true);
    }

    render() {
        return (
            <div>

                <div className="container-xxl position-relative bg-white d-flex p-0">
                    <Leftsidebar />
                    {/* Content Start */}
                    <div className="content">
                        <Header />


                        <div className="row" id="johndashidone">
                            {/* <h5 class="johndash">John Doe  /  Add card</h5> */}
                            {/* <form action="#" id="userphoneid">
                                <div className="walletimgblock">
                                    <img src="icons/telephone-userphone.png" alt="telephone-userphone  " className="" />
                                </div>
                            
                            <div className='form-group'>
                                <input type={text} className="form-control" placeholder='Password'></input>
                            </div>
                                
                            </form> */}

                            <h5 className="blog" id="settingtext">
                            Settings
                            </h5>
                            <form action="#" className='mb-5' id="userphoneid">
                            <div className="mb-3" id="formlableid">
                                    <div className='labelimg'>
                                        <div className="formicon">
                                            <img src="icons/key-svgrepo-com 2pass.png" alt="key-svgrepo-com 2pass  " className="" />
                                        </div>
                                        <label htmlFor="exampleInputPassword1" className="form-label"> Password</label>
                                    </div>
                                    <input type="password" className="form-control" id="exampleInputPassword1" placeholder=''/>
                                </div>

                                <div className="mb-3" id="formlableid">
                                    <div className='labelimg'>
                                        <div className="formicon">
                                            <img src="icons/email-svgrepo-com 2 (1).png" alt="email-svgrepo-com 2 (1)  " className="" />
                                        </div>
                                        <label htmlFor="exampleInputEmail1" className="form-label">Email address </label>
                                    </div>
                                    <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder=''/>
                                </div>
                                <div className="mb-3" id="formlableid">
                                    <div className='labelimg'>
                                        <div className="formicon">
                                            <img src="icons/telephone-userphone.png" alt="telephone-userphone  " className="" />
                                        </div>
                                        <label htmlFor="exampleInputEmail1" className="form-label">Phone </label>
                                    </div>
                                    <input type="number" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder=''/>
                                </div>
                                <div className="mb-3" id="formlableid">
                                    <div className='labelimg'>
                                        <div className="formicon">
                                            <img src="icons/credit-card-svgrepo-com 1.png" alt="credit-card-svgrepo-com 1  " className="" />
                                        </div>
                                        <label htmlFor="exampleInputEmail1" className="form-label">Manage cards </label>
                                    </div>
                                    <input type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder=''/>
                                </div>
                            </form>

                        </div>


                        

                        </div>
                    </div>
                    <Footer />

                </div>
                );

    }
}