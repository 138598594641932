// import logo from './logo.svg';
import './App.css';
//==================================  Import all dependencies  ============================

import { BrowserRouter, Route ,Routes} from 'react-router-dom'

import config from './config/config'

import Login from './component/login'
import ForgotPassword from './component/forgotPassword'
import Signup from './component/signup'
import Termsconditions from './component/termsconditions'
import Dashboard from './component/dashboard'
import UpdateProfile from './component/updateProfile'
import Phone from './component/phone'
import ChangePassword from './component/changePassword'
import AddBanks from './component/addBanks'
import UserBanks from './component/userBanks'
import AddCards from './component/addCards'
import PaymentOptions from './component/paymentOptions'
import PayWithCard from './component/payWithCard'
import PayWithBank from './component/payWithBank'
import PayWithCrypto from './component/payWithCrypto'
import Transactions from './component/transactions'
import Confirmation from './component/confirmation'
import Faqs from './component/faqs'
import UserGuide from './component/userGuide'
// import EditBank from './component/editBank';
import ViewBank from './component/viewBank';
import Recent_transactions from './component/Recent_transactions';
import UserGuide_1st from './component/userGuide_1st'
import UserGuide_2nd from './component/userGuide_2nd'
import UserGuide_3rd from './component/userGuide_3rd'
import TransactionsList from './component/transactionsList';
import PublicDashboard from './component/publicDashboard';
// import BlogSample_1 from './component/blogSample_1';
import Blogs from './component/blogs';
import BlogDetail from './component/blogDetail';
import Setting from './component/setting';
import Profile from './component/profile';
import Profpage from './component/profpage';
import Test from './component/test';




// import VerifyAccount from './component/verifyAccount'


function App() {
  return (
    <BrowserRouter>
      <div>
      <Routes>
        {/* User Routes */}
        <Route path = {`${config.baseUrl}`} exact element={<Login/>} />
        <Route path  = {`${config.baseUrl}login`} exact element={<Login/>} />
        <Route path  = {`${config.baseUrl}signup`} exact element={<Signup/>} />
        <Route path  = {`${config.baseUrl}termsconditions`} exact element={<Termsconditions/>} />
        <Route path = {`${config.baseUrl}dashboard`} exact element={<Dashboard/>} />
        <Route path = {`${config.baseUrl}updateProfile`} exact element= {<UpdateProfile/>}/>
        <Route path = {`${config.baseUrl}phone`} exact element= {<Phone/>}/>
        <Route path = {`${config.baseUrl}changePassword`} exact element= {<ChangePassword/>}/>
        <Route path = {`${config.baseUrl}addBanks`} exact element= {<AddBanks/>}/>
        {/* <Route path = {`${config.baseUrl}editBank/:id`} exact element= {<EditBank/>}/> */}
        <Route path = {`${config.baseUrl}viewBank/:id`} exact element= {<ViewBank/>}/>
        <Route path = {`${config.baseUrl}userBanks`} exact element= {<UserBanks/>}/>
        <Route path = {`${config.baseUrl}paymentOptions`} exact element= {<PaymentOptions/>}/>
        <Route path = {`${config.baseUrl}payWithCard`} exact element= {<PayWithCard/>}/>
        <Route path = {`${config.baseUrl}addCards`} exact element= {<AddCards/>}/>
        <Route path = {`${config.baseUrl}payWithBank`} exact element= {<PayWithBank/>}/>
        <Route path = {`${config.baseUrl}payWithCrypto`} exact element= {<PayWithCrypto/>}/>
        <Route path = {`${config.baseUrl}transactions`} exact element= {<Transactions/>}/>
        <Route path = {`${config.baseUrl}confirmation`} exact element= {<Confirmation/>}/>
        <Route path = {`${config.baseUrl}confirmation`} exact element= {<Confirmation/>}/>
        <Route path = {`${config.baseUrl}faqs`} exact element= {<Faqs/>}/>
        <Route path = {`${config.baseUrl}userGuide`} exact element= {<UserGuide/>}/>
        <Route path = {`${config.baseUrl}forgotPassword`} exact element= {<ForgotPassword/>}/>
        {/* <Route path = {`${config.baseUrl}recent_transactions`} exact element= {<Recent_transactions/>}/> */}
        <Route path = {`${config.baseUrl}userGuide_1st`} exact element= {<UserGuide_1st/>}/>
        <Route path = {`${config.baseUrl}userGuide_2nd`} exact element= {<UserGuide_2nd/>}/>
        <Route path = {`${config.baseUrl}userGuide_3rd`} exact element= {<UserGuide_3rd/>}/>
        <Route path = {`${config.baseUrl}transactionsList/:type`} exact element= {<TransactionsList/>}/>
        <Route path = {`${config.baseUrl}publicDashboard`} exact element= {<PublicDashboard/>}/>
        {/* <Route path = {`${config.baseUrl}blogSample_2`} exact element= {<BlogSample_2/>}/> */}
        <Route path = {`${config.baseUrl}blogs`} exact element= {<Blogs/>}/>
        <Route path = {`${config.baseUrl}blog/:slug`} exact element= {<BlogDetail/>}/>
        <Route path = {`${config.baseUrl}setting`} exact element= {<Setting/>}/>
        <Route path = {`${config.baseUrl}profile`} exact element= {<Profile/>}/>
        <Route path = {`${config.baseUrl}profpage`} exact element= {<Profpage/>}/>

        <Route path = {`${config.baseUrl}test`} exact element= {<Test/>}/>


        {/* <Route path = {`${config.baseUrl}email`} exact element= {<Email/>}/> */}
        {/* Admin Routes */}

        </Routes>
       
      </div>
    </BrowserRouter>

  );
}

export default App;
