import React, { Component } from 'react';
import Header from '../directives/header'
import Leftsidebar from '../directives/leftsidebar'
import Footer from '../directives/footer'
import axios from 'axios'
import config from '../config/config'
import moment from "moment";

const headers = {
    'Content-Type': 'application/json'
};
export default class BlogDetail extends Component {


    constructor(props) {
        super(props)
        this.state = {
            blog_detail: [],
        }
    }

    componentDidMount() {
        document.getElementById("blogs").classList.add("active");
        this.slug = window.location.pathname.split("/").pop();
        this.getBlogDetail();
    }

    async getBlogDetail() {
        axios.get(`${config.apiUrl}getBlogDetail/`+this.slug)
            .then(response => {
                if (response.data.success === true) {
                    this.setState({
                        blog_detail: response.data.response
                    })
                }
            })
            .catch(err => {
                // toast.error(err.response.data?.msg);
            })
    }


    render() {

        return (
            <div>

                <div className="container-xxl position-relative bg-white d-flex p-0">
                    <Leftsidebar />
                    {/* Content Start */}
                    <div className="content">
                        <Header />

                    
                        <div className="row" id="johndashidfaq">
                        <div className="row">
                            <div className="cardbox">
                            <div className="col-lg-12 col-md-12 col-12">
                                <div className="cardssinner1" id='cryptocardid'>
                                <div className="card" style={{ width: "18rem" }} id="dailycardss">
                                    <img src={config.apiUrl+this.state.blog_detail.image} id="predictionid" style={{height:'300px'}} className="card-img-top"  alt="prediction 1" />
                                    {/* <img src="img/crypto_img 2.png" className="card-img-top" alt="crypto_img 2" id="dailytips"/> */}
                                    <img src={config.apiUrl+this.state.blog_detail.image} id="coinimgsid" className="card-img-top"  alt="coinimgs" />
                                    <div className="card-body">
                                    <h5 className="card-title mt-3">{this.state.blog_detail.title}</h5>
                                    <p className="carda-text">
                                            <span>
                                            <img src="icons/time.png" alt="time" className="timeico" />
                                            </span>
                                            {moment(this.state.blog_detail.datetime).fromNow()}
                                        </p>
                                    <p className="carda-text">
                                    <div
                          dangerouslySetInnerHTML={{ __html: this.state.blog_detail.discription }}
                          />
                                    {/* {this.state.blog_detail.discription}  */}
                                    </p>
                                    </div>
                                </div>
                                </div>
                            </div>

                           
                            </div>
                        </div>
                        </div>
                    </div>
                    {/* Content End */}
                    {/* Connet Wallet Popup */}
                    {/* The Modal */}

                    

                    {/* JavaScript Libraries */}
                    {/* Template Javascript */}
                </div>
                <Footer />
            </div>
        );

    }
}
