import React, { Component } from 'react';
import Header from '../directives/header'
import Leftsidebar from '../directives/leftsidebar'
// import Footer from '../directives/footer'
import Footer from '../directives/footer'

import axios from 'axios'
import config from '../config/config'
// import toast, { Toaster } from 'react-hot-toast';
import Swal from 'sweetalert2';
import Cookies from 'js-cookie';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import moment from "moment";
const headers = {
  'Content-Type': 'application/json'
};
export default class UserBanks extends Component {

    constructor(props) {
        super(props)
        this.state = {
          bank_account_list: [],
        }
        this.loginData = (!Cookies.get('loginSuccesGarryToken')) ? [] : JSON.parse(Cookies.get('loginSuccesGarryToken'));
      }
    
      componentDidMount() {
        this.get_user_bank_accounts();
        document.getElementById("userBanks").classList.add("active");
      }

      async get_user_bank_accounts() {
        let obj = {
          user_id: this.loginData.data.id,
        }
        axios.post(`${config.apiUrl}getUserBankDetails`, obj, { headers })
          .then(response => {
            if (response.data.success === true) {
              this.setState({
                bank_account_list: response.data.response
              })
            }
            else if (response.data.success === false) {
            }
          })
          .catch(err => {
          })
      }
    
      async deleteThisBank(bank_id)
      {
        Swal.fire({
          // title: 'Are you sure?',
          text: 'Are you sure?',
          icon: 'warning',
          // imageUrl: image,        
          imageHeight: 65, 
          imageWidth: 65,
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes ! Delete It',
          reverseButtons: true
        }).then((result) => {
          if (result.isConfirmed) {
            let obj = { bank_id : bank_id };
            axios.post(`${config.apiUrl}deleteUserBank`, obj, { headers })
              .then(response => {
                if (response.data.success === true) {
                    Swal.fire(
                      'Deleted!',
                      'Bank has been deleted.',
                      'success'
                    )
                    this.get_user_bank_accounts();
                }
                else if (response.data.success === false) {
                  Swal.fire(
                    'Not Deleted!',
                    'Bank not deleted.',
                    'error'
                  )
                }
              })
          }
        })
        
      }

    render() {
        return (
            <div>

                <div className="">
                    <Leftsidebar />
                    {/* Content Start */}
                    <div className="content">
                        <Header />

                        <div className='row' id='johndashidone'>
                            <div class="col-sm-12 col-xl-12 mb-5">
                                <div class="bg-light rounded h-100 p-4">
                                    <div className="tophead">
                                        <h4>Banks Accounts</h4>
                                        <a href={`${config.baseUrl}addBanks`} className="btn btn-primary btn-sm">Add Bank</a>
                                    </div>
                                    <br/>

                                    <div id="banktableid" className='table-responsive'>
                                    <table class="table table-bordered">
                                        <thead>
                                            <tr>
                                                {/* <th scope="col">Sr. no.</th> */}
                                                <th scope="col">Bank Name</th>
                                                {/* <th scope="col">Account No.</th>
                                                <th scope="col">Account Holder Name</th>
                                                <th scope="col">Date</th> */}
                                                <th scope="col">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {this.state.bank_account_list.length > 0 ?
                                        this.state.bank_account_list.map((item, i) =>
                                            <tr>
                                                {/* <th scope="row">{i+parseInt(1)}</th> */}
                                                <td>{item.bank_name}</td>
                                                {/* <td>{item.account_number}</td>
                                                <td>{item.account_holder_name}</td>
                                                <td>{moment(item.date).format("DD/MM/YYYY")}</td> */}
                                                <td>
                                                <a href={`${config.baseUrl}viewBank/`+item.id} className='btn btn-success expirydatesubmit btn-sm'><i className='fa fa-eye'></i></a> &nbsp;
                                                  {/* <a href={`${config.baseUrl}editBank/`+item.id} className='btn btn-info btn-sm'>Edit</a> &nbsp; */}
                                                  <a href="javascript:void(0)" onClick={this.deleteThisBank.bind(this, item.id)} className='btn btn-warning btn-sm'><i className='fa fa-trash'></i></a>
                                                </td>
                                            </tr>
                                            )
                                          :
                                          <tr>
                                                <th colSpan={5} scope="row"><center>No records found.</center></th>
                                          </tr>
                                          }
                                        </tbody>
                                    </table>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>

                        

                    </div>
                </div>
                <Footer />

            </div>
        );

    }
}



