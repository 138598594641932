import React, { Component } from 'react';
import Cookies from 'js-cookie';
import config from '../config/config'
import Web3 from 'web3';
import Onboard from 'bnc-onboard';
import { BrowserView, MobileView, isMobile } from 'react-device-detect';

import { Modal, Button } from "react-bootstrap";


export default class Test extends Component {
	constructor(props) {
		super(props)
		this.state = {
			connected_wallet:'',
			isMetamask: false,
		}
	}
	componentDidMount() {

		setTimeout(async () => {
			if (window.ethereum) {
			  let web3 = new Web3(window.ethereum);
			  var  tr_address = await web3.eth.getAccounts();
			  if(isMobile && tr_address.length == 0){
				tr_address = await window.ethereum.request({
				  method: "eth_requestAccounts",
				});
				console.log(tr_address);
				alert('This is mobile device');
			  }
			  if (tr_address.length > 0) {
				await this.setState({
				  connected_wallet: tr_address[0],
				  wallet_address: tr_address[0],
				  isMetamask: (web3.currentProvider.isMetaMask ? web3.currentProvider.isMetaMask : false),
				})
				await Cookies.set('loginMetamask_garry', tr_address[0]);
			  }
			}
		  }, 1000);
	}

	async connectMetasmask_mobile() {
		let web3
		const onboard = Onboard({
		  dappId: '0880efe2-6c9c-4c85-a416-d5aea1f62201', // [String] The API key created by step one above
		  networkId: 56,  // [Integer] The Ethereum network ID your Dapp uses.
		  walletSelect: {
			wallets: [
			  { walletName: "metamask", preferred: true },
			  { walletName: "trust", preferred: true },
			]
		  },
		  subscriptions: {
			wallet: wallet => {
			  web3 = new Web3(wallet.provider)
			}
		  }
		})
		let a = await onboard.walletSelect();
	  }


	  
	render() {
		return (
			
			<>
				<div>
				<BrowserView>
				<br/>
				<br/>
				<br/>
			<h4>This is Desktop</h4>
				</BrowserView>

				<MobileView>
					<br/>
					<br/>
					<br/>
					<h4>This is mobile device</h4>
					{this.state.connected_wallet != '' ?
						<a href='javascript:void(0)' className='addcards2' >{this.state.connected_wallet.substring(0, 6)}.....{this.state.connected_wallet.slice(-6)}</a>
						:
						<>
						<a href='javascript:void(0)' onClick={this.connectMetasmask_mobile} className='addcards2' >Connect&nbsp;Wallet Mobile {this.state.connected_wallet}</a>
						</>
					}
				</MobileView>
				</div>
			</>
		)
	}
}