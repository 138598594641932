import React, { Component } from 'react';
import Header from '../directives/header'
import Footer from '../directives/footer'
import Leftsidebar from '../directives/leftsidebar'
// import Footer from '../directives/footer'
import axios from 'axios'
import config from '../config/config'
import toast, { Toaster } from 'react-hot-toast';
import 'react-toastify/dist/ReactToastify.css';
import Cookies from 'js-cookie';
import moment from "moment";

const headers = {
  'Content-Type': 'application/json'
};

export default class Recent_transactions extends Component {

  constructor(props) {
    super(props)
    this.state = {
      transactions: [],
      pending_trx_count:0,
      success_trx_count:0,
      failed_trx_count:0,
    }

    this.loginData = (!Cookies.get('loginSuccesGarryToken')) ? [] : JSON.parse(Cookies.get('loginSuccesGarryToken'));
  }

  componentDidMount() {
    this.getMyRecentTransactions();
    document.getElementById("transactions").classList.add("active");
  }

  async getMyRecentTransactions() {
    let obj = {
      user_id: this.loginData.data.id
    }
    axios.post(`${config.apiUrl}getMyAllTransactions`, obj, { headers })
      .then(response => {
        if (response.data.success === true) {
          this.setState({
            transactions: response.data.response,
            allTransactions: response.data.response
          })

          let allData = response.data.response;
          var pending_transactions = allData.filter(function (el) {
            return el.status ==  0
          });
          var success_transactions = allData.filter(function (el) {
            return el.status ==  1
          });
          var failed_transactions = allData.filter(function (el) {
            return el.status ==  2
          });
          // console.log(selected_card)
          this.setState({
            pending_trx_count: pending_transactions.length,
            success_trx_count: success_transactions.length,
            failed_trx_count: failed_transactions.length
          })

        }
      })
      .catch(err => {
        // toast.error(err.response.data?.msg);
      })
  }

  async fiterTransactions(trx_status)
  {
    let allData = this.state.allTransactions;
    var transactions = allData.filter(function (el) {
      return el.status ==  trx_status
    });
    // console.log(selected_card)
    await this.setState({
      transactions: transactions
    })
  }

  render() {
    return (
      <div>

        <div className="container-xxl position-relative bg-white d-flex p-0">
          <Leftsidebar />
          {/* Content Start */}
          <div className="content">
            <Header />
            

            <div className="row" id='recentone'>
              <div className="quikdiv failed one">
                <span><img src="icons/tools.png" alt="tools" className="toolsimg" /></span>
                <h6 className="mb-0 clalehaed">Quick Tools</h6>
              </div>
              <div className="quiktools one" id='quikoldid'>
                <div className="col-lg-4 col-md-4 col-sm-12 col-12 trx_statics" onClick={this.fiterTransactions.bind(this, 0)}>
                  <div className="managebox2 failed">
                    <div className="quikicons">
                      <img src="icons/yelloplus.png" alt="yelloplus" />
                    </div>
                    <div className="quiktext">
                      <h5 className="review1">Click to review</h5>
                      <h3 className="failed">{this.state.pending_trx_count}</h3>
                      <p>List of pending transctions</p>
                    </div>
                  </div>
                  
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 col-12 trx_statics" onClick={this.fiterTransactions.bind(this, 2)}>
                  <div className="managebox2 failed">
                    <div className="quikicons">
                      <img src="icons/corss.png" alt="corss" />
                    </div>
                    <div className="quiktext">
                      <h5 className="review1">Click to review</h5>
                      <h3 className="failed">{this.state.failed_trx_count}</h3>
                      <p>List of cancelled transactions</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 col-12 trx_statics" onClick={this.fiterTransactions.bind(this, 1)}>
                  <div className="managebox2 failed">
                    <div className="quikicons">
                      <img src="icons/success-svgrepo-com 1.png" alt="success-svgrepo-com 1" />
                    </div>
                    <div className="quiktext">
                      <h5 className="review1">Click to review</h5>
                      <h3 className="failed">{this.state.success_trx_count}</h3>
                      <p>List of succesful transactions</p>
                    </div>
                  </div>
                </div>
              </div>
              
              <div className="quiktools one" id='quiknewid'>
                <div className="col-lg-4 col-md-4 col-sm-12 col-12" >
                  <div className="managebox2 failed">
                  <div className='filterinner'>
                        <span><img src="icons/blockfilter.png" alt="blockfilter" className='blockfilter'/></span>
                        <h5 className="review1">Filter by</h5>
                      </div>
                    <div className='quikinner'>
                    <div className="quikicons">
                      <img src="icons/recnetico.png" alt="recnetico" />
                    </div>
                    <div className="quiktext">
                      <h3 className="failed">Recent</h3>
                      <p>Recent transctions</p>
                    </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 col-12 trx_statics" onClick={this.fiterTransactions.bind(this, 0)}>
                  <div className="managebox2 failed">
                  <div className='filterinner'>
                        <span><img src="icons/blockfilter.png" alt="blockfilter" className='blockfilter'/></span>
                        <h5 className="review1">Filter by</h5>
                      </div>
                    <div className='quikinner'>
                    <div className="quikicons">
                      <img src="icons/yelloplus.png" alt="yelloplus" />
                    </div>
                    <div className="quiktext">
                      <h3 className="failed">Pending</h3>
                      <p>Pending transctions</p>
                    </div>
                    </div>
                  </div>
                </div>  
                <div className="col-lg-4 col-md-4 col-sm-12 col-12" onClick={this.fiterTransactions.bind(this, 2)}>
                  <div className="managebox2 failed">
                  <div className='filterinner'>
                        <span><img src="icons/blockfilter.png" alt="blockfilter" className='blockfilter'/></span>
                        <h5 className="review1">Filter by</h5>
                    </div>
                    <div className='quikinner'>
                    <div className="quikicons">
                      <img src="icons/corss.png" alt="corss" />
                    </div>
                    <div className="quiktext">
                      <h3 className="failed">Failed</h3>
                      <p>Failed transactions</p>
                    </div>
                    </div>
                  </div>
                </div> 
                <div className="col-lg-4 col-md-4 col-sm-12 col-12" onClick={this.fiterTransactions.bind(this, 1)}>
                  <div className="managebox2 failed">
                  <div className='filterinner'>
                        <span><img src="icons/blockfilter.png" alt="blockfilter" className='blockfilter'/></span>
                        <h5 className="review1">Filter by</h5>
                    </div>
                    <div className='quikinner'>
                    <div className="quikicons">
                      <img src="icons/success-svgrepo-com 1.png" alt="success-svgrepo-com 1" />
                    </div>
                    <div className="quiktext">
                      <h3 className="failed">Success</h3>
                      <p>Succesful transactions</p>
                    </div>
                    </div>
                  </div>
                </div> 
              </div>

            </div>

            <div className="row" id='recenttwo'>
              <div className="quikdiv failed one">
                <span><img src="icons/tools.png" alt="tools" className="toolsimg" /></span>
                <h6 className="mb-0 clalehaed">Quick Tools</h6>
              </div>
              <div className="quiktools one" id='quikoldid'>
                <div className="col-lg-4 col-md-4 col-sm-12 col-12 trx_statics" onClick={this.fiterTransactions.bind(this, 0)}>
                  <div className="managebox2 failed">
                    <div className="quikicons">
                      <img src="icons/recnetico.png" alt="recnetico" />
                    </div>
                    <div className="quiktext">
                      <div className='filterdiv'>
                      <span><img src="icons/blockfilter.png" alt="blockfilter" className="filterimg" /></span>
                      <h5 className="review1">Filter by</h5>
                      </div>
                      <h3 className="failed">Recent</h3>
                      <p>Recent transctions</p>
                    </div>
                  </div>
                  
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 col-12 trx_statics" onClick={this.fiterTransactions.bind(this, 0)}>
                  <div className="managebox2 failed">
                    <div className="quikicons">
                      <img src="icons/yelloplus.png" alt="yelloplus" />
                    </div>
                    <div className="quiktext">
                    <div className='filterdiv'>
                      <span><img src="icons/blockfilter.png" alt="blockfilter" className="filterimg" /></span>
                      <h5 className="review1">Filter by</h5>
                      </div>
                      <h3 className="failed">Pending</h3>
                      <p>Pending transctions</p>
                    </div>
                  </div>
                  
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 col-12 trx_statics" onClick={this.fiterTransactions.bind(this, 2)}>
                  <div className="managebox2 failed">
                    <div className="quikicons">
                      <img src="icons/corss.png" alt="corss" />
                    </div>
                    <div className="quiktext">
                    <div className='filterdiv'>
                      <span><img src="icons/blockfilter.png" alt="blockfilter" className="filterimg" /></span>
                      <h5 className="review1">Filter by</h5>
                      </div>
                      <h3 className="failed">Failed</h3>
                      <p>Canclled transactions</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 col-12 trx_statics" onClick={this.fiterTransactions.bind(this, 1)}>
                  <div className="managebox2 failed">
                    <div className="quikicons">
                      <img src="icons/success-svgrepo-com 1.png" alt="success-svgrepo-com 1" />
                    </div>
                    <div className="quiktext">
                    <div className='filterdiv'>
                      <span><img src="icons/blockfilter.png" alt="blockfilter" className="filterimg" /></span>
                      <h5 className="review1">Filter by</h5>
                      </div>
                      <h3 className="failed">Success</h3>
                      <p>Succesful transactions</p>
                    </div>
                  </div>
                </div>
              </div>
              
              <div className="quiktools one" id='quiknewid'>
                <div className="col-lg-4 col-md-4 col-sm-12 col-12" >
                  <div className="managebox2 failed">
                  <div className='filterinner'>
                        <span><img src="icons/blockfilter.png" alt="blockfilter" className='blockfilter'/></span>
                        <h5 className="review1">Filter by</h5>
                      </div>
                    <div className='quikinner'>
                    <div className="quikicons">
                      <img src="icons/recnetico.png" alt="recnetico" />
                    </div>
                    <div className="quiktext">
                      <h3 className="failed">Recent</h3>
                      <p>Recent transctions</p>
                    </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 col-12 trx_statics" onClick={this.fiterTransactions.bind(this, 0)}>
                  <div className="managebox2 failed">
                  <div className='filterinner'>
                        <span><img src="icons/blockfilter.png" alt="blockfilter" className='blockfilter'/></span>
                        <h5 className="review1">Filter by</h5>
                      </div>
                    <div className='quikinner'>
                    <div className="quikicons">
                      <img src="icons/yelloplus.png" alt="yelloplus" />
                    </div>
                    <div className="quiktext">
                      <h3 className="failed">Pending</h3>
                      <p>Pending transctions</p>
                    </div>
                    </div>
                  </div>
                </div>  
                <div className="col-lg-4 col-md-4 col-sm-12 col-12" onClick={this.fiterTransactions.bind(this, 2)}>
                  <div className="managebox2 failed">
                  <div className='filterinner'>
                        <span><img src="icons/blockfilter.png" alt="blockfilter" className='blockfilter'/></span>
                        <h5 className="review1">Filter by</h5>
                    </div>
                    <div className='quikinner'>
                    <div className="quikicons">
                      <img src="icons/corss.png" alt="corss" />
                    </div>
                    <div className="quiktext">
                      <h3 className="failed">Failed</h3>
                      <p>Failed transactions</p>
                    </div>
                    </div>
                  </div>
                </div> 
                <div className="col-lg-4 col-md-4 col-sm-12 col-12" onClick={this.fiterTransactions.bind(this, 1)}>
                  <div className="managebox2 failed">
                  <div className='filterinner'>
                        <span><img src="icons/blockfilter.png" alt="blockfilter" className='blockfilter'/></span>
                        <h5 className="review1">Filter by</h5>
                    </div>
                    <div className='quikinner'>
                    <div className="quikicons">
                      <img src="icons/success-svgrepo-com 1.png" alt="success-svgrepo-com 1" />
                    </div>
                    <div className="quiktext">
                      <h3 className="failed">Success</h3>
                      <p>Succesful transactions</p>
                    </div>
                    </div>
                  </div>
                </div> 
              </div>

            </div>

            
            <div className="row" id="recentt">
              {/* <h5 class="johndash">John Doe  /  Add card</h5> */}
              <div className="col-lg-4 col-md-4 col-12 m-0 p-0">
  <div id="january_recent">
    <div className="bg-light" id="recntbg">
      <div
        className="d-flex align-items-center justify-content-between mb-4"
        id="maintrnsid"
      >
        <h6 className="mb-0 clalehaed" id="transid">
          Transaction
        </h6>
        <h6 className="mb-0 clalehaed" id="recenttransid">
          Recent Transaction
        </h6>
        {/* <a href="/gerry_token/transactions" className="showanker">
          See All
        </a> */}
      </div>
      <table className="tablecolor" id="recentableid">
        <tbody>
          <tr>
            <td className="imgborder">
              <button type="button" className="btn btn-dark m-2 green">
              JAN
              </button>
            </td>
            <td className="textcolor">
            GTN 01.937635365<p className="date">12/4/2022</p>
            </td>
            <td>
            NGN 30,000<p className="pendingbtn">Success</p>
            </td>
          </tr>
          <tr>
            <td className="imgborder">
              <button type="button" className="btn btn-dark m-2 green">
              FEB
              </button>
            </td>
            <td className="textcolor">
            GTN 01.937635365<p className="date">10/2/2022</p>
            </td>
            <td>
            NGN 50,000<p className="pendingbtn">Success</p>
            </td>
          </tr>
          <tr>
            <td className="imgborder">
              <button type="button" className="btn btn-dark m-2 green">
              FEB
              </button>
            </td>
            <td className="textcolor">
            GTN 01.937635365<p className="date">20/2/2022</p>
            </td>
            <td>
            NGN 10,000<p className="pendingbtn">Failed</p>
            </td>
          </tr>
          <tr>
            <td className="imgborder">
              <button type="button" className="btn btn-dark m-2 green">
              MAR
              </button>
            </td>
            <td className="textcolor">
            GTN 01.937635365<p className="date">12/3/2022</p>
            </td>
            <td>
            NGN 30,000<p className="pendingbtn">Pending</p>
            </td>
          </tr>
          <tr>
            <td className="imgborder">
              <button type="button" className="btn btn-dark m-2 green">
              JAN
              </button>
            </td>
            <td className="textcolor">
            GTN 01.937635365<p className="date">12/4/2022</p>
            </td>
            <td>
            NGN 30,000<p className="pendingbtn">Success</p>
            </td>
          </tr>
          <tr>
            <td className="imgborder">
              <button type="button" className="btn btn-dark m-2 green">
              FEB
              </button>
            </td>
            <td className="textcolor">
            GTN 01.937635365<p className="date">10/2/2022</p>
            </td>
            <td>
            NGN 50,000<p className="pendingbtn">Success</p>
            </td>
          </tr>
          <tr>
            <td className="imgborder">
              <button type="button" className="btn btn-dark m-2 green">
              FEB
              </button>
            </td>
            <td className="textcolor">
            GTN 01.937635365<p className="date">20/2/2022</p>
            </td>
            <td>
            NGN 10,000<p className="pendingbtn">Failed</p>
            </td>
          </tr>
          <tr>
            <td className="imgborder">
              <button type="button" className="btn btn-dark m-2 green">
              MAR
              </button>
            </td>
            <td className="textcolor">
            GTN 01.937635365<p className="date">12/3/2022</p>
            </td>
            <td>
            NGN 30,000<p className="pendingbtn">Pending</p>
            </td>
          </tr>
          {/* <tr>
            <td className="imgborder">
              <button type="button" className="btn btn-dark m-2 green">
                MAY
              </button>
            </td>
            <td className="textcolor">
              GARRI 5<p className="date">05/05/2022</p>
            </td>
            <td>
              USDT 5<p className="pendingbtn">Pending</p>
            </td>
          </tr>
          <tr>
            <td className="imgborder">
              <button type="button" className="btn btn-dark m-2 green">
                APR
              </button>
            </td>
            <td className="textcolor">
              GARRI 17.700001<p className="date">27/04/2022</p>
            </td>
            <td>
              NGN 10<p className="pendingbtn">Pending</p>
            </td>
          </tr> */}
        </tbody>
      </table>
    </div>
  </div>
</div>

              {/* <div className="circlediv">
                <div className="smallcircle" />
                <div className="smallcircle" />
                <div className="smallcircle" />
                <div className="smallcircle active" />
              </div> */}
            </div>



          </div>
        </div>
        <Footer />
      </div>
    );

  }
}