import React, { Component } from 'react';
import axios from 'axios';
import config from '../config/config'
import toast, { Toaster } from 'react-hot-toast';
import Cookies from 'js-cookie';
import 'react-toastify/dist/ReactToastify.css';

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

const headers = {
    'Content-Type': 'application/json'
};

const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");

export default class ForgotPassword extends Component {
    constructor(props) {
        super(props)
        this.state = {
            phone: '',
            otp: '',
            otp_screen: false,
            reg_screen: true,
            send_btn_text: 'Send OTP',
            verify_btn_text: 'Verify OTP',
            password_screen:false,
        }
        this.onChange = this.onChange.bind(this);
        this.submitForm = this.submitForm.bind(this);
        this.submitSend_otpForm = this.submitSend_otpForm.bind(this);
        if (Cookies.get('loginSuccesGarryToken')) {
            window.location = `${config.baseUrl}dashboard`;
        }
    }
    componentDidMount() {

    }
    onChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    async submitSend_otpForm(e) {
        e.preventDefault()
        if (!this.state.phone) {
            await toast.error('Mobile number required!');
            return;
        }
        this.setState({
            send_btn_text: 'Please wait'
        })
        const data = {
            mobile_no: this.state.phone
        }
        await axios.post(`${config.apiUrl}send_otp_forgot_password`, data, { headers })
            .then(response => {
                if (response.data.success === true) {
                    toast.success(response.data.msg);
                    this.setState({
                        otp_screen: true,
                        reg_screen: false,
                        user_id:response.data.user_id
                    })
                }
                else if (response.data.success === false) {
                    toast.error(response.data.msg);
                    this.setState({
                        send_btn_text: 'Send OTP'
                    })
                }
            })
            .catch(err => {
                toast.error(err.response.data?.msg);
                this.setState({
                    send_btn_text: 'Send OTP'
                })
            })
    }

    async verifyOtp(e) {
        e.preventDefault()
        if (!this.state.otp) {
            await toast.error('OTP is required!');
            return;
        }
        this.setState({
            verify_btn_text: 'Please wait'
        })
        const data = {
            mobile_no: this.state.phone,
            entered_otp: this.state.otp,
        }
        await axios.post(`${config.apiUrl}verifyOtp`, data, { headers })
            .then(response => {
                if (response.data.success === true) {
                    toast.success(response.data.msg);
                    this.setState({
                        otp_screen: false,
                        reg_screen: false,
                        password_screen: true,
                    })
                }
                else if (response.data.success === false) {
                    toast.error(response.data.msg);
                    this.setState({
                        verify_btn_text: 'Verify'
                    })
                }
            })
            .catch(err => {
                toast.error(err.response.data?.msg);
                this.setState({
                    verify_btn_text: 'Verify'
                })
            })
    }



    async submitForm(e) {
        e.preventDefault()
        if(!strongRegex.test(this.state.password)) {
            await toast.error('Password should have one digit and one spacial character!');	
            return;
        }
        if(this.state.password != this.state.confirm_password)
        {
            toast.error('Confirm password dose not match.');
            return;
        }
        const data = {
            password : this.state.password,
            user_id:this.state.user_id
        }

        axios.post(`${config.apiUrl}createNewPassword`, data, { headers })
            .then(response => {
                if (response.data.success === true) {
                    toast.success(response.data.msg);
                    setTimeout(() => {
                        window.location.href = `${config.baseUrl}login`
                    }, 1000);
                }
                else if (response.data.success === false) {
                    toast.error(response.data.msg);
                }
            })
            .catch(err => {
                toast.error(err.response.data?.msg);
            })
    }


    render() {
        return (

            <section className="login" id='varificationid'>
                <img src="img/loginmobile.png" alt="loginmobile" id="loginmobile"></img>
                <div className="inner-img" id="innerimgid">

                    <img src="img/backimg.png" alt="backimg" />
                </div>
                <Toaster
                    position="top-right"
                    reverseOrder={false}
                />
                <div className="container">
                    <div className="row login-content">
                        <div className="col-lg-8 col-md-8">
                            {this.state.reg_screen ?
                                <div className="inner-login" id='logindid'>
                                    <div className="bankimgblock">
                                        <img src="icons/gcoin.png" alt="gcoin" className id="gcoin" />
                                    </div>
                                    <h1>Forgot Password</h1>
                                    <form action="#">

                                        <div className="form-group" id="mobilecount">
                                            <label htmlFor>Mobile no.</label>

                                            <PhoneInput
                                                country={'ng'}
                                                value={this.state.phone}
                                                onChange={phone => this.setState({ phone })}
                                            />
                                        </div>

                                        <br></br>
                                        <div className="loginbtm form-group text-center">
                                            <button type="submit" className="btn btn-success signinbtn" onClick={this.submitSend_otpForm}>{this.state.send_btn_text}</button>
                                            <br />
                                            <br />
                                            <p>Already have an account ?<a href={`${config.baseUrl}login`}> Sign In</a></p>

                                        </div>

                                    </form>
                                </div>
                                :
                                ''
                            }

                            {this.state.otp_screen ?
                                <div className="inner-login" id="otpid">
                                    <div className="bankimgblock">
                                        <img src="icons/gcoin.png" alt="gcoin" className id="gcoin" />
                                    </div>
                                    <h1>OTP Verification</h1>
                                    <p style={{ color: 'green' }}>OTP has been sent to your mobile number.</p>
                                    <form action="#">
                                        <div className="form-group">
                                            <label htmlFor>OTP</label>
                                            <input type="text" className="form-control" required="" value={this.state.otp} onChange={this.onChange} name="otp" id="exampleInputEmail_2" placeholder="Enter Otp" autoComplete='off' />
                                        </div>
                                        <br></br>
                                        <div className="loginbtm">
                                            <button className="btn btn-success signinbtn"
                                                onClick={this.verifyOtp.bind(this)}>{this.state.verify_btn_text}
                                            </button>
                                            <p><a href='javascrip:void(0)' onClick={this.submitSend_otpForm}> Resend OTP</a></p>
                                            {/* <p><a onClick={this.send_otp_on_call.bind(this)} href="javascript:;"> OTP On Call?</a></p> */}

                                            <p>Already have an account ?<a href={`${config.baseUrl}login`}> Sign In</a></p>
                                        </div>
                                    </form>
                                </div>
                                :
                                ''
                            }

                            {this.state.password_screen ?
                                <div className="inner-login" id="newpasswdid">
                                    <div className="bankimgblock">
                                        <img src="icons/gcoin.png" alt="gcoin" className id="gcoin" />
                                    </div>
                                    <h1>Create New Password</h1>
                                    <p style={{ color: 'green' }}>OTP has been verified. Create your new password</p>
                                    <form action="#">
                                        <div className="form-group">
                                            <label htmlFor>Password</label>
                                            <input type="text" className="form-control" required="" value={this.state.password} onChange={this.onChange} name="password" id="exampleInputEmail_2" placeholder="Enter new password" autoComplete='off' />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor>Confirm Password</label>
                                            <input type="text" className="form-control" required="" value={this.state.confirm_password} onChange={this.onChange} name="confirm_password" id="exampleInputEmail_2" placeholder="Enter confirm password" autoComplete='off' />
                                        </div>
                                        <br></br>
                                        <div className="loginbtm">
                                            <button className="btn btn-success signinbtn"
                                                onClick={this.submitForm}>Update
                                            </button>

                                            <p>Already have an account ?<a href={`${config.baseUrl}login`}> Sign In</a></p>
                                        </div>
                                    </form>
                                </div>
                                :
                                ''
                            }
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}