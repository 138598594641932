import React, { Component } from 'react';
import axios from 'axios';
import config from '../config/config'
import toast, { Toaster } from 'react-hot-toast';
import Cookies from 'js-cookie';
import 'react-toastify/dist/ReactToastify.css';
const headers = {
    'Content-Type': 'application/json'
};

export default class Login extends Component {
    constructor(props) {
        super(props)
        this.state = {
            username: '',
            password: '',
            rememberMe: false
        }
        this.onChange = this.onChange.bind(this);
        this.submitForm = this.submitForm.bind(this);

        if (Cookies.get('loginSuccesGarryToken')) {
            window.location = `${config.baseUrl}dashboard`;
        }
    }
    componentDidMount() {
        const rememberMe = localStorage.getItem('rememberMe') === 'true';
        const username = rememberMe ? localStorage.getItem('username') : '';
        const password = rememberMe ? localStorage.getItem('password') : '';
        this.setState({ username, password, rememberMe });
    }
    onChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleChange = (event) => {
        const input = event.target;
        const value = input.type === 'checkbox' ? input.checked : input.value;
    
        this.setState({ [input.name]: value });
      };

    async submitForm(e) {
        e.preventDefault()
        const data = this.state
        const { username, password, rememberMe } = this.state;
        localStorage.setItem('rememberMe', rememberMe);
        localStorage.setItem('username', rememberMe ? username : '');
        localStorage.setItem('password', rememberMe ? password : '');
        // alert(rememberMe);
        // return;
        
        // await fetch(`${config.apiUrl}login`, {
        //         method: 'POST',
        //         body: JSON.stringify(data),
        //         headers: {
        //             'Content-type': 'application/json; charset=UTF-8'
        //         }
        //     })
        //     .then(response => response.json())
        //     .then(json => {
        //         console.log(json);

        //     });

        axios.post(`${config.apiUrl}login`, data, { headers })
            .then(response => {
                if (response.data.success === true) {
                    toast.success(response.data.msg);
                    Cookies.set('loginSuccesGarryToken', JSON.stringify(response.data));

                    setTimeout(() => {
                        window.location.href = `${config.baseUrl}dashboard`
                    }, 1000);
                }
                else if (response.data.success === false) {
                    toast.error(response.data.msg);
                }
            })  
            .catch(err => {
                toast.error(err.response.data?.msg);
            })
    }

    async togglePassword(params) {
        const togglePassword = document.querySelector('#togglePassword');
        const password = document.querySelector('#id_password');
 
        const type = password.getAttribute('type') === 'password' ? 'text' : 'password';
        password.setAttribute('type', type);
        togglePassword.classList.toggle('fa-eye-slash');
    }

    render() {
        return (

            <section className="login" id='logisnid'>
                <img src="img/loginmobile.png" alt="loginmobile" id="loginmobile"></img>
                <div className="inner-img" id="innerimgid">
                    
                    <img src="img/backimg.png" alt="backimg" />
                </div>
                <Toaster
                        position="top-right"
                        reverseOrder={false}
                    />
                <div className="container">
                    <div className="row login-content">
                        <div className="col-lg-8 col-md-8">
                            <div className="inner-login" id='logindid'>
                            <div className="bankimgblock">
                                <img src="icons/gcoin.png" alt="gcoin" className id="gcoin" />
                            </div>
                                <h1>Garri Swap - Login</h1>
                                <form action="#">

                                    <div className="form-group">
                                        <label className="control-label mb-10" for="exampleInputEmail_2">Username</label>
                                        <input type="text" className="form-control" required="" value={this.state.username}
                                            onChange={this.onChange} name="username" id="exampleInputEmail_2" placeholder="Username" autoComplete='off' />
                                    </div>


                                    <div className="form-group">
                                        <label className="pull-left control-label mb-10" for="exampleInputpwd_2">Password</label>
                                        <div className="clearfix"></div>
                                        <input type="password" className="form-control" required="" id="id_password" value={this.state.password}
                                            onChange={this.onChange} name="password" placeholder="Password" autoComplete='off' />
                                         <i class="far fa-eye" id="togglePassword" onClick={this.togglePassword.bind(this)}></i>


                                        <div className="form-group form-control-inline">
                                            <label className="form-check-label form-text" htmlFor="flexSwitchCheckChecked">Remember me</label>
                                            {/* <div className="form-check form-switch">
                                                <input className="form-check-input" type="checkbox" id="flexSwitchCheckChecked" name='rememberMe' checked={this.state.rememberMe} onChange={this.handleChange} />
                                            </div> */}
                                            <label className="switch">
                                                <input type="checkbox" defaultChecked="" />
                                                <span className="slider round" />
                                            </label>
                                           
                                        </div>


                                        <br></br>
                                        <div className="loginbtm form-group text-center">
                                            <button type="submit" className="btn btn-success signinbtn" onClick={this.submitForm}>Sign In</button>
                                            <br />
                                            <br />
                                            <p><a href={`${config.baseUrl}forgotPassword`}> Forgot password?</a></p>
                                            <p>Didn't have an account ?<a href={`${config.baseUrl}signup`}> Sign Up</a></p>
                                            
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}