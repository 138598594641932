import React, { Component } from 'react';
import Header from '../directives/header'
import Footer from '../directives/footer'
import Leftsidebar from '../directives/leftsidebar'
// import Footer from '../directives/footer'
import axios from 'axios'
import config from '../config/config'
import toast, { Toaster } from 'react-hot-toast';
import Cookies from 'js-cookie';
import 'react-toastify/dist/ReactToastify.css';
import moment from "moment";
const headers = {
    'Content-Type': 'application/json'
};

export default class Profile extends Component {

    constructor(props) {
        super(props)
        this.state = {
            first_name: '',
            last_name: '',
            phone_number: '',
            dob: '',
            gender: '',
            city: '',
            state: '',
            address: '',
        }
        this.loginData = (!Cookies.get('loginSuccesGarryToken')) ? [] : JSON.parse(Cookies.get('loginSuccesGarryToken'));
    }

    componentDidMount() {
        this.getUserProfileAPI();
    }


    async getUserProfileAPI() {

        await axios.post(`${config.apiUrl}getUserProfile`, { 'email': this.loginData?.data.email },)
            .then(async response => {
                if (response.data.success === true) {
                    await this.setState({
                        id: response.data.response.id,
                        // email: response.data.response.email,
                        first_name: response.data.response.first_name,
                        last_name: response.data.response.last_name,
                        phone_number: response.data.response.phone_number,
                        dob: response.data.response.dob,
                        gender: response.data.response.gender,
                        city: response.data.response.city,
                        address: response.data.response.address,
                        profile_pic: (response.data.response.profile_pic ? config.apiUrl + response.data.response.profile_pic : 'img/avatar.png'),
                        status:response.data.response.status
                    })
                    if (response.data.response.country_id != '' && response.data.response.state_id) {
                        this.getUserStateAPI(response.data.response.country_id, response.data.response.state_id);
                    }
                }
                else if (response.data.success === false) {
                }
            })
    }

    async getUserStateAPI(country_id, state_id) {
        await axios.post(`${config.apiUrl}getState`, { 'country_id': country_id },)
            .then(async response => {
                if (response.data.success === true) {
                    let allData = response.data.response;
                    var state_data = allData.filter(function (el) {
                        return el.id == state_id
                    });
                    await this.setState({
                        state: state_data[0].name
                    })
                }
            })
    }


    render() {
        return (
            <div>

                <div className="container-xxl position-relative bg-white d-flex p-0">
                    <Leftsidebar />
                    {/* Content Start */}
                    <div className="content">
                        <Header />

                        <div className='row'>
                            <div className='editpro'>
                                <a href={`${config.baseUrl}updateProfile`} className='editpen'>
                                    Edit
                                    <span><img src="icons/editpencil.png" alt="editpencil" id="" /></span>
                                </a>
                            </div>
                            <h5 class="blog" id="blogsidone">User Profile</h5>
                            <div className="userprofileblock mb-0">
                                <img id="userpro" src={this.state.profile_pic} alt=""
                                    style={{ width: 40, height: 40 }}
                                />
                                {console.log('>>>>>>>>', this.state.status)}
                                <a href="#" className="user-inner">
                                    <img src={this.state.profile_pic} alt="eidtimg" id="editid" />
                                    {this.state.status == 1 ? 
                                    <>
                                    <p>Verified</p>
                                    <span><img src="icons/verified-svgrepo-com 1.png" alt="verified-svgrepo-com 1" /></span>
                                    </>
                                    :''}
                                </a>
                                {/* <div className="input-file-container">
                                <input className="input-file" id="my-file" type="file" />
                                <label tabIndex={0} htmlFor="my-file" className="input-file-trigger">
                                <img src="icons/photo-file-svgrepo-com 1.png" alt="photo-file-svgrepo-com 1" className="uploadinput" />
                                Upload
                                </label>
                            </div> */}
                            </div>


                            <div className="accordion mt-0" id="accordionExample">
                                <div className='row'>
                                    <div className='payinner'>
                                        <div className='edditinner'>
                                            <ul>
                                                <li>
                                                    <a href='javascript:;'>
                                                        <div className='innerveri'>
                                                            <span><img src="icons/verifi1.png" alt="verifi1" id="verifyimgid" /></span>
                                                            <p>{this.state.first_name} {this.state.last_name}</p>
                                                        </div>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href='javascript:;'>
                                                        <div className='innerveri'>
                                                            <span><img src="icons/verifi2.png" alt="verifi2" id="verifyimgid" /></span>
                                                            {this.state.phone_number ? 
                                                            <p>+{this.state.phone_number}</p>
                                                            :''}
                                                        </div>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href='javascript:;'>
                                                        <div className='innerveri'>
                                                            <span><img src="icons/verifi3.png" alt="verifi3" id="verifyimgid" /></span>
                                                            <p>{moment(this.state.dob).format("DD-MMMM-YYYY")}</p>
                                                        </div>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href='javascript:;'>
                                                        <div className='innerveri'>
                                                            <span><img src="icons/verifi4.png" alt="verifi4" id="verifyimgid" /></span>
                                                            <p>
                                                                {this.state.gender == null || this.state.gender == 'null' ?
                                                                    ''
                                                                    :
                                                                    this.state.gender == '0' ? 'Male'
                                                                        :
                                                                        'Female'

                                                                }
                                                            </p>
                                                        </div>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href='javascript:;'>
                                                        <div className='innerveri'>
                                                            <span><img src="icons/verifi5.png" alt="verifi5" id="verifyimgid" /></span>
                                                            <p>{this.state.city}</p>
                                                        </div>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href='javascript:;'>
                                                        <div className='innerveri'>
                                                            <span><img src="icons/verifi6.png" alt="verifi6" id="verifyimgid" /></span>
                                                            <p>{this.state.state}</p>
                                                        </div>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href='javascript:;'>
                                                        <div className='innerveri'>
                                                            <span><img src="icons/verifi7.png" alt="verifi7" id="verifyimgid" /></span>
                                                            <p>{this.state.address}</p>
                                                        </div>
                                                    </a>
                                                </li>
                                                {/* <li>
                                                    <a href='javascript:;'>
                                                        <div className='innerveri'>
                                                            <span><img src="icons/verifi8.png" alt="verifi8" id="verifyimgid" /></span>
                                                            <p>National ID Card</p>
                                                        </div>
                                                    </a>
                                                </li> */}
                                                {/* <li><a href='#'>
                                            <span><img src="icons/verifi2.png" alt="verifi2" id="verifyimgid" /></span>
                                                +234 9087856652
                                                </a>
                                            </li>
                                            <li><a href='#'>
                                                <span><img src="icons/verifi3.png" alt="verifi3" id="verifyimgid" /></span>
                                                22 - March - 1985
                                                </a>
                                            </li>
                                            <li><a href='#'>
                                                <span><img src="icons/verifi4.png" alt="verifi4" id="verifyimgid" /></span>
                                                Male
                                                </a>
                                            </li>
                                            <li><a href='#'>
                                                <span><img src="icons/verifi5.png" alt="verifi5" id="verifyimgid" /></span>
                                                Maitama
                                                </a>
                                            </li>
                                            <li><a href='#'>
                                                <span><img src="icons/verifi6.png" alt="verifi6" id="verifyimgid" /></span>
                                                Abuja
                                                </a>
                                            </li>
                                            <li><a href='#'>
                                                <span><img src="icons/verifi7.png" alt="verifi7" id="verifyimgid" /></span>
                                                12 Cresent Avenue Maitama FCT Abuja
                                                </a>
                                            </li>
                                            <li><a href='#'>
                                                <span><img src="icons/verifi8.png" alt="verifi8" id="verifyimgid" /></span>
                                                National ID Card
                                                </a>
                                            </li> */}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );

    }
}
