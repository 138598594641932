import React, { Component } from 'react';
import Header from '../directives/header'
import Footer from '../directives/footer'
import Leftsidebar from '../directives/leftsidebar'
// import Footer from '../directives/footer'
import axios from 'axios'
import config from '../config/config'
import toast, { Toaster } from 'react-hot-toast';
import 'react-toastify/dist/ReactToastify.css';
import Cookies from 'js-cookie';
import moment from "moment";

const headers = {
  'Content-Type': 'application/json'
};

export default class transactionsList extends Component {

  constructor(props) {
    super(props)
    this.state = {
      transactions: [],
    }

    this.loginData = (!Cookies.get('loginSuccesGarryToken')) ? [] : JSON.parse(Cookies.get('loginSuccesGarryToken'));
  }

  componentDidMount() {
    this.getTransactions();
    this.trxType = window.location.pathname.split("/").pop()
    document.getElementById("transactions").classList.add("active");
  }

  async getTransactions() {
    let obj = {
      user_id: this.loginData.data.id
    }
    axios.post(`${config.apiUrl}getMyAllTransactions`, obj, { headers })
      .then(response => {
        if (response.data.success === true) {
          // this.setState({
          //   transactions: response.data.response,
          //   allTransactions: response.data.response
          // })

          let allData = response.data.response;
          var trx_type = '';
          if (this.trxType == 'pending') {
            var transactionsData = allData.filter(function (el) {
              return el.status == 0
            });
            trx_type = 'Pending';
          }
          else if (this.trxType == 'success') {
            var transactionsData = allData.filter(function (el) {
              return el.status == 1
            });
            trx_type = 'Successful';
          }
          else if (this.trxType == 'failed') {
            var transactionsData = allData.filter(function (el) {
              return el.status == 2
            });
            trx_type = 'Failed';
          }
          // console.log(selected_card)
          this.setState({
            transactions: transactionsData,
            trx_type: trx_type
          })
        }
      })
      .catch(err => {
        // toast.error(err.response.data?.msg);
      })
  }

  async fiterTransactions(trx_status) {
    let allData = this.state.allTransactions;
    var transactions = allData.filter(function (el) {
      return el.status == trx_status
    });
    // console.log(selected_card)
    await this.setState({
      transactions: transactions
    })
  }

  render() {
    return (
      <div>

        <div className="container-xxl position-relative bg-white d-flex p-0">
          <Leftsidebar />
          {/* Content Start */}
          <div className="content">
            <Header />

            <div className="row" id="recentt">
              {/* <h5 class="johndash">John Doe  /  Add card</h5> */}
              <div className="col-lg-4 col-md-4 col-12 m-0 p-0">
                <div id="january_recent">
                  <div className="bg-light" id="recntbg">
                    <div
                      className="d-flex align-items-center justify-content-between mb-4"
                      id="maintrnsid" >
                      {/* <h6 className="mb-0 clalehaed" id="transid">
                        Transaction
                        </h6> */}
                      <h6 className="mb-0 clalehaed" id="recenttransid">
                        <center> {this.state.trx_type} Transactions</center>
                      </h6>
                      {/* <a href="/gerry_token/transactions" className="showanker">
                        See All
                        </a> */}
                    </div>
                    <table className="tablecolor" id="recentableid">
                      <tbody>
                        {this.state.transactions.length ?
                          this.state.transactions.map((item, i) =>
                            <tr>
                              <td className="imgborder">
                                <button type="button" className="btn btn-dark m-2 green">{moment(item.datetime).format("MMM").toUpperCase()}</button>
                              </td>
                              <td className="textcolor">
                                GARRI {item.garri_token_amount}
                                <p className="date">{moment(item.datetime).format("DD/MM/YYYY")}</p>
                              </td>
                              <td>
                                {item.currency_name} {item.currency_amount}
                                {item.status == 0 ? <p className="pendingbtn">Pending</p> : ''}
                                {item.status == 1 ? <p className="successbtn">Success</p> : ''}
                                {item.status == 2 ? <p className="failledbtn">Failed</p> : ''}
                              </td>
                            </tr>
                          )
                          :
                          <tr>
                            <td colSpan={3}>
                              <center>No Transaction Found.</center>
                            </td>
                          </tr>
                        }

                      </tbody>
                    </table>
                  </div>
                </div>

                {/* <div id="january_recent">
                    <div className="bg-light" id="recntbg">
                    <div
                        className="d-flex align-items-center justify-content-between mb-4"
                        id="maintrnsid"
                    >
                        <h6 className="mb-0 clalehaed" id="transid">
                        Transaction
                        </h6>
                        <h6 className="mb-0 clalehaed" id="recenttransid">
                        Older 
                        </h6>
                        
                    </div>
                    <table className="tablecolor" id="recentableid">
                        <tbody>
                        <tr>
                            <td className="imgborder">
                            <button type="button" className="btn btn-dark m-2 green">
                            MAR
                            </button>
                            </td>
                            <td className="textcolor">
                            GTN 01.937635365<p className="date">12/3/2022</p>
                            </td>
                            <td>
                            NGN 30,000<p className="pendingbtn">Pending</p>
                            </td>
                        </tr>
                        <tr>
                            <td className="imgborder">
                            <button type="button" className="btn btn-dark m-2 green">
                            JAN
                            </button>
                            </td>
                            <td className="textcolor">
                            GTN 01.937635365<p className="date">12/4/2022</p>
                            </td>
                            <td>
                            NGN 30,000<p className="pendingbtn">Success</p>
                            </td>
                        </tr>
                        <tr>
                            <td className="imgborder">
                            <button type="button" className="btn btn-dark m-2 green">
                            FEB
                            </button>
                            </td>
                            <td className="textcolor">
                            GTN 01.937635365<p className="date">10/2/2022</p>
                            </td>
                            <td>
                            NGN 50,000<p className="pendingbtn">Success</p>
                            </td>
                        </tr>
                        <tr>
                            <td className="imgborder">
                            <button type="button" className="btn btn-dark m-2 green">
                            FEB
                            </button>
                            </td>
                            <td className="textcolor">
                            GTN 01.937635365<p className="date">20/2/2022</p>
                            </td>
                            <td>
                            NGN 10,000<p className="pendingbtn">Failed</p>
                            </td>
                        </tr>
                        <tr>
                            <td className="imgborder">
                            <button type="button" className="btn btn-dark m-2 green">
                            MAR
                            </button>
                            </td>
                            <td className="textcolor">
                            GTN 01.937635365<p className="date">12/3/2022</p>
                            </td>
                            <td>
                            NGN 30,000<p className="pendingbtn">Pending</p>
                            </td>
                        </tr>
                       
                        </tbody>
                    </table>
                    </div>
                </div> */}



              </div>

            </div>



          </div>
        </div>
        <Footer />
      </div>
    );

  }
}