import React, { Component } from 'react';
import axios from 'axios';
import config from '../config/config'
import toast, { Toaster } from 'react-hot-toast';
import Cookies from 'js-cookie';
import 'react-toastify/dist/ReactToastify.css';
const headers = {
    'Content-Type': 'application/json'
};

export default class Login extends Component {
    constructor(props) {
        super(props)
        this.state = {
            description: ''
        }
        if (Cookies.get('loginSuccesGarryToken')) {
            window.location = `${config.baseUrl}dashboard`;
        }
    }
    componentDidMount() {
        this.getTermsAndCondition();
    }

    async getTermsAndCondition() {
        axios.get(`${config.apiUrl}getTermsAndCondition`)
            .then(response => {
                if (response.data.success === true) {
                    this.setState({
                        title: response.data.response.title,
                        description: response.data.response.description
                    })
                }
            })
    }

    render() {
        return (

            <section className="login" id='logisnid'>
                <img src="img/loginmobile.png" alt="loginmobile" id="loginmobile"></img>
                <div className="inner-img" id="innerimgid">
                    
                    <img src="img/backimg.png" alt="backimg" />
                </div>
                <Toaster
                        position="top-right"
                        reverseOrder={false}
                    />
                <div className="container">
                    <div className="row login-content">
                        <div className="col-lg-8 col-md-8">
                            <div className="inner-login" id='logindid'>
                            <div className="bankimgblock">
                                <img src="icons/gcoin.png" alt="gcoin" className id="gcoin" />
                            </div>
                                <h1>Garri Swap - Terms & Conditions</h1>
                                <p>
                                  {this.state.description}
                                </p>
                              <hr></hr>
                                
                              <div className="loginbtm form-group text-center">
                                <p><a href={`${config.baseUrl}signup`}>Back to Sign Up</a></p>
                                </div>
                                <br></br>
                                <br></br>
                                <br></br>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}