import React, { Component } from 'react';
// import { config } from 'yargs';
import Header from '../directives/header'
import Leftsidebar from '../directives/leftsidebar'
import Footer from '../directives/footer'
import axios from 'axios'
import config from '../config/config'
import toast, { Toaster } from 'react-hot-toast';
import 'react-toastify/dist/ReactToastify.css';
import Cookies from 'js-cookie';
import moment from "moment";
import Web3 from 'web3';
import Websocket from 'react-websocket';

import CanvasJSReact from '../canvasjs.react';
// import { web } from 'webpack';
//var CanvasJSReact = require('./canvasjs.react');
var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

const headers = {
    'Content-Type': 'application/json'
};
export default class Dashboard extends Component {


    constructor(props) {
        super(props)
        this.state = {
            currency_list: [],
            blog_list: [],
            currency: 3,
            currency_amount: 0,
            garri_amount: 0,
            transactions: [],
            one_USD_to_NGN: 0,
            ngnusdtPrice: 0,
            busdtousd: 0,
            bnbtousd: 0,
            USD_in_one_GARRI:0,
            GARRI_in_one_USD: 0,
            please_wait_btn : false,
            my_pair_list: [
                { 'pair': 'BTCUSDT', 'image': 'icons/bitcoin1.png', 'name': 'BITCOIN', 'symbol': 'BTC' },
                { 'pair': 'ETHUSDT', 'image': 'icons/ethereum 1.png', 'name': 'ETHRIUM', 'symbol': 'ETH' },
                { 'pair': 'BNBUSDT', 'image': 'icons/bnb-svgrepo-com 1.png', 'name': 'BNB', 'symbol': 'BNB' },
                { 'pair': 'USDT', 'image': 'icons/usdt-svgrepo-com 1.png', 'name': 'USDT', 'symbol': 'USDT' },
                { 'pair': 'XRPUSDT', 'image': 'icons/xrpicons.png', 'name': 'XRP', 'symbol': 'XRP' },
                { 'pair': 'DASHUSDT', 'image': 'icons/dash-svgrepo-com 1.png', 'name': 'DASHCOIN', 'symbol': 'DASH' },
                { 'pair': 'DOGEUSDT', 'image': 'icons/dogecoin 1.png', 'name': 'DOGECOIN', 'symbol': 'DOGE' }
            ],
            graphDataPoints: []
        }
        this.onChange = this.onChange.bind(this);
        this.handleChangeNumber = this.handleChangeNumber.bind(this);
        this.submitForm = this.submitForm.bind(this);
        this.loginData = (!Cookies.get('loginSuccesGarryToken')) ? [] : JSON.parse(Cookies.get('loginSuccesGarryToken'));


    }

    async componentDidMount() {
        this.getCurrency();
        this.getMyRecentTransactions();
        this.getBusdPriceAPI();
        this.getNgnUsdtPriceAPI();
        this.getGraphData();
        this.getLatestBlogs();
        document.getElementById("dashboard").classList.add("active");
        await this.get_admin_setting();
        await this.get_garri_live_price();
        setInterval(async () => {
            await this.get_garri_live_price();
        }, 20000);
    }

    async get_garri_live_price() {
        console.log('>>>>>>>>>>>>>>>>>>>>>>>>>>>');
        // Get live price from api or contract is here //
        let web3 = new Web3('https://bsc-dataseed1.binance.org/');
        let BNBcontractAddress = `${config.BNB_Contract}`
        let GARRIcontractAddress = `${config.GARRI_Contract}`
        let BNB_GARRI_PAIR_Address = `${config.BNB_GARRI_PAIR_Address}`
        let contractABI = config.ERC20_ABI;
        const BNBcontract = await new web3.eth.Contract(contractABI, BNBcontractAddress);
        const GARRIcontract = await new web3.eth.Contract(contractABI, GARRIcontractAddress);


        let BNB_balance = await BNBcontract.methods.balanceOf(BNB_GARRI_PAIR_Address).call()
        let GARRI_balance = await GARRIcontract.methods.balanceOf(BNB_GARRI_PAIR_Address).call()
        let ttl_BNB = (parseFloat(BNB_balance) / 10 ** 18).toFixed(8);
        let ttl_GARRI = (parseFloat(GARRI_balance) / 10 ** 18).toFixed(8);
        let GARRI_in_one_BNB = parseFloat(ttl_BNB / ttl_GARRI).toFixed(8);
        console.log('GARRI in one BNB => ', GARRI_in_one_BNB)
        console.log('USDT in one BNB => ', this.state.bnbtousd)
        let USD_in_one_GARRI = GARRI_in_one_BNB * this.state.bnbtousd;
        console.log('USDT in one GARRI => ', USD_in_one_GARRI);
        let GARRI_in_one_USD = (1 / USD_in_one_GARRI).toFixed(8);
        console.log('[1] GARRI in one USDT => ', GARRI_in_one_USD);
        await this.setState({
            GARRI_in_one_USD: GARRI_in_one_USD,
            USD_in_one_GARRI:USD_in_one_GARRI
        })
        this.get_garri_amount();
    }

    async get_garri_amount() {

        let one_USD_to_NGN = this.state.one_USD_to_NGN;
        let currency = this.state.currency;
        let entered_amount = this.state.currency_amount;


        let GARRI_in_one_USD = this.state.GARRI_in_one_USD;
        console.log('[2] GARRI in one USDT => ', GARRI_in_one_USD);
        let garri_amount = 0;
        if (currency == 1) // USDT
        {
            let one_USDT_to_USD = entered_amount / 1;  // 1 is usd value
            garri_amount = one_USDT_to_USD * GARRI_in_one_USD;
        }
        else if (currency == 2)  // BUSD
        {
            let one_BUSD_to_USD = entered_amount / this.state.busdtousd;
            garri_amount = one_BUSD_to_USD * GARRI_in_one_USD;
        }
        else if (currency == 3)  // NGN
        {
            let one_NGN_to_USD = entered_amount / one_USD_to_NGN;   /// 1000/500 == 2USD
            garri_amount = one_NGN_to_USD * GARRI_in_one_USD; // 2*1 = 2 GARRI
        }
        await this.setState({
            garri_amount: (garri_amount ? garri_amount : 0).toFixed(6)
        })
        console.log('Finally GARRI token amount => ', this.state.garri_amount);
    }

    async onChange(e) {
        await this.setState({
            [e.target.name]: e.target.value
        })
        this.get_garri_amount();
    }

    async handleChangeNumber(event) {
        const re = /^[0-9/.]+$/;
        if (event.target.value === '' || re.test(event.target.value)) {
            await this.setState({
                [event.target.name]: event.target.value
            })
            this.get_garri_amount();
        }
    }
    async getCurrency() {
        axios.get(`${config.apiUrl}getCurrency`)
            .then(response => {
                if (response.data.success === true) {
                    this.setState({
                        currency_list: response.data.response
                    })
                }
            })
    }

    async getLatestBlogs() {
        axios.get(`${config.apiUrl}getLatestBlogs`)
            .then(response => {
                if (response.data.success === true) {
                    this.setState({
                        blog_list: response.data.response
                    })
                }
            })
    }

    async get_admin_setting() {
        await axios.get(`${config.apiUrl}get_admin_setting`, { headers })
            .then(response => {
                if (response.data.success === true) {
                    this.setState({
                        one_USD_to_NGN: response.data.data.one_USD_to_NGN,
                    })
                }
            })
    }

    async submitForm(e) {
        e.preventDefault();
        if (this.state.currency_amount == '') {
            toast.error('Currency amount required.');
            return;
        }
        else if (this.state.currency_amount == 0) {
            toast.error('Currency amount must be greater then 0');
            return;
        }
        
        let usd_amount = 0;
        if (this.state.currency == 1) {
            usd_amount = this.state.currency_amount * 1;
        }
        else if (this.state.currency == 2) {
            usd_amount = this.state.currency_amount * this.state.busdtousd;
        }
        else if (this.state.currency == 3) {
            usd_amount = this.state.currency_amount * this.state.ngnusdtPrice;
            // if(this.state.currency_amount < 5000)
            // {
            //     toast.error('Currency amount must be greater then 5000');
            //     return;
            // }
        }
        this.setState({
            please_wait_btn: true
        })
        let obj = {
            user_id: this.loginData.data.id,
            currency: this.state.currency,
            currency_amount: this.state.currency_amount,
            garry_token_amount: this.state.garri_amount,
            fees: this.state.fees,
            usd_amount: usd_amount,
        }
        axios.post(`${config.apiUrl}insertTemporeryData`, obj, { headers })
            .then(response => {
                if (response.data.success === true) {
                    setTimeout(() => {
                        window.location.href = config.baseUrl + 'paymentOptions'
                    }, 300);
                }
                else if (response.data.success === false) {
                    toast.error(response.data.msg);
                }
            })
            .catch(err => {
                toast.error(err.response.data?.msg);
            })
    }

    async getMyRecentTransactions() {
        let obj = {
            user_id: this.loginData.data.id
        }
        axios.post(`${config.apiUrl}getMyRecentTransactions`, obj, { headers })
            .then(response => {
                if (response.data.success === true) {
                    this.setState({
                        transactions: response.data.response
                    })
                }
            })
            .catch(err => {
                // toast.error(err.response.data?.msg);
            })
    }


    async livePairDataFromBinance(socketData) {

        socketData = JSON.parse(socketData);

        if (this.state.my_pair_list.length > 0) {
            let coinList1 = this.state.my_pair_list;
            // console.log(coinList1)
            var i = 0;
            for (i = 0; i < coinList1.length; i++) {
                var item = coinList1[i];

                var si = socketData.findIndex(el => el['s'] === item.pair);
                // console.log(si)
                if (si > -1) {
                    coinList1[i].livePrice = parseFloat(socketData[si].c).toFixed(4);
                    coinList1[i].changePercantage = parseFloat(socketData[si].P).toFixed(4);
                }
                if (item.pair == "USDT") {
                    coinList1[i].livePrice = 80.59;
                    coinList1[i].changePercantage = 0.00;
                }
            }


            this.setState({
                my_pair_list: coinList1
            })

        }
    }

    async getBusdPriceAPI() {
        await axios({
            method: 'get',
            url: `https://api.binance.com/api/v3/ticker/price?symbol=BUSDUSDT`,
        }).then(response => {
            this.setState({
                busdtousd: response.data.price,
                // usdtobnb: 1 / response.data.price
            })
        })

        await axios({
            method: 'get',
            url: `https://api.binance.com/api/v3/ticker/price?symbol=BNBUSDT`,
        }).then(response => {
            this.setState({
                bnbtousd: response.data.price,
                // usdtobnb: 1 / response.data.price
            })
        })
    }

    async getNgnUsdtPriceAPI() {
        await axios({
            method: 'get',
            url: `https://api.coingecko.com/api/v3/simple/price?ids=tether&vs_currencies=ngn`,
        }).then(response => {
            this.setState({
                ngnusdtPrice: (1 / response.data.tether.ngn).toFixed(6),
            })
        })
    }

    async getGraphData() {
        let obj = {
            user_id: this.loginData.data.id
        }
        axios.post(`${config.apiUrl}getGraphData`, obj, { headers })
            .then(response => {
                if (response.data.success === true) {
                    let data = response.data.response;
                    let gdataPoints = [];
                    for (var i = 0; i < data.length; i++) {
                        gdataPoints.push({ x: new Date(data[i].month), y: data[i].usd_amount });
                    }
                    this.setState({
                        graphDataPoints: gdataPoints
                    })
                }
            })
            .catch(err => {
                // toast.error(err.response.data?.msg);
            })
    }


    render() {

        const dataPoints = this.state.graphDataPoints;
     
        const options = {
            animationEnabled: true,
            title: {
                // text: "Monthly history"
            },
            axisY: {
                title: "Amount (USD)",
                prefix: "$"
            },
            data: [{
                type: "splineArea",
                xValueFormatString: "MMMM-YYYY",
                yValueFormatString: "$#,##0.##",
                showInLegend: true,
                legendText: "Months",
                dataPoints: dataPoints
            }]
        }
        return (
            <div>

                <div className="">
                    <Leftsidebar />
                    {/* Content Start */}
                    <div className="content">
                        <Header />

                        <Websocket url='wss://stream.binance.com:9443/ws/!ticker@arr'
                            onMessage={this.livePairDataFromBinance.bind(this)} />

                        <div className="row" id="johndashid">
                            {/* <div className="col-lg-4 col-md-4 col-12">
                                <div className="cardimgs1">
                                <img src="img/cards.png" alt="cards" />
                                </div>
                            </div> */}
                            <div className="col-lg-12 col-md-12 col-12 m-0 p-0">
                            <h5 className="johndash">Dashboard</h5>
                                <div className="conversion">
                                    <div className="converdiv">
                                        <span><img src="icons/error.png" alt="error" className="error" /></span>
                                        <h4 className='converdexk'>Conversion Desk</h4>
                                        <h4 className='buytoken'>Buy Token</h4>
                                    </div>
                                    {/* <h5>Amount</h5> */}
                                    <div className="innerconver">
                                        <form action="#" className="mainform">
                                            <div className="innbox">
                                            </div>
                                            <div id='buytoknid'>
                                                <div className="form-group" id='buytokleft'>

                                                    <i class="fa fa-angle-up" aria-hidden="true"></i>

                                                    <select class="custom-select" id='selectdrop' name="currency" value={this.state.currency} onChange={this.onChange}>
                                                        {this.state.currency_list.map((item, i) =>
                                                            <option value={item.id}>{item.asset_id}</option>
                                                        )}
                                                    </select>

                                                </div>
                                                <div className="form-group" id='buytokright'>
                                                    {/* <label>Amount</label> */}
                                                    <input type="text" className="form-control" id='currency_amount' name='currency_amount' value={this.state.currency_amount} onChange={this.handleChangeNumber} autoComplete='off' />
                                                    <p>Today Rate {moment().format("DD/MM/YYYY")}</p>
                                                    <p>{moment().format("hh:mm")} GMT</p>
                                                </div>
                                            </div>

                                            <div className="convertdiv">
                                                <img src="icons/convert_btn.png" alt="convert_btn" className="covertimg" id='convert_btn1' />
                                                <img src="icons/convert_btn2.png" alt="convert_btn" className="covertimg" id='convert_btn2' />
                                            </div>

                                            <div id='buytoknid'>
                                                <div className="form-group" id='buytokleft'>

                                                    <i class="fa fa-angle-up" aria-hidden="true"></i>

                                                    <select class="custom-select" id='selectdrop'>
                                                        {/* <i class="fa-solid fa-angle-up"></i> */}
                                                        <option>GARRI</option>
                                                    </select>

                                                </div>
                                                <div className="form-group" id='buytokright'>
                                                    {/* <label>Amount</label> */}
                                                    <input type="text" className="form-control" id='currency_amount' name='garri_amount' value={this.state.garri_amount} onChange={this.handleChangeNumber} autoComplete='off' />
                                                    {/* <p>Today Rate {moment().format("DD/MM/YYYY")}</p>
                                                    <p>{moment().format("hh:mm")} GMT</p> */}
                                                </div>
                                            </div>

                                        </form>
                                        <div className="buygaribtn">
                                            {this.state.please_wait_btn ? 
                                                <button type='button' className="buygaributton" >Please wait....</button>
                                            :
                                            <>
                                            <button type='submit' onClick={this.submitForm} className="buygaributton" id='burgaribtn'>Buy Garri</button>
                                            <button type='submit' onClick={this.submitForm} className="buygaributton" id='Purchasebtn'>Purchase</button>
                                            </>
                                        }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="col-lg-4 col-md-4 col-12">
                                <div className="howmuch">
                                    <a href="#" class="addcards2" data-bs-toggle="modal" data-bs-target="#myModal">Connet Wallet</a>
                                </div>
                            </div> */}
                        </div>
                        <br/>
                        <div className="row" id="januaryid">
                            <div className="col-lg-4 col-md-4 col-12">
                                <div id="january">
                                    <div className="bg-light" id="recntbg">
                                        <div className="d-flex align-items-center justify-content-between mb-4" id="maintrnsid">
                                            <h6 className="mb-0 clalehaed" id="transid">Transaction</h6>
                                            <h6 className="mb-0 clalehaed" id="recenttransid">Recent Transaction</h6>
                                            <a href={`${config.baseUrl}transactions`} className="showanker">See All</a>
                                        </div>
                                        {/* <div id="calender"></div> */}
                                        <table className="tablecolor mb-0" id="recentableid">
                                            <tbody>
                                                {this.state.transactions.length ?
                                                    this.state.transactions.map((item, i) =>
                                                        <tr>
                                                            <td className="imgborder">
                                                                <button type="button" className="btn btn-dark m-2 green">{moment(item.datetime).format("MMM").toUpperCase()}</button>
                                                            </td>
                                                            <td className="textcolor">
                                                                GARRI {item.garri_token_amount}
                                                                <p className="date">{moment(item.datetime).format("DD/MM/YYYY")}</p>
                                                            </td>
                                                            <td>
                                                                {item.currency_name} {item.currency_amount}
                                                                {item.status == 0 ? <p className="pendingbtn">Pending</p> : ''}
                                                                {item.status == 1 ? <p className="successbtn">Success</p> : ''}
                                                                {item.status == 2 ? <p className="failledbtn">Failed</p> : ''}
                                                            </td>
                                                        </tr>
                                                    )
                                                    :
                                                    <tr>
                                                        <td colSpan={3}>
                                                            <center>No Transaction Found.</center>
                                                        </td>
                                                    </tr>
                                                }

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-8 col-md-8 col-12 m-0 p-0">
                                {/* {dataPoints.length > 0 ?
                                    <div className="chartbox">
                                        <div>
                                            <CanvasJSChart options={options} />
                                        </div>
                                    </div>
                                    :
                                    ''
                                } */}

                                <div className="main-carousel">
                                    <div className="container m-0 p-0">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="inner-carousel">
                                                    <h4>
                                                        Crypto News
                                                    </h4>
                                                    <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel">
                                                        <div className="carousel-indicators">
                                                            {this.state.blog_list.map((item, i) =>
                                                                <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to={i} className={i == 1 ? `maindost active` : `maindost`} aria-label={`Slide ` + i} />
                                                            )}
                                                        </div>
                                                        
                                                        <div className="carousel-inner">
                                                            {this.state.blog_list.map((item, i) =>
                                                                
                                                                <div className={i == 0 ? `carousel-item active` : `carousel-item`}>
                                                                <a href={`${config.baseUrl}blog/`+item.slug}>
                                                                    <img src={config.apiUrl + item.image} className="d-block w-100" alt="mainbitcion" />
                                                                    <div className="carousel-caption  d-md-block">
                                                                        <h5 style={{fontSize:'17px', color:'#fff'}}>{item.title}</h5>
                                                                    </div>
                                                                    </a>
                                                                </div>
                                                                
                                                                
                                                            )}
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className="row m-0 p-0" id="bitconsid">
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-12" id="coinboxid">
                                        <h4 className='marketpri'>
                                            Market Price
                                        </h4>
                                        <h4 className='coinretet'>
                                            Coins / Token rates
                                        </h4>
                                        <table className="bitcointableid">
                                            <tbody>
                                                 <tr>
                                                        <td><img src="icons/gcoin.png" width="28px" alt="garriswap" className="bitcoinimg" /></td>
                                                        <td className="textcolor">GARRISWAP</td>
                                                        <td>GARRI</td>
                                                        <td className="textcolor">${this.state.USD_in_one_GARRI.toFixed(6)}</td>
                                                        <td className="textcolor2">0.00001%</td>
                                                    </tr>
                                                {this.state.my_pair_list.map((coin_item, i) =>
                                                    <tr>
                                                        <td><img src={coin_item.image} alt="bitcoin1" className="bitcoinimg" /></td>
                                                        <td className="textcolor">{coin_item.name}</td>
                                                        <td>{coin_item.symbol}</td>
                                                        <td className="textcolor">${coin_item.livePrice}</td>
                                                        <td className="textcolor2">{coin_item.changePercantage}%</td>
                                                    </tr>
                                                )}

                                            </tbody>
                                        </table>
                                    </div>

                                </div>


                            </div>
                        </div>
                    </div>
                    {/* Content End */}
                    {/* Connet Wallet Popup */}
                    {/* The Modal */}



                    {/* JavaScript Libraries */}
                    {/* Template Javascript */}
                </div>
                <Footer />
            </div>
        );

    }
}
