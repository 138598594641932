import React, { Component } from 'react';
// import { config } from 'yargs';
import Header from '../directives/header'
import Footer from '../directives/footer'
import Leftsidebar from '../directives/leftsidebar'
// import Footer from '../directives/footer'
import axios from 'axios'
import config from '../config/config'
import toast, { Toaster } from 'react-hot-toast';
import 'react-toastify/dist/ReactToastify.css';
import Cookies from 'js-cookie';
import Web3 from 'web3';
import moment from "moment";
const headers = {
  'Content-Type': 'application/json'
};

export default class PaymentOptions extends Component {


  constructor(props) {
    super(props)
    this.state = {
      currency: '',
      currency_amount: '',
      garri_token_amount: '',
      user_name: '',
      date: '',
      fees: 0,
      payble_amount: 0,
      order_id: '',
      currency_list: [],
      currency: 3,
      // currency_amount: '',
      // garri_amount: '',
      busdtousd: 0,
      bnbtousd: 0,
      GARRI_in_one_USD: 0,
      card_payment_stt: 0,
      bank_payment_stt: 0,
      wallet_payment_stt: 0,
    }
    this.onChange = this.onChange.bind(this);
    this.handleChangeNumber = this.handleChangeNumber.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.loginData = (!Cookies.get('loginSuccesGarryToken')) ? [] : JSON.parse(Cookies.get('loginSuccesGarryToken'));
  }

  async componentDidMount() {
    this.get_current_transaction();
    this.getCurrency();
    this.getBusdPriceAPI();
    this.getNgnUsdtPriceAPI();
    this.getPaymentMethod();
    await this.get_admin_setting();
    await this.get_garri_live_price();
    setInterval(async () => {
      await this.get_garri_live_price();
    }, 20000);
  }

  async get_garri_live_price() {
    console.log('>>>>>>>>>>>>>>>>>>>>>>>>>>>');
    // Get live price from api or contract is here //
    let web3 = new Web3('https://bsc-dataseed1.binance.org/');
    let BNBcontractAddress = `${config.BNB_Contract}`
    let GARRIcontractAddress = `${config.GARRI_Contract}`
    let BNB_GARRI_PAIR_Address = `${config.BNB_GARRI_PAIR_Address}`
    let contractABI = config.ERC20_ABI;
    const BNBcontract = await new web3.eth.Contract(contractABI, BNBcontractAddress);
    const GARRIcontract = await new web3.eth.Contract(contractABI, GARRIcontractAddress);


    let BNB_balance = await BNBcontract.methods.balanceOf(BNB_GARRI_PAIR_Address).call()
    let GARRI_balance = await GARRIcontract.methods.balanceOf(BNB_GARRI_PAIR_Address).call()
    let ttl_BNB = (parseFloat(BNB_balance) / 10 ** 18).toFixed(8);
    let ttl_GARRI = (parseFloat(GARRI_balance) / 10 ** 18).toFixed(8);
    let GARRI_in_one_BNB = parseFloat(ttl_BNB / ttl_GARRI).toFixed(8);
    console.log('GARRI in one BNB => ', GARRI_in_one_BNB)
    console.log('USDT in one BNB => ', this.state.bnbtousd)
    let USD_in_one_GARRI = GARRI_in_one_BNB * this.state.bnbtousd;
    console.log('USDT in one GARRI => ', USD_in_one_GARRI);
    let GARRI_in_one_USD = (1 / USD_in_one_GARRI).toFixed(8);
    console.log('[1] GARRI in one USDT => ', GARRI_in_one_USD);
    await this.setState({
      GARRI_in_one_USD: GARRI_in_one_USD
    })
    this.get_garri_amount();
  }

  async get_garri_amount() {
    // let obj = {
    //     currency: this.state.currency,
    //     currency_amount: (this.state.currency_amount ? this.state.currency_amount : 0)
    // }
    // axios.post(`${config.apiUrl}getCurrencyValue`, obj, { headers })
    //     .then(response => {
    //         if (response.data.success === true) {
    //             this.setState({
    //                 garri_amount: response.data.garri_amount,
    //                 fees: response.data.fees
    //             })
    //         }

    //     })

    let one_USD_to_NGN = this.state.one_USD_to_NGN;
    let currency = this.state.currency;
    let entered_amount = this.state.currency_amount;


    let GARRI_in_one_USD = this.state.GARRI_in_one_USD;
    console.log('[2] GARRI in one USDT => ', GARRI_in_one_USD);
    let garri_amount = 0;
    if (currency == 1) // USDT
    {
      let one_USDT_to_USD = entered_amount / 1;  // 1 is usd value
      garri_amount = one_USDT_to_USD * GARRI_in_one_USD;
    }
    else if (currency == 2)  // BUSD
    {
      let one_BUSD_to_USD = entered_amount / this.state.busdtousd;
      garri_amount = one_BUSD_to_USD * GARRI_in_one_USD;
    }
    else if (currency == 3)  // NGN
    {
      let one_NGN_to_USD = entered_amount / one_USD_to_NGN;   /// 1000/500 == 2USD
      garri_amount = one_NGN_to_USD * GARRI_in_one_USD; // 2*1 = 2 GARRI
    }
    await this.setState({
      garri_amount: (garri_amount ? garri_amount : 0).toFixed(6)
    })
    console.log('Finally GARRI token amount => ', this.state.garri_amount);
  }

  async getPaymentMethod() {

    await axios.post(`${config.apiUrl}getPaymentMethod`, { headers })
      .then(response => {

        if (response.data.success === true) {
          this.setState({
            card_payment_stt: response.data.response[0].status,
            bank_payment_stt: response.data.response[1].status,
            wallet_payment_stt: response.data.response[2].status,
          })
        }
      })
  }

  async onChange(e) {
    await this.setState({
      [e.target.name]: e.target.value
    })
    this.get_garri_amount();
  }

  async handleChangeNumber(event) {
    const re = /^[0-9/.]+$/;
    if (event.target.value === '' || re.test(event.target.value)) {
      await this.setState({
        [event.target.name]: event.target.value
      })
      this.get_garri_amount();
    }
  }
  async getCurrency() {
    axios.get(`${config.apiUrl}getCurrency`)
      .then(response => {
        if (response.data.success === true) {
          this.setState({
            currency_list: response.data.response
          })
        }
      })
      .catch(err => {
        // toast.error(err.response.data?.msg);
      })
  }

  async get_admin_setting() {
    await axios.get(`${config.apiUrl}get_admin_setting`, { headers })
      .then(response => {
        if (response.data.success === true) {
          this.setState({
            one_USD_to_NGN: response.data.data.one_USD_to_NGN,
          })
        }
      })
  }

  async get_current_transaction() {
    let obj = {
      user_id: this.loginData.data.id,
    }
    axios.post(`${config.apiUrl}getTemporaryData`, obj, { headers })
      .then(response => {
        if (response.data.success === true) {
          console.log(response.data);
          if (response.data.response.status == 2) {
            // setTimeout(() => {
            window.location.href = config.baseUrl + 'dashboard';
            // }, 1000)
          }
          this.setState({
            order_id: response.data.response.id,
            currency_name: response.data.response.currency_name,
            currency: response.data.response.currency,
            currency_amount: response.data.response.currency_amount,
            garri_token_amount: response.data.response.garry_token_amount,
            garri_amount: response.data.response.garry_token_amount,
            user_name: response.data.user_name,
            date: moment(response.data.response.date).format("DD/MM/YYYY"),
            fees: 0,
            payble_amount: response.data.response.currency_amount,
          })
        }
        else if (response.data.success === false) {
          window.location.href = config.baseUrl + 'dashboard';
        }
      })
      .catch(err => {
        window.location.href = config.baseUrl + 'dashboard';
      })
  }

  async getBusdPriceAPI() {
    await axios({
      method: 'get',
      url: `https://api.binance.com/api/v3/ticker/price?symbol=BUSDUSDT`,
    }).then(response => {
      this.setState({
        busdtousd: response.data.price,
        // usdtobnb: 1 / response.data.price
      })
    })

    await axios({
      method: 'get',
      url: `https://api.binance.com/api/v3/ticker/price?symbol=BNBUSDT`,
    }).then(response => {
      this.setState({
        bnbtousd: response.data.price,
        // usdtobnb: 1 / response.data.price
      })
    })
  }

  async getNgnUsdtPriceAPI() {
    await axios({
      method: 'get',
      url: `https://api.coingecko.com/api/v3/simple/price?ids=tether&vs_currencies=ngn`,
    }).then(response => {
      this.setState({
        ngnusdtPrice: (1 / response.data.tether.ngn).toFixed(6),
      })

      console.log();
    })
  }

  async submitForm(e) {
    e.preventDefault();
    if (this.state.currency_amount == '') {
      toast.error('Currency amount required.');
      this.get_current_transaction();
      return;
    }
    else if (this.state.currency_amount == 0) {
      toast.error('Currency amount must be greater then 0');
      this.get_current_transaction();
      return;
    }
    let usd_amount = 0;
    if (this.state.currency == 1) {
      usd_amount = this.state.currency_amount * 1;
    }
    else if (this.state.currency == 2) {
      usd_amount = this.state.currency_amount * this.state.busdtousd;
    }
    else if (this.state.currency == 3) {
      usd_amount = this.state.currency_amount * this.state.ngnusdtPrice;
    }
    let obj = {
      order_id: this.state.order_id,
      currency: this.state.currency,
      currency_amount: this.state.currency_amount,
      garry_token_amount: this.state.garri_amount,
      fees: this.state.fees,
      usd_amount: usd_amount,
    }
    axios.post(`${config.apiUrl}updateTempararyOrder`, obj, { headers })
      .then(response => {
        if (response.data.success === true) {
          toast.success('Order updated!');
          setTimeout(() => {
            window.location.href = config.baseUrl + 'paymentOptions'
          }, 1000);
        }
        else if (response.data.success === false) {
          toast.error(response.data.msg);
        }
      })
      .catch(err => {
        toast.error(err.response.data?.msg);
      })
  }


  async cancelThisOrder() {
    let obj = {
      order_id: this.state.order_id,
    }
    await axios.post(`${config.apiUrl}cancelThisOrder`, obj, { headers })
      .then(response => {
        if (response.data.success === true) {
          // toast.success("Your order has been cancelled.", {});
          setTimeout(() => {
            window.location.href = config.baseUrl + 'dashboard';
          }, 1000)
        }
      })
      .catch(err => {
        // toast.error(err.response.data?.msg);
        window.location.href = config.baseUrl + 'dashboard';
      })
  }

  render() {
    return (
      <div style={{ overflowX: "hidden" }}>

        <div className="">
          <Leftsidebar />
          {/* Content Start */}
          <div className="content">
            <Header />

            <div className="row" id="paymentmth">
              <div className="main-content" id='p_optionid'>
                <div id="wizardmethod">

                  <div className="tophead" id="selectpmethed">
                    <h4>Select Payment Method
                    </h4>
                    <a className='btn btn-danger btn-sm' href='javascript:history.back()' style={{ float: 'right', marginRight: '55px' }}> &nbsp; Back &nbsp;</a>
                  </div>
                  {/* <section class="signup-step-container">
            <div class="container">
            <div class="row d-flex justify-content-center">
                <div class="col-md-8">
                    <div class="wizard" id="wizardid">
                        <div class="wizard-inner">
                            <div class="connecting-line"></div>
                            <ul class="nav nav-tabs" role="tablist">
                                <li role="presentation" class="active">
                                    <a href="payment_option.html" data-toggle="tab" aria-controls="step1" role="tab" aria-expanded="true"><span class="round-tab">1 </span> <i>Step 1</i></a>
                                </li>
                                <li role="presentation" class="disabled">
                                    <a href="cardpayment_option_purchase_flow.html" data-toggle="tab" aria-controls="step2" role="tab" aria-expanded="false"><span class="round-tab">2</span> <i>Step 2</i></a>
                                </li>
                                <li role="presentation" class="disabled">
                                    <a href="cardpayment_option_purchase_flow.html" data-toggle="tab" aria-controls="step3" role="tab"><span class="round-tab">3</span> <i>Step 3</i></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section> */}
                  <section className="signup-step-container mt-5">
                    <div className="container m-0 p-0">
                      <div className="row d-flex justify-content-center">
                        <div className="col-12">


                          <div className="wizard" id="wizardmain">
                            <div className="wizard-inner">
                              <div className="connecting-line" />
                              <ul className="nav nav-tabs" role="tablist">
                                <li role="presentation" className="active">
                                  <a href="" data-toggle="tab" aria-controls="step1" role="tab" aria-expanded="true"><span className="round-tab">1 </span> <i>Step 1</i></a>
                                </li>
                                <li role="presentation" className="disabled">
                                  <a href="javascript:void(0)" data-toggle="tab" aria-controls="step2" role="tab" aria-expanded="false"><span className="round-tab">2</span> <i>Step 2</i></a>
                                </li>
                                <li role="presentation" className="disabled">
                                  <a href="javascript:void(0)" data-toggle="tab" aria-controls="step3" role="tab"><span className="round-tab">3</span> <i>Step 3</i></a>
                                </li>
                                {/* <li role="presentation" class="disabled">
                                    <a href="#step4" data-toggle="tab" aria-controls="step4" role="tab"><span class="round-tab">4</span> <i>Step 4</i></a>
                                </li> */}
                              </ul>
                            </div>



                            <form className="msform" method="post">
                              {/* progressbar */}
                              <ul className="progressbar">
                                <li></li>
                                <li></li>
                                <li></li>
                              </ul>

                            </form>


                            <br></br>
                            <div className="paymentdiv" id="paymentdivid">
                              <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                <div className="paymentdivleft">
                                  <div className="innerpay">
                                    {this.state.currency_name == 'NGN' ?
                                      <>
                                        {this.state.card_payment_stt == 1 ?
                                          <>
                                            <a href={`${config.baseUrl}payWithCard`} className="cardsmall">
                                              <div className="cardpayblock">
                                                <div className="cardspaybox1">
                                                  <img src="icons/credit-card-svgrepo-com 1.png" alt="credit-card-svgrepo-com 1" />
                                                  <h5>Card payment</h5>
                                                </div>
                                                <div className="cardpaytext">
                                                  <h4>Pay with Debit Card</h4>
                                                  <p>You can add a new card or select
                                                    from your saved card to make
                                                    payment for your token
                                                    purchase</p>
                                                </div>
                                              </div>
                                            </a>
                                          </>
                                          :
                                          ''}
                                        {this.state.bank_payment_stt == 1 ?
                                          <>
                                            <a href={`${config.baseUrl}payWithBank`} className="cardsmall">
                                              <div className="bankpayblock">
                                                <div className="cardspaybox2">
                                                  <img src="icons/bank2 1.png" alt="bank2 1" />
                                                  <h5>Bank payment</h5>
                                                </div>
                                                <div className="cardpaytext">
                                                  <h4>Pay with Bank Account</h4>
                                                  <p>You can complete payment
                                                    for Garri token by paying
                                                    your money to the account
                                                    given.
                                                  </p>
                                                </div>
                                              </div>
                                            </a>
                                          </>
                                          :
                                          ''
                                        }

                                      </>
                                      :
                                      ''
                                    }
                                    {this.state.currency_name == 'BUSD' || this.state.currency_name == 'USDT' ?
                                      <>
                                        {this.state.wallet_payment_stt == 1 ?
                                          <>
                                            <div className="walletpayblock">
                                              <a href={`${config.baseUrl}payWithCrypto`} className="cardsmall">
                                              <div className="cardspaybox3">
                                                  <img src="icons/wallet1.png" alt="wallet1" />
                                                  <h5>Wallet payment</h5>
                                              </div>
                                              <div className="cardpaytext">
                                                <h4>Pay with wallet</h4>
                                                <p>This option lets you pay for
                                                  Garri token directly from
                                                  your crypto wallet
                                                </p>
                                              </div>
                                              </a>
                                            </div>
                                          </>
                                          :
                                          <center>
                                            <p>Payment method not available.</p>
                                          </center>
                                        }
                                      </>
                                      :
                                      ''
                                    }
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                <div className="gtncardright">
                                  <div className="gtntopbox">
                                    <div className="gtnmainimg">

                                      <img src="img/top_card_content2.png" alt="top_card_content2" />
                                      <div className='gtntext'>
                                        <h6>GARRI</h6>
                                        <h6>{this.state.garri_token_amount}</h6>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="gtninner">
                                    {/* <h5>{this.state.user_name}</h5> */}
                                    <h5>Order detail</h5>
                                    <div className="gtntextblock">
                                      <div className="gtntetxt1">
                                        <ul>
                                          <li>Product</li>
                                          <li>Amount</li>
                                          <li>Date</li>
                                          {/* <li>Fee</li> */}
                                        </ul>
                                      </div>
                                      <div className="gtntetxt2">
                                        <ul>
                                          <li>Garri Token</li>
                                          <li>{this.state.currency_name} {this.state.currency_amount}</li>
                                          <li>{this.state.date}</li>
                                          {/* <li>{this.state.currency_name} {this.state.fees}</li> */}
                                        </ul>
                                      </div>
                                    </div>
                                    <div className="gtnbottom">
                                      <p>You have to pay</p>
                                      <div className="gtnbottomtext">
                                        <h4>{this.state.currency_name} {this.state.payble_amount}</h4>
                                        <div className="gntsmallimg">
                                          <img src="icons/bill-svgrepo-com 1.png" alt="bill-svgrepo-com 1" />
                                        </div>
                                      </div>
                                    </div>
                                    
                                  </div>
                                </div>
                              </div>

                              <div className='popbtn'>
                                <a href="#" className="editpen" data-bs-toggle="modal" data-bs-target="#myModaledit" id="canclepop">
                                  Edit
                                  <span>
                                    <img src="icons/editpencil.png" alt="editpencil" id="" />
                                  </span>
                                </a>

                                <a href="#" className="uplaodlatter" data-bs-toggle="modal" data-bs-target="#myModalcancle" id="editpop">
                                  Cancle
                                  <span>
                                    <img src="icons/corss.png" alt="corss" id="" />
                                  </span>
                                </a>
                              </div>

                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                  </section>

                  {/* -------------------------Popup Design ----------------------*/}

                  {/* The Modal Edit*/}
                  <div className="modal fade" id="myModaledit">
                    <div className="modal-dialog">
                      <div className="modal-content">
                        <div className="conversion" id='popdesign'>
                          <div className="converdiv">
                            <span>
                              <img src="icons/poperror.png" alt="poperror" className="error" />
                            </span>
                            <h4 className="converdexk">Conversion Desk</h4>
                            <h4 className="buytoken">Buy Token</h4>
                          </div>
                          <div className="innerconver">
                            <form action="#" className="mainform">
                              <div className="innbox" />
                              <div id="buytoknid">
                                <div className="form-group" id="buytokleft">
                                  <i className="fa fa-angle-up" aria-hidden="true" />
                                  <select class="custom-select" id='selectdrop' name="currency" value={this.state.currency} onChange={this.onChange}>
                                    {this.state.currency_list.map((item, i) =>
                                      <option value={item.id}>{item.asset_id}</option>
                                    )}
                                  </select>
                                </div>
                                <div className="form-group" id="buytokright">
                                  <input type="text" className="form-control" id='currency_amount' name='currency_amount' value={this.state.currency_amount} onChange={this.handleChangeNumber} autoComplete='off' />
                                </div>
                              </div>
                              <div className="convertdiv">
                                <img
                                  src="icons/convert_btn.png"
                                  alt="convert_btn"
                                  className="covertimg"
                                  id="convert_btn1"
                                />
                                <img
                                  src="icons/convert_btn2.png"
                                  alt="convert_btn"
                                  className="covertimg"
                                  id="convert_btn2"
                                />
                              </div>
                              <div id="buytoknid">
                                <div className="form-group" id="buytokleft">
                                  <i className="fa fa-angle-up" aria-hidden="true" />
                                  <select className="custom-select" id="selectdrop">
                                    <option>GARRI</option>
                                  </select>
                                </div>
                                <div className="form-group" id="buytokright">
                                  <input type="text" className="form-control" id='currency_amount' name='garri_amount' value={this.state.garri_amount} onChange={this.handleChangeNumber} autoComplete='off' />
                                </div>
                              </div>
                            </form>
                            <div className="buygaribtn">
                              {/* <button type="submit" className="buygaributton" data-bs-dismiss="modal" id="burgaribtn">
                                Buy Garri
                              </button> */}
                              <button type="submit" className="buygaributton" data-bs-dismiss="modal" id="Purchasebtn" onClick={this.submitForm} >
                                Purchase
                              </button>
                            </div>

                          </div>
                        </div>

                      </div>
                    </div>
                  </div>




                  {/* The Modal Cancle*/}
                  <div className="modal fade" id="myModalcancle">
                    <div className="modal-dialog">
                      <div className="modal-content">
                        {/* Modal Header */}
                        <div className="modal-header">
                          <h4 className="modal-title text-center">?</h4>
                          <button type="button" className="btn-close" data-bs-dismiss="modal" />
                        </div>
                        {/* Modal body */}
                        <div className="modal-body">Are you sure you want
                          to cancle </div>
                        {/* Modal footer */}
                        <div className="modal-footer">
                          <button
                            type="button"
                            className="btn btn-danger"
                            data-bs-dismiss="modal" id='yespop'
                            onClick={this.cancelThisOrder.bind(this)}
                          >
                            Yes
                          </button>
                          <button
                            type="button"
                            className="btn btn-danger"
                            data-bs-dismiss="modal" id='nopop'
                          >
                            No
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>



                  {/* <div className="row">
            <div className="backnextbtn">
              <div className="backdiv">
                <div className="backiconimg">
                  <img src="icons/dropdown-svgrepo-com 9.png" alt="dropdown-svgrepo-com 9" />
                </div>
                <p>Back</p>
              </div>
              <div className="nextdiv">
                <div className="nexticonimg">
                  <img src="icons/dropdown-svgrepo-com 10.png" alt="dropdown-svgrepo-com 10" />
                </div>
                <p>Next</p>
              </div>
            </div>
          </div> */}
                </div>
              </div>
            </div>



          </div>
        </div>
        <Footer />
      </div>
    );

  }
}