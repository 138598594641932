import React, { Component } from 'react';
import Header from '../directives/header';
import Footer from '../directives/footer'
import Leftsidebar from '../directives/leftsidebar';
// import Footer from '../directives/footer'
import axios from 'axios';
import config from '../config/config';
import toast, { Toaster } from 'react-hot-toast';
import Cookies from 'js-cookie';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import Web3 from 'web3';
const headers = {
  'Content-Type': 'application/json'
};


export default class PayWithCard extends Component {

  constructor(props) {
    super(props)
    this.state = {
      card_number: '',
      card_name: '',
      expiry_month: '',
      expiry_year: '',
      add_card_section: false,
      cards_section: true,
      pay_now_section: false,
      cards_list: [],
      card_type_list: [],
      loading: '',
      wallet_address: '',
    }
    this.onChange = this.onChange.bind(this);
    this.handleChangeNumber = this.handleChangeNumber.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.submit_paymentForm = this.submit_paymentForm.bind(this);
    this.loginData = (!Cookies.get('loginSuccesGarryToken')) ? [] : JSON.parse(Cookies.get('loginSuccesGarryToken'));
  }

  async componentDidMount() {
    this.get_current_transaction();
    this.get_my_cards();
    this.get_cards_type();

    if (window.ethereum) {
      let web3 = new Web3(window.ethereum);
      let tr_address = await web3.eth.getAccounts();
      if (tr_address.length > 0) {
        this.setState({
          wallet_address: tr_address[0]
        })
      }
    }
  }

  async get_current_transaction() {
    let obj = {
      user_id: this.loginData.data.id,
    }
    axios.post(`${config.apiUrl}getTemporaryData`, obj, { headers })
      .then(response => {
        if (response.data.success === true) {
          // console.log(response.data);
          this.setState({
            payble_amount: parseFloat(response.data.response.currency_amount) + parseFloat(response.data.response.fees),
            user_email: response.data.response.email,//response.data.user_name,
            currency: response.data.response.currency,
            currency_name: response.data.response.currency_name,
            currency_amount: response.data.response.currency_amount,
            garri_token_amount: response.data.response.garry_token_amount,
            // date: moment(response.data.response.date).format("DD/MM/YYYY"),
            fees: response.data.response.fees,
            usd_amount: response.data.response.usd_amount,
          })
        }
        else if (response.data.success === false) {
          // toast.error(response.data.msg);
          window.location.href = config.baseUrl;
        }
      })
      .catch(err => {
        // toast.error(err.response.data?.msg);
        window.location.href = config.baseUrl;
      })
  }

  async get_my_cards() {
    let obj = {
      user_id: this.loginData.data.id,
    }
    axios.post(`${config.apiUrl}getUserDebitCard`, obj, { headers })
      .then(response => {
        if (response.data.success === true) {
          this.setState({
            cards_list: response.data.response,
            add_card_section: false,
            cards_section: true,
            pay_now_section: false
          })
        }
        else if (response.data.success === false) {
          this.setState({
            add_card_section: true,
            cards_section: false,
            pay_now_section: false
          })
        }
      })
      .catch(err => {
        this.setState({
          add_card_section: true,
          cards_section: false,
          pay_now_section: false
        })
      })
  }

  async get_cards_type() {
    axios.get(`${config.apiUrl}getCardType`)
      .then(response => {
        if (response.data.success === true) {
          this.setState({
            card_type_list: response.data.response,
            card_name: response.data.response[0].id
          })
        }
      })
      .catch(err => {
      })
  }

  async remove_this_card(card_id) {


    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to delete this card',
      icon: 'warning',
      imageUrl: '',
      imageHeight: 65,
      imageWidth: 65,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes! Delete',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        let obj = {
          id: card_id
        }
        axios.post(`${config.apiUrl}deleteDebitCard`, obj, { headers })
          .then(response => {
            if (response.data.success === true) {
              Swal.fire(
                'Deleted!',
                'Your Card has been deleted.',
                'success'
              )
              this.get_my_cards();
            }
            else if (response.data.success === false) {
              toast.error(response.data.msg);
            }
          })
          .catch(err => {
            toast.error(err.response.data?.msg);
          })
      }
    })

  }

  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    })
  }
  handleChangeNumber(event) {
    const re = /^[0-9\b]+$/;
    if (event.target.value === '' || re.test(event.target.value)) {
      this.setState({
        [event.target.name]: event.target.value
      })
    }
    else {
      toast.error('Please enter only numbers');
    }
  }

  async submitForm(e) {
    e.preventDefault();

    let obj = {
      user_id: this.loginData.data.id,
      card_number: this.state.card_number,
      card_type_id: this.state.card_name,
      name_on_card: this.state.name_on_card,
      expiry_month: this.state.expiry_month,
      expiry_year: this.state.expiry_year,
    }
    //   console.log(obj)
    //   return;
    axios.post(`${config.apiUrl}insertDebitCard`, obj, { headers })
      .then(response => {
        if (response.data.success === true) {
          toast.success('Card detail added successfully.');

          this.setState({
            add_card_section: false,
            cards_section: true,
            pay_now_section: false,
            card_number: '',
            name_on_card: '',
            expiry_month: '',
            expiry_year: ''
          })
          this.get_my_cards();
          // setTimeout(() => {
          //     window.location.href = config.baseUrl + 'payWithCard'
          // }, 1000);
        }
        else if (response.data.success === false) {
          toast.error(response.data.msg);
        }
      })
      .catch(err => {
        toast.error(err.response.data?.msg);
      })
  }

  async add_new_card() {
    await this.setState({
      add_card_section: true,
      cards_section: false,
      pay_now_section: false
    })
  }

  async cancel_add_card() {
    await this.setState({
      add_card_section: false,
      cards_section: true,
      pay_now_section: false
    })
  }

  async payWith_this_card(card_id) {

    let allData = this.state.cards_list;
    var selected_card = allData.filter(function (el) {
      return el.id == card_id
    });
    console.log(selected_card)
    await this.setState({
      add_card_section: false,
      cards_section: false,
      pay_now_section: true,
      c_number: selected_card[0].card_number,
      c_icon: selected_card[0].icon,
      c_name_on_card: selected_card[0].name_on_card,
      c_type: selected_card[0].card_type,
      c_expiry_month: selected_card[0].expiry_month,
      c_expiry_year: selected_card[0].expiry_year,
      cvv_code: '',
      card_id: card_id
    })

  }


  async submit_paymentForm(e) {
    e.preventDefault();

    if (!this.state.cvv_code) {
      toast.error('Please enter cvv code.', {});
      return;
    }
    else if (!this.state.wallet_address) {
      toast.error('Please enter wallet address.', {});
      return;
    }

    try {
      const web3 = new Web3()
      let addr_chk = await web3.utils.toChecksumAddress(this.state.wallet_address)
      if(addr_chk == this.state.wallet_address)
      {
        document.getElementById('pay_now_btn').setAttribute("disabled", "true")
        let obj = {
          user_id: this.loginData.data.id,
          currency: this.state.currency,
          total_amount: this.state.payble_amount,
          user_email: this.state.user_email,
          currency_amount: this.state.currency_amount,
          garri_token_amount: this.state.garri_token_amount,
          fees: this.state.fees,
          usd_amount: this.state.usd_amount,
          payment_method: 'CARD',
          card_id: this.state.card_id,
          token_transfer_address: this.state.wallet_address
        }

        var data = JSON.stringify({
          "email": this.state.user_email,
          "amount": parseInt(Math.round(this.state.payble_amount * 100)),
          "card": {
            "cvv": this.state.cvv_code,
            "number": this.state.c_number,
            "expiry_month": this.state.c_expiry_month,
            "expiry_year": this.state.c_expiry_year
          },
          "pin": "0000"
        });

        var pay_config = {
          method: 'post',
          url: 'https://api.paystack.co/charge',
          headers: {
            'Authorization': 'Bearer ' + config.PAYSTACK_SECRETKEY,
            'Content-Type': 'application/json'
          },
          data: data
        };

        axios(pay_config)
          .then(function (response) {
            // console.log('>>>>>>>>',response.data);
            let payment_response = response.data;
            if (payment_response.status == true && payment_response.data.status == "success") {
              obj.getway_response = payment_response;
              axios.post(`${config.apiUrl}submit_pay_with_card`, obj, { headers })
                .then(result => {
                  if (result.data.success === true) {
                    toast.success(result.data.msg, {});
                    setTimeout(() => {
                      window.location.href = config.baseUrl + 'confirmation';
                    }, 2000)
                  }
                  else if (result.data.success === false) {
                    console.log(result.data);
                    toast.error(result.data.msg);
                  }
                })
                .catch(err => {
                  toast.error(err.result.data?.msg);
                })
            }
            else {
              toast.error('Payment failed.');
              document.getElementById('pay_now_btn').removeAttribute("disabled")
            }
          })
          .catch(error =>  {
            // console.log('..', JSON.parse(error));
            
            toast.error('Invalid card detail or please enable international payment.');
            document.getElementById('pay_now_btn').removeAttribute("disabled")
          });
        }
        else{
          toast.error('Please enter correct wallet address.', {});
          return;
        }
    }
    catch {
      toast.error('Please enter correct wallet address.', {});
      return;
    }

  }

  copyToClipboard = async (e) => {
    if (this.state.wallet_address != '') {
      await navigator.clipboard.writeText(this.state.wallet_address);
      toast.success('Address copied!')
    }
  };


  render() {
    return (
      <div>

        <div className="">
          <Leftsidebar />
          {/* Content Start */}
          <div className="content">
            <Header />

            <section className="signup-step-container mt-5">
              <div className="container">
                <div className="row d-flex justify-content-center">
                  <div className="col-md-8 col-sm-12 col-xs-12 col-12">
                    <div className="wizard" id="wizardmain">
                      {/* <div className="wizard-inner" id='cardstepid'>
                        <div className="connecting-line" />
                        <ul className="nav nav-tabs" role="tablist">
                          <li role="presentation" className="active">
                            <a href="javascript:void(0)" data-toggle="tab" aria-controls="step1" role="tab" aria-expanded="true"><span className="round-tab">1 </span> <i>Step 1</i></a>
                          </li>
                          <li role="presentation" className="">
                            <a href="javascript:void(0)" data-toggle="tab" aria-controls="step2" role="tab" aria-expanded="true"><span className="round-tab">2</span> <i>Step 2</i></a>
                          </li>
                          <li role="presentation" className="disabled">
                            <a href="javascript:void(0)" data-toggle="tab" aria-controls="step3" role="tab"><span className="round-tab">3</span> <i>Step 3</i></a>
                          </li>
                        </ul>
                        <a className='btn btn-danger btn-sm' href='javascript:history.back()' style={{ float: 'right', marginLeft: '55px' }}> &nbsp; Back &nbsp;</a>
                      </div> */}
                      <div className="wizard-inner">
                      <div className="connecting-line" />
                      <ul className="nav nav-tabs" role="tablist">
                        <li role="presentation" className="active">
                          <a href="" data-toggle="tab" aria-controls="step1" role="tab" aria-expanded="true"><span className="round-tab">1 </span> <i>Step 1</i></a>
                        </li>
                        <li role="presentation" className="active">
                          <a href="javascript:void(0)" data-toggle="tab" aria-controls="step2" role="tab" aria-expanded="false"><span className="round-tab">2</span> <i>Step 2</i></a>
                        </li>
                        <li role="presentation" className="disabled">
                          <a href="javascript:void(0)" data-toggle="tab" aria-controls="step3" role="tab"><span className="round-tab">3</span> <i>Step 3</i></a>
                        </li>
                        {/* <li role="presentation" class="disabled">
                                    <a href="#step4" data-toggle="tab" aria-controls="step4" role="tab"><span class="round-tab">4</span> <i>Step 4</i></a>
                                </li> */}
                      </ul>
                    </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            {this.state.add_card_section ?
              <div className="row" id="johndashidone">
                {/* <h5 className="johndash">John Doe  /  Add card</h5> */}
                <div className="row">
                  <form action="#" id="addcardform">
                    <h4>Add New Card</h4>
                    <div className="selectbox" id=''>
                      <h3>Card Number</h3>
                      <p>Enter the 16 - digit number on your card</p>
                      <div className="input-group mb-3 selectrow">
                        <input type="text" className="form-control" name="card_number" pattern="[0-9]*" value={this.state.card_number} onChange={this.handleChangeNumber} autoComplete="off" maxLength={16} placeholder="Card number" />
                      </div>
                    </div>
                    <div className="selectbox">
                      <h3>Card Type</h3>
                      <p>Select the type of your card</p>
                      <div className="input-group mb-3 selectrow">
                        {/* <input type="text" className="form-control" name="card_name" value={this.state.card_name} onChange={this.onChange} autoComplete="off" /> */}

                        <select className='form-control' name="card_name" value={this.state.card_name} onChange={this.onChange} style={{ background: '#fff' }}>
                          {this.state.card_type_list.map((item, i) =>
                            <option value={item.id}>{item.card_type}</option>
                          )}
                          <option value={0}>Other</option>
                        </select>

                      </div>
                    </div>
                    <div className="mainselectbox" id="mainselectboxname">
                      <div className="selectext" id="mainselctone">
                        <h3>Name On Card</h3>
                        <p>Enter the 3-digit numer card
                          behind the card</p>
                      </div>
                      <div className="selectinput" id="mainselctone">
                        <div className="input-group mb-3 selectrow">
                          <input type="text" className="form-control" name="name_on_card" value={this.state.name_on_card} onChange={this.onChange} autoComplete="off" placeholder='Name on card' />
                        </div>
                      </div>
                    </div>
                    <div className="mainselectbox" id="mainselectboxname">
                      <div className="selectext" id="mainselctone">
                        <h3>Expiry Date</h3>
                        <p>Enter expiry date of the card</p>
                      </div>
                      <div className="selectinput expirydate" id="expiremonth">
                        <div className="input-group mb-3 selectrow">
                          <input type="text" className="form-control" name="expiry_month" value={this.state.expiry_month} onChange={this.handleChangeNumber} maxLength={2} autoComplete="off" placeholder='Expiry month' />
                        </div>
                        <div className="input-group mb-3 selectrow">
                          <input type="text" className="form-control" name="expiry_year" value={this.state.expiry_year} onChange={this.handleChangeNumber} maxLength={4} autoComplete="off" placeholder='Expiry year' />
                        </div>
                      </div>
                    </div>
                    <div className="bankbtn">
                      <div className='row'>
                        <div className='canclesubmit' id='cancleids'>
                        {this.state.cards_list.length > 0 ?
                          <div className='col-md-6'>
                            <button className="btn btn-danger expirydatesubmit" onClick={this.cancel_add_card.bind(this)}>Cancel</button>
                          </div>
                          :
                          ''
                        }
                        <div className='col-md-6'>
                          <button className="btn btn-success expirydatesubmit" onClick={this.submitForm}>Submit</button>
                        </div>
                        </div>

                        
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              :
              ''
            }

            {this.state.cards_section ?
              <div className="row" id="johndashid">
                <div className="main-content">
                  <div className="tophead" id="selectpmethed">
                    <h4 className='paycards'>Pay With Card</h4>
                    <h4 className='managecards text-center'>Manage Cards</h4>
                    <a href='javascript:;' onClick={this.add_new_card.bind(this)} className="addcards" id="newaddcardbtn">Add Card</a>
                  </div>
                  <div className="tophead" id="addcardid">
                  {/* <a href="#"><span><img src="icons/add_btn.png" alt="add_btn" /></span>Add Card</a> */}
                  <a href='javascript:;' onClick={this.add_new_card.bind(this)} className="addcards"><span><img src="icons/add_btn.png" alt="add_btn" /></span>Add Card</a>
                </div>

                  <div className="innercard">
                    
                    {this.state.cards_list.map((item, i) =>
                      <div className={`cardblock${item.card_type_id}`}>
                        <a href='javascript:;'>
                          <h5>Debit Card <a title="Remove" onClick={this.remove_this_card.bind(this, item.id)} style={{ float: 'right', color: '#a12a2a' }}><i className='fa fa-trash'></i></a></h5>
                          <div className='pay_this_card' onClick={this.payWith_this_card.bind(this, item.id)} >
                            {/* onClick={this.payWith_this_card.bind(this, item.id)} */}
                            <div className="innerbox1">
                              <div className="imgbox1">
                                <img src="icons/debiticon.png" alt="debiticon" />
                              </div>
                              <div className="imgbox1">
                                {item.icon != '' ?
                                  <img src={`icons/${item.icon}`} alt="visaicon" />
                                  :
                                  <h5>{item.card_type}</h5>
                                }
                              </div>
                            </div>
                            <h3>{item.card_number.toString().substring(0, 4)} - XXXX - XXXX - {item.card_number.toString().slice(-4)}</h3>
                            {/* <h3>{item.card_number.substring(0, 4)} - XXXX - XXXX - {item.card_number.slice(-6)}</h3> */}
                            <div className="innerbox2">
                              <p>{item.name_on_card}</p>
                              <p>{item.expiry_month}/{item.expiry_year}</p>
                            </div>
                          </div>
                        </a>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              :
              ''
            }

            {this.state.pay_now_section ?
              <div className="row" id="johndashid">
                <div className="main-content">
                  <div className="tophead">
                    <h4>Pay With Card</h4>
                    <a href='javascript:;' onClick={this.add_new_card.bind(this)} className="addcards">Add Card</a>
                  </div>
                  <form action="#" id="addcardform2">
                    {/* <div className='row'>
                      <div className='col-md-10'>
                        <div className="selectbox" id='cardmunid'>
                          <h3>Card Number</h3>
                          <div className="input-group mb-3 selectrow">
                            <input type="text" className="form-control" value={this.state.c_number} readOnly />
                          </div>
                        </div>
                      </div>
                      <div className='col-md-2'>
                        {this.state.c_icon != '' ?
                          <><br></br> <img src={`icons/${this.state.c_icon}`} alt="visaicon" className='visaiconimg'/></>
                          :
                          <><br></br>  <h5>{this.state.c_type}</h5></>
                        }
                      </div>
                    </div> */}
                    <div className='row'>
                      <div className='col-md-10'>
                        <div className="selectbox">
                          <h3>Name On Card</h3>
                          {/* <p>Enter the 16 - digit number on your card</p> */}
                          <div className="input-group mb-3 selectrow">
                            <input type="text" className="form-control" value={this.state.c_name_on_card} readOnly />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='row'>
                      <div className='col-md-5'>
                        <div className="selectbox">
                          <h3>Expiry Month</h3>
                          {/* <p>Enter the 16 - digit number on your card</p> */}
                          <div className="input-group mb-3 selectrow">
                            <input type="text" className="form-control" value={this.state.c_expiry_month} readOnly />
                          </div>
                        </div>
                      </div>
                      <div className='col-md-5'>
                        <div className="selectbox">
                          <h3>Expiry Year</h3>
                          {/* <p>Enter the 16 - digit number on your card</p> */}
                          <div className="input-group mb-3 selectrow">
                            <input type="text" className="form-control" value={this.state.c_expiry_year} readOnly />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='row'>
                      <div className='col-md-10'>
                        <div className="selectbox">
                          <h3>CVV</h3>
                          <p>Enter cvv number on your card</p>
                          <div className="input-group mb-3 selectrow">
                            <input type="number" className="form-control" name="cvv_code" value={this.state.cvv_code} placeholder="Enter CVV" onChange={this.onChange} autoComplete="off" min={0} />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='row'>
                      <div className='col-md-10'>
                        <div className="selectbox">
                          <h3>Wallet Address</h3>
                          <p>Add your wallet address to recive your Garri Token</p>
                          <div className="input-group mb-3 selectrow">
                            <input type="text" className="form-control text-center active" name='wallet_address' placeholder="Wallet address" onChange={this.onChange} autoComplete="off" value={this.state.wallet_address} />
                            <div className="input-group-text bankdiv" id="paywith_card" onClick={this.copyToClipboard.bind(this)}>
                              <img src="icons/copy-svgrepo-com 1.png" alt="copy-svgrepo-com 1" className="bankinput" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="bankbtn mb-lg-5 mb-sm-0" id='paybackbtn'>
                      <div className='row'>
                        <div className='col-md-6'>
                          <button className="btn btn-success expirydatesubmit" id='pay_now_btn' onClick={this.submit_paymentForm}>{this.state.loading} Pay ({this.state.payble_amount} {this.state.currency_name})</button>
                        </div>
                        <div className='col-md-2'>
                          <button className="btn btn-danger expirydatesubmit" onClick={this.cancel_add_card.bind(this)}>Back</button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              :
              ''
            }

            <div class="modal fade" id="exampleModal">
              <div class="modal-dialog">
                <div class="modal-content">

                  <div class="modal-header">
                    <h4 class="modal-title text-center">OxEOF1...5154</h4>
                    <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                  </div>

                  <div class="modal-body">
                    <div class="row">
                      <div class="col-sm-6 col-sm-12 col-xs-12 col-12">
                        <div class="card" id="walletpopupid">
                          <div class="card-body">
                            <h5 class="card-title">$31.12 USD</h5>
                            <p class="card-text">Total Balance</p>
                            {/* <!-- <a href="#" class="btn btn-primary">Go somewhere</a> --> */}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="modal-footer">
                      <button type="button" class="btn btn-danger addcards2" data-bs-dismiss="modal">Close</button>
                    </div>

                  </div>
                </div>
              </div>
            </div>


          </div>
        </div>
        <Footer />
      </div>
    );

  }
}