import React, { Component } from 'react';
import Cookies from 'js-cookie';
import { Link } from 'react-router-dom'
import config from '../config/config'
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';
import Web3 from 'web3';
import Onboard from 'bnc-onboard';
import { BrowserView, MobileView, isMobile } from 'react-device-detect';
import { Modal, Button } from "react-bootstrap";

export default class Header extends Component {

  constructor(props) {
    super(props)
    this.state = {
      profile_pic: 'img/avatar.png',
      isOpen: false,
      connected_wallet: '',
      isMetamask: false,
    }
    this.loginData = JSON.parse(!Cookies.get('loginSuccesGarryToken') ? null : Cookies.get('loginSuccesGarryToken'));
    this.loginData_metamask = (!Cookies.get('loginMetamask_garry') ? null : Cookies.get('loginMetamask_garry'));
    if (!Cookies.get('loginSuccesGarryToken')) {
      window.location = '';
    }
  }

  logout() {
    Cookies.remove('loginSuccesGarryToken')
    Cookies.remove('loginMetamask_garry')
    window.location.href = config.baseUrl
  }


  async componentDidMount() {
    
    console.clear()
    this.checkIfWalletIsConnected();
    this.getUserProfileAPI();
    this.checkMetaMask();

    setTimeout(async () => {
      if (window.ethereum) {
        let web3 = new Web3(window.ethereum);
        let tr_address = await web3.eth.getAccounts();
        if(isMobile && tr_address.length == 0){
          tr_address = await window.ethereum.request({
            method: "eth_requestAccounts",
          });
        }
        if (tr_address.length > 0) {
          await this.setState({
            connected_wallet: tr_address[0],
            wallet_address: tr_address[0],
            isMetamask: (web3.currentProvider.isMetaMask ? web3.currentProvider.isMetaMask : false),
          })
          await Cookies.set('loginMetamask_garry', tr_address[0]);
        }
      }
    }, 1000);
  }

  async checkMetaMask() {
    const { ethereum } = window;
    if (ethereum) {
      ethereum.on("accountsChanged", accounts => {
        if (accounts.length > 0) {
          // this.logout(accounts)
          let web3 = new Web3(window.ethereum);
          let currentNetwork = web3.currentProvider.chainId;
          // var ETH_mainnet = 0x1;
          // var ETH_testnet = 0x3;

          // var bnb_mainnet = 0x38; // 56
          // var bnb_testnet = 0x61;  // 97
          if (currentNetwork != '56' && currentNetwork != '0x38') {
            Cookies.set('loginMetamask_garry', "");
            return;
          }

          this.setState({
            connected_wallet: ethereum.selectedAddress,
            wallet_address: ethereum.selectedAddress,
            isMetamask: (web3.currentProvider.isMetaMask ? web3.currentProvider.isMetaMask : false),
          })
          Cookies.set('loginMetamask_garry', ethereum.selectedAddress);
        }
        else
          Cookies.set('loginMetamask_garry', "");
      });

      ethereum.on('networkChanged', (chainId) =>
        
        this.networkChanged(chainId)
      );
    }
  }

  async getUserProfileAPI() {

    axios.post(`${config.apiUrl}getUserProfile`, { 'email': this.loginData?.data.email },)
      .then(response => {
        if (response.data.success === true) {
          this.setState({
            profile_pic: (response.data.response.profile_pic ? config.apiUrl + response.data.response.profile_pic : 'img/avatar.png')
          })
        }
        else if (response.data.success === false) {
        }
      })
      .catch(err => {
      })
  }


  async connect_metamask(onConnected) {
    if (!window.ethereum) {
      let icon = '';
      Swal.fire({
        // title: 'Are you sure?',
        text: "You'll need to install MetaMask to continue. Once you have it installed, go ahead.",
        icon: icon,
        imageUrl: 'img/metamask.svg',
        imageHeight: 65,
        imageWidth: 65,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Install Now',
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          if (icon == '') {
            window.open('https://metamask.io/', '_blank');
          }
          else {
            window.location.reload();
          }
        }
      })
      return;
    }
    const accounts = await window.ethereum.request({
      method: "eth_requestAccounts",
    });

    Cookies.set('loginMetamask_garry', accounts[0]);
    setTimeout(() => {
      window.location.reload()
    }, 100);
  }


  async checkIfWalletIsConnected() {
    // console.clear()
    if (window.ethereum) {
      const accounts = await window.ethereum.request({
        method: "eth_accounts",
      });
      if (accounts.length > 0) {
        return;
      }
      else {
        Cookies.set('loginMetamask_garry', '');
      }
    }
  }

  async connectMetasmask_mobile() {
    let web3
    const onboard = Onboard({
      dappId: '0880efe2-6c9c-4c85-a416-d5aea1f62201', // [String] The API key created by step one above
      networkId: 56,  // [Integer] The Ethereum network ID your Dapp uses.
      walletSelect: {
        wallets: [
          { walletName: "metamask", preferred: true },
          { walletName: "trust", preferred: true },
        ]
      },
      subscriptions: {
        wallet: wallet => {
          web3 = new Web3(wallet.provider)
        }
      }
    })
    let a = await onboard.walletSelect();
  }

  openModal = () => this.setState({ isOpen: true });
  closeModal = () => this.setState({ isOpen: false });

  async connect_trust_browser() {
    let icon = '';
    Swal.fire({
      // title: 'Are you sure?',
      text: "Please connect from dapp browser.",
      icon: icon,
      imageUrl: 'img/trustwallet.png',
      imageHeight: 65,
      imageWidth: 65,
      // showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'OK',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.close()
      }
    })
    return;
  }

  render() {

    return (

      <>
        {/* Navbar Start */}

        <Toaster
          position="top-right"
          reverseOrder={false}
        />
        <BrowserView>
          <nav className="navbar navbar-expand bg-light navbar-light sticky-top px-4 py-3" id="oneheaderid">
            <a href={`${config.baseUrl}dashboard`} className="navbar-brand d-flex d-lg-none me-4">
              <h2 className="text-primary mb-0" />
            </a>
            <a href="javascript:;" className="sidebar-toggler flex-shrink-0">
              <i className="fa fa-bars" id="toggleiconid1" />
              <i className="fa fa-angle-left" id="toggleiconid2" aria-hidden="true" />
            </a>
            <div className="navbar-nav align-items-center ms-auto" id="topmobilenav">

              <div className="nav-item dropdown">
                <a href="#" className="nav-link dropdown-toggle" id='head_menu' data-bs-toggle="dropdown">
                  <img className="rounded-circle me-lg-2" src={this.state.profile_pic} alt="" style={{ width: '40px', height: '40px' }} />
                  <span className="d-none d-lg-inline-flex">{this.loginData.data.username}</span>
                </a>
                <div className="dropdown-menu dropdown-menu-end bg-light border-0 rounded-0 rounded-bottom m-0" id='head_menu_option_div'>
                  <a href={`${config.baseUrl}updateProfile`} className="dropdown-item">My Profile</a>
                  <a href="javascript:;" onClick={this.logout.bind(this)} className="dropdown-item">Log Out</a>
                </div>
              </div>
              <a href={`${config.baseUrl}dashboard`} className="navbar-brand mx-4 mb-3" id="mobilelogoid"><img src="img/logo.png" alt="logo" id="dummylogo" /></a>

              {this.state.connected_wallet != '' ?
                <a href='javascript:void(0)' className='addcards2' >{this.state.connected_wallet.substring(0, 6)}.....{this.state.connected_wallet.slice(-6)}</a>
                :
                <>
                <a href='javascript:void(0)' onClick={this.openModal} className='addcards2' >Connect&nbsp;Wallet</a>
                </>
              }

              <Modal show={this.state.isOpen} onHide={this.closeModal}>
                <Modal.Header closeButton>
                  <Modal.Title>Choose Wallet</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="row pt-4 pb-4">
                    <div className="col-md-6 text-center">
                        <a className='d-flex align-items-center justify-content-center' href="javascript:;" onClick={this.connect_metamask}><img src="img/metamask.svg" className="" alt="Metamask wallet" width="50px" />&nbsp;&nbsp;

                          <h5>Metamask</h5></a>
                    </div>
                    <div class="col-md-6">
                        <a className='d-flex align-items-center justify-content-center' href="javascript:;" onClick={this.connect_trust_browser}><img src="img/trustwallet.png" className='' alt="Trust wallet" style={{ height: '50px' }} />&nbsp;&nbsp;

                          <h5>Trust Wallet</h5>
                        </a>

                    </div>
                  </div>
                </Modal.Body>
              </Modal>

            </div>
          </nav>

        </BrowserView>


        <MobileView>
        
          <nav className="navbar navbar-expand bg-light navbar-light sticky-top px-4 py-3" id="oneheaderid">
            <a href={`${config.baseUrl}dashboard`} className="navbar-brand d-flex d-lg-none me-4">
              <h2 className="text-primary mb-0" />
            </a>
            <a href="javascript:;" className="sidebar-toggler flex-shrink-0">
              <i className="fa fa-bars" id="toggleiconid1" />
              <i className="fa fa-angle-left" id="toggleiconid2" aria-hidden="true" />
            </a>
            <div className="navbar-nav align-items-center ms-auto" id="topmobilenav">

              <div className="nav-item dropdown">
                <a href="#" className="nav-link dropdown-toggle" id='head_menu' data-bs-toggle="dropdown">
                  <img className="rounded-circle me-lg-2" src={this.state.profile_pic} alt="" style={{ width: '40px', height: '40px' }} />
                  <span className="d-none d-lg-inline-flex">{this.loginData.data.username}</span>
                </a>
                <div className="dropdown-menu dropdown-menu-end bg-light border-0 rounded-0 rounded-bottom m-0" id='head_menu_option_div'>
                  <a href={`${config.baseUrl}profile`} className="dropdown-item">My Profile</a>
                  <a href="javascript:;" onClick={this.logout.bind(this)} className="dropdown-item">Log Out</a>
                </div>
              </div>
              <a href={`${config.baseUrl}dashboard`} className="navbar-brand mx-4 mb-3" id="mobilelogoid"><img src="img/logo.png" alt="logo" id="dummylogo" /></a>
             
              {this.state.connected_wallet != '' ?
                <a href='javascript:void(0)' className='addcards2' >{this.state.connected_wallet.substring(0, 6)}.....{this.state.connected_wallet.slice(-6)}</a>
                :
                <>
                <a href='javascript:void(0)' onClick={this.connectMetasmask_mobile} className='addcards2' >Connect&nbsp;Wallet 1 {this.state.connected_wallet}</a>
                </>
              }

            </div>
          </nav>

          {/* Navbar End */}
        </MobileView>
      </>
    )
  }
}