import React, { Component } from 'react';
import Header from '../directives/header'
import Footer from '../directives/footer'
import Leftsidebar from '../directives/leftsidebar'
import axios from 'axios'
import config from '../config/config'
import toast, { Toaster } from 'react-hot-toast';
import Cookies from 'js-cookie';
import 'react-toastify/dist/ReactToastify.css';
const headers = {
    'Content-Type': 'application/json'
};

export default class ChangePassword extends Component {

    constructor(props) {
        super(props)
        this.state = {
            currentPassword_type:'password',
            newPassword_type:'password',
            confirmPassword_type:'password',
            t1:'fa-eye',
            t2:'fa-eye',
            t3:'fa-eye',
        }
        this.onChange = this.onChange.bind(this);
        this.submitForm = this.submitForm.bind(this);
        this.loginData = (!Cookies.get('loginSuccesGarryToken')) ? [] : JSON.parse(Cookies.get('loginSuccesGarryToken'));
    }

    componentDidMount() {
        document.getElementById("set_nav").classList.add("show");
        document.getElementById("set_option_div").classList.add("show");
        document.getElementById("set_nav").setAttribute("aria-expanded", true);
    }

    onChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
  
    async submitForm(e) {
        e.preventDefault();
  
        let obj = {
          user_id:this.loginData.data.id,
          currentPassword:this.state.current_password,
          new_password:this.state.new_password,
          confirm_password:this.state.confirm_password
        }
      //   console.log(obj)
      //   return;
        axios.post(`${config.apiUrl}changePassword`, obj, { headers })
              .then(response => {
                  if (response.data.success === true) 
                  {
                    window.scrollTo(0, 0);
                    toast.success('Password changed successfully.');
                    this.setState({
                        current_password:'',
                        new_password:'',
                        confirm_password:'',
                    })
                    //   setTimeout(() => {
                    //       window.location.href = config.baseUrl+'changePassword'
                    //   }, 1000);
                  }
                  else if (response.data.success === false) {
                      toast.error(response.data.msg);
                  }
              })
              .catch(err => {
                  toast.error(err.response.data?.msg);
              })
    }

    async togglePassword(eyebtn)
    {
        if(eyebtn == 'current')
        {
            if(this.state.currentPassword_type == 'password')
            {
                this.setState({
                    currentPassword_type:'text',
                    t1:'fa-eye-slash'
                })
                return;
            }
            this.setState({
                currentPassword_type:'password',
                t1:'fa-eye'
            })
        }
        else if(eyebtn == 'new')
        {
            if(this.state.newPassword_type == 'password')
            {
                this.setState({
                    newPassword_type:'text',
                    t2:'fa-eye-slash'
                })
                return;
            }
            this.setState({
                newPassword_type:'password',
                t2:'fa-eye'
            })
        }
        else if(eyebtn == 'confirm')
        {
            if(this.state.confirmPassword_type == 'password')
            {
                this.setState({
                    confirmPassword_type:'text',
                    t3:'fa-eye-slash'
                })
                return;
            }
            this.setState({
                confirmPassword_type:'password',
                t3:'fa-eye'
            })
        }
    }

    render() {
        return (
            <div>

                <div className="">
                    <Leftsidebar />
                    {/* Content Start */}
                    <div className="content">
                        <Header />



                        <div class="row" id="johndashidone">
                            {/* <!-- <h5 class="johndash">John Doe  /  Add card</h5> --> */}

                            <form action="#" id="userpasswordid">
                                <div class="walletimgblock">
                                    <img src="icons/key-svgrepo-com 2pass.png" alt="key-svgrepo-com 2pass" class="walletinput" />
                                </div>

                                <div class="selectbox">
                                    <h3 className='oldpass'>Current Password</h3>
                                    <h3 className='newpass'>Enter Old  Password</h3>
                                    <p>Enter your current password</p>

                                    <div class="input-group mb-3 selectrow eyeicon"  id='changep'>
                                        <input type={this.state.currentPassword_type} class="form-control text-center" placeholder="Enter Password" name="current_password" value={this.state.current_password} onChange={this.onChange} autoComplete="off" />
                                        <i class={`far ${this.state.t1}`} onClick={this.togglePassword.bind(this, 'current')} id="eyeiconid"></i>
                                    </div>
                                </div>

                                <div class="selectbox">
                                    <h3 className='oldpass'>New Password</h3>
                                    <h3 className='newpass'>Enter Password</h3>
                                    <p>Enter a strong password with at least  one  number, symbol , caplock</p>

                                    <div class="input-group mb-3 selectrow eyeicon" id='changep'>
                                        <input type={this.state.newPassword_type}  class="form-control text-center" placeholder="Enter Password" name="new_password" value={this.state.new_password} onChange={this.onChange} autoComplete="off" />
                                        {/* <i class="far fa-eye" id="togglePassword" onClick={this.togglePassword.bind(this)}></i> */}
                                        <i class={`far ${this.state.t2}`} onClick={this.togglePassword.bind(this, 'new')} id="eyeiconid"></i>
                                    </div>
                                </div>
                                <div class="selectbox">
                                    <h3>Retype Password</h3>
                                    <p>Retype the password you enterd above</p>

                                    <div class="input-group mb-3 selectrow eyeicon" id='changep'>
                                        <input type={this.state.confirmPassword_type}  class="form-control text-center" placeholder="Retype Password" name="confirm_password" value={this.state.confirm_password} onChange={this.onChange} autoComplete="off" />
                                        {/* <i class="far fa-eye" id="togglePassword" onClick={this.togglePassword.bind(this)}></i> */}
                                        <i class={`far ${this.state.t3}`} onClick={this.togglePassword.bind(this, 'confirm')} id="eyeiconid"></i>
                                    </div>
                                </div>
                                <div class="bankbtn mb-lg-5 mb-sm-0" id='passbtnid'>
                                <button type='submit' className="btn btn-success expirydatesubmit" onClick={this.submitForm}>Submit</button>
                                </div>
                            </form>
                            
                        </div>



                    </div>
                </div>
                <Footer />
            </div>
        );

    }
}